import { MessageSquare } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatTopicDate } from '../utils/date';
import { Topic } from '../types/community';
import { getUniqueTopics } from '../utils/community/topics';
import { useResizeObserver } from '../hooks/useResizeObserver';

interface TopicListProps {
  communityId: string;
  topics: Topic[];
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    noTopics: 'Nenhum tópico ainda',
    beFirst: 'Seja o primeiro a criar um tópico!',
    responses: 'respostas',
    lastResponse: 'Última resposta'
  },
  'en': {
    noTopics: 'No topics yet',
    beFirst: 'Be the first to create a topic!',
    responses: 'responses',
    lastResponse: 'Last response'
  }
};

export function TopicList({ communityId, topics, isDarkMode = false, language = 'en' }: TopicListProps) {
  const { ref } = useResizeObserver();
  const uniqueTopics = getUniqueTopics(topics);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  if (uniqueTopics.length === 0) {
    return (
      <div className="text-center py-8">
        <MessageSquare className={`h-12 w-12 mx-auto mb-3 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
        <p className={isDarkMode ? 'text-gray-300' : 'text-gray-500'}>
          {t('noTopics')}
        </p>
        <p className={`text-sm mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`}>
          {t('beFirst')}
        </p>
      </div>
    );
  }

  return (
    <div ref={ref} className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
      {uniqueTopics.map((topic) => {
        const key = `${topic.id}-${topic.timestamp?.getTime() || Date.now()}`;
        
        return (
          <Link
            key={key}
            to={`/communities/${communityId}/topics/${topic.id}`}
            className={`block p-4 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition-colors`}
          >
            <div className="flex items-start space-x-3">
              <div className="flex-1 min-w-0">
                <h3 className={`text-lg font-semibold mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {topic.title}
                </h3>
                <p className={`line-clamp-2 mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  {topic.content}
                </p>
                <div className={`flex flex-wrap items-center gap-2 text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  <div className="flex items-center space-x-1">
                    <MessageSquare className="h-3.5 w-3.5" />
                    <span>{topic.responseCount} {t('responses')}</span>
                  </div>
                  <span>•</span>
                  <span>{formatTopicDate(topic.timestamp)}</span>
                  {topic.lastResponseAt && topic.responseCount > 0 && (
                    <>
                      <span>•</span>
                      <span className={isDarkMode ? 'text-gray-500' : 'text-gray-400'}>
                        {t('lastResponse')} {formatTopicDate(topic.lastResponseAt)}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}