import { useState, useEffect } from 'react';
import { Search, X, Loader2, Clapperboard } from 'lucide-react';
import axios from 'axios';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Buscar Vídeos',
    searchPlaceholder: 'Buscar vídeos...',
    loading: 'Carregando...',
    noResults: 'Nenhum vídeo encontrado',
    noResultsFor: 'Nenhum vídeo encontrado para',
    searchVideos: 'Buscar Vídeos',
    enterVideo: 'Digite o nome de um vídeo para buscar',
    select: 'Selecionar',
    errors: {
      searchFailed: 'Falha ao buscar vídeos'
    }
  },
  'en': {
    title: 'Search Videos',
    searchPlaceholder: 'Search for videos...',
    loading: 'Loading...',
    noResults: 'No videos found',
    noResultsFor: 'No videos found for',
    searchVideos: 'Search Videos',
    enterVideo: 'Enter a video name to search',
    select: 'Select',
    errors: {
      searchFailed: 'Failed to search videos'
    }
  }
};

interface YoutubeSearchProps {
  onSelect: (video: {
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  }) => void;
  onClose: () => void;
  isDarkMode?: boolean;
}

export function YoutubeSearch({ onSelect, onClose, isDarkMode = false }: YoutubeSearchProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [videos, setVideos] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    const searchVideos = async () => {
      if (!searchQuery.trim()) {
        setVideos([]);
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search`,
          {
            params: {
              part: 'snippet',
              maxResults: 10,
              q: searchQuery,
              type: 'video',
              key: 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8'
            }
          }
        );

        setVideos(response.data.items);
      } catch (error) {
        console.error('Error searching videos:', error);
        toast.error(t('errors.searchFailed'));
      } finally {
        setLoading(false);
      }
    };

    const debounceTimer = setTimeout(() => {
      if (searchQuery) {
        searchVideos();
      }
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery, language]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-md rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className={`p-4 border-b flex justify-between items-center ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('title')}
          </h2>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>

        <div className="p-4">
          <div className="relative mb-4">
            <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('searchPlaceholder')}
              className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
              autoFocus
            />
          </div>

          {loading ? (
            <div className="flex justify-center py-8">
              <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-red-400' : 'text-red-500'}`} />
            </div>
          ) : videos.length > 0 ? (
            <div className="max-h-96 overflow-y-auto">
              {videos.map((video) => (
                <div
                  key={video.id.videoId}
                  className={`flex items-center space-x-3 p-3 ${
                    isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
                  } rounded-lg transition-colors cursor-pointer`}
                  onClick={() => onSelect({
                    id: video.id.videoId,
                    title: video.snippet.title,
                    channelTitle: video.snippet.channelTitle,
                    thumbnailUrl: video.snippet.thumbnails.high.url
                  })}
                >
                  <img
                    src={video.snippet.thumbnails.default.url}
                    alt={video.snippet.title}
                    className="w-24 h-16 object-cover rounded-lg"
                  />
                  <div className="flex-1 min-w-0">
                    <p className={`font-medium text-sm line-clamp-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {video.snippet.title}
                    </p>
                    <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      {video.snippet.channelTitle}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : searchQuery ? (
            <p className={`text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} py-8`}>
              {t('noResultsFor')} "{searchQuery}"
            </p>
          ) : (
            <div className="text-center py-8">
              <Clapperboard className={`h-20 w-20 mx-auto ${isDarkMode ? 'text-red-400' : 'text-red-500'} mb-4`} />
              <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                {t('enterVideo')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}