import { useState, useEffect, useRef } from 'react';
import { Camera, Loader2 } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { usePhoto } from '../contexts/PhotoContext';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../lib/firebase';
import { ImageEditor } from './ImageEditor';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Foto do Perfil',
    uploadPhoto: 'Enviar Foto',
    processing: 'Processando...',
    errors: {
      uploadFailed: 'Falha ao enviar foto',
      processingFailed: 'Falha ao processar foto'
    },
    success: {
      photoUpdated: 'Foto atualizada com sucesso!'
    }
  },
  'en': {
    title: 'Profile Photo',
    uploadPhoto: 'Upload Photo',
    processing: 'Processing...',
    errors: {
      uploadFailed: 'Failed to upload photo',
      processingFailed: 'Failed to process photo'
    },
    success: {
      photoUpdated: 'Photo updated successfully!'
    }
  }
};

interface ProfilePhotoUploaderProps {
  isDarkMode?: boolean;
}

export function ProfilePhotoUploader({ isDarkMode = false }: ProfilePhotoUploaderProps) {
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const { updateUserPhoto: updateContextPhoto } = usePhoto();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const handlePhotoSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setShowEditor(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCroppedImage = async (blob: Blob) => {
    if (!user) return;

    const loadingToast = toast.loading(t('processing'));
    setUploadingPhoto(true);

    try {
      const storageRef = ref(storage, `avatars/${user.uid}`);
      const file = new File([blob], 'profile-photo.jpg', { type: 'image/jpeg' });
      await uploadBytes(storageRef, file);
      const photoURL = await getDownloadURL(storageRef);

      // Update user document
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { photoURL });

      // Update context
      updateContextPhoto(photoURL);
      
      toast.success(t('success.photoUpdated'));
    } catch (error: any) {
      console.error('Photo update failed:', error);
      toast.error(error.message || t('errors.uploadFailed'));
    } finally {
      setUploadingPhoto(false);
      setShowEditor(false);
      setSelectedImage(null);
      toast.dismiss(loadingToast);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div>
      <h2 className={`text-lg font-semibold mb-6 text-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {t('title')}
      </h2>
      <div className="flex justify-center">
        <div className="relative">
          <img
            src={user?.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user?.uid}`}
            alt="Profile"
            className="h-32 w-32 rounded-full object-cover bg-gray-100"
          />
          <label className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-lg cursor-pointer">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handlePhotoSelect}
              className="hidden"
            />
            {uploadingPhoto ? (
              <Loader2 className="h-5 w-5 animate-spin text-blue-500" />
            ) : (
              <Camera className="h-5 w-5 text-blue-500" />
            )}
          </label>
        </div>
      </div>

      {showEditor && selectedImage && (
        <ImageEditor
          image={selectedImage}
          onSave={handleCroppedImage}
          onCancel={() => {
            setShowEditor(false);
            setSelectedImage(null);
          }}
          aspectRatio={1}
          isProcessing={uploadingPhoto}
        />
      )}
    </div>
  );
}