import { Heart, X } from 'lucide-react';

interface MatchActionsProps {
  onSwipe: (liked: boolean) => Promise<void>;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    dislike: 'Não gostei',
    like: 'Gostei'
  },
  'en': {
    dislike: 'Dislike',
    like: 'Like'
  }
};

export function MatchActions({ onSwipe, isDarkMode = false, language = 'en' }: MatchActionsProps) {
  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  return (
    <div className="fixed bottom-20 left-0 right-0 flex justify-center space-x-4">
      <button
        onClick={() => onSwipe(false)}
        className={`p-4 rounded-full text-white shadow-lg transition-colors ${
          isDarkMode 
            ? 'bg-red-600 hover:bg-red-700' 
            : 'bg-red-500 hover:bg-red-600'
        }`}
        title={t('dislike')}
      >
        <X className="h-8 w-8" />
      </button>
      <button
        onClick={() => onSwipe(true)}
        className={`p-4 rounded-full text-white shadow-lg transition-colors ${
          isDarkMode 
            ? 'bg-green-600 hover:bg-green-700' 
            : 'bg-green-500 hover:bg-green-600'
        }`}
        title={t('like')}
      >
        <Heart className="h-8 w-8" />
      </button>
    </div>
  );
}