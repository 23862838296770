import { useState, useEffect } from 'react';

export type FilterOption = 'all' | 'mine';

interface ContentFilterProps {
  onFilterChange: (option: FilterOption) => void;
  selectedOption: FilterOption;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    all: 'Todos',
    mine: 'Meu'
  },
  'en': {
    all: 'All',
    mine: 'My'
  }
};

export function ContentFilter({ onFilterChange, selectedOption, isDarkMode = false, language = 'en' }: ContentFilterProps) {
  const t = (key: string) => {
    try {
      return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
    } catch (error) {
      // Fallback to English if translation is missing
      return translations['en'][key as keyof (typeof translations)['en']];
    }
  };

  return (
    <div className={`flex items-center space-x-2 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} p-1 rounded-full`}>
      <button
        onClick={() => onFilterChange('all')}
        className={`px-3 py-1 text-sm rounded-full transition-colors ${
          selectedOption === 'all'
            ? isDarkMode 
              ? 'bg-gray-900 text-white' 
              : 'bg-black text-white'
            : isDarkMode
              ? 'text-gray-300 hover:bg-gray-600'
              : 'text-gray-600 hover:bg-gray-200'
        }`}
      >
        {t('all')}
      </button>
      <button
        onClick={() => onFilterChange('mine')}
        className={`px-3 py-1 text-sm rounded-full transition-colors ${
          selectedOption === 'mine'
            ? isDarkMode 
              ? 'bg-gray-900 text-white' 
              : 'bg-black text-white'
            : isDarkMode
              ? 'text-gray-300 hover:bg-gray-600'
              : 'text-gray-600 hover:bg-gray-200'
        }`}
      >
        {t('mine')}
      </button>
    </div>
  );
}