import { Users } from 'lucide-react';

interface CommunityParticipantsProps {
  participants: any[];
  onJoinRequest: () => Promise<void>;
  onLeaveRequest: () => Promise<void>;
  isParticipant: boolean;
  isRequesting: boolean;
  communityId: string;
  userId: string;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    join: 'Participar da Comunidade',
    leave: 'Sair da Comunidade',
    joining: 'Entrando...',
    leaving: 'Saindo...',
    listening: 'ouvindo'
  },
  'en': {
    join: 'Join Community',
    leave: 'Leave Community',
    joining: 'Joining...',
    leaving: 'Leaving...',
    listening: 'listening'
  }
};

export function CommunityParticipants({ 
  isParticipant,
  isRequesting,
  onJoinRequest,
  onLeaveRequest,
  isDarkMode = false,
  language = 'en'
}: CommunityParticipantsProps) {
  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  return (
    <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg p-4`}>
      {isParticipant ? (
        <button
          onClick={onLeaveRequest}
          disabled={isRequesting}
          className={`w-full px-4 py-2 text-white rounded-full text-sm font-medium disabled:opacity-50 flex items-center justify-center space-x-2 ${
            isDarkMode 
              ? 'bg-red-600 hover:bg-red-700' 
              : 'bg-red-500 hover:bg-red-600'
          }`}
        >
          {isRequesting ? t('leaving') : t('leave')}
        </button>
      ) : (
        <button
          onClick={onJoinRequest}
          disabled={isRequesting}
          className={`w-full px-4 py-2 text-white rounded-full text-sm font-medium disabled:opacity-50 flex items-center justify-center space-x-2 ${
            isDarkMode 
              ? 'bg-blue-600 hover:bg-blue-700' 
              : 'bg-black hover:bg-gray-800'
          }`}
        >
          {isRequesting ? t('joining') : t('join')}
        </button>
      )}
    </div>
  );
}