import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { X, Loader2, User } from 'lucide-react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

interface UserBioProps {
  userId: string;
  username: string;
  onClose: () => void;
  isDarkMode?: boolean;
}

const translations = {
  'pt-BR': {
    bio: 'Biografia',
    loading: 'Carregando...',
    noBio: 'Nenhuma biografia disponível',
    errors: {
      loadFailed: 'Falha ao carregar biografia'
    }
  },
  'en': {
    bio: 'Bio',
    loading: 'Loading...',
    noBio: 'No bio available',
    errors: {
      loadFailed: 'Failed to load bio'
    }
  }
};

export function UserBio({ userId, username, onClose, isDarkMode = false }: UserBioProps) {
  const [bio, setBio] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    // Detect user's language
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    const fetchUserBio = async () => {
      try {
        setLoading(true);
        const userDoc = await getDoc(doc(db, 'users', userId));
        
        if (userDoc.exists()) {
          setBio(userDoc.data().bio || null);
        }
      } catch (error) {
        console.error('Error fetching user bio:', error);
        toast.error(t('errors.loadFailed'));
      } finally {
        setLoading(false);
      }
    };

    fetchUserBio();
  }, [userId, language]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-md rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl`}>
        <div className={`p-4 border-b flex justify-between items-center ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <Link 
            to={`/profile/${username}`}
            className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'} hover:underline`}
          >
            @{username}'s {t('bio')}
          </Link>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>
        
        <div className="p-4">
          {loading ? (
            <div className="flex justify-center py-8">
              <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            </div>
          ) : bio ? (
            <p className={`whitespace-pre-wrap break-words ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              {bio}
            </p>
          ) : (
            <div className="text-center py-8">
              <User className={`h-12 w-12 mx-auto mb-3 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
              <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                {t('noBio')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}