import { useState, useEffect } from 'react';
import { CreatePost } from '../components/CreatePost';
import { Settings, Bell, Search } from 'lucide-react';
import { auth } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { ContentFilter, FilterOption } from '../components/ContentFilter';
import { StoriesGallery } from '../components/StoriesGallery';
import { PostList } from '../components/PostList';
import { Link } from 'react-router-dom';
import { NotificationBadge } from '../components/NotificationBadge';
import { fetchPosts } from '../utils/posts';
import type { PostData } from '../utils/posts/types';
import toast from 'react-hot-toast';

export function Home() {
  const [posts, setPosts] = useState<PostData[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filterOption, setFilterOption] = useState<FilterOption>('all');
  const [lastDoc, setLastDoc] = useState<any>(null);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    try {
      const userLang = navigator.language;
      setLanguage(userLang.startsWith('pt') ? 'pt-BR' : 'en');
    } catch (error) {
      console.error('Language detection error:', error);
      setLanguage('en'); // Fallback to English
    }
  }, []);

  useEffect(() => {
    loadInitialPosts();
  }, [user, filterOption]);

  const loadInitialPosts = async () => {
    try {
      setLoading(true);
      const result = await fetchPosts(user?.uid || '', filterOption);
      setPosts(result.posts);
      setLastDoc(result.lastDoc);
      setHasMore(result.hasMore);
    } catch (error) {
      console.error('Error loading posts:', error);
      toast.error('Failed to load posts');
    } finally {
      setLoading(false);
    }
  };

  const loadMorePosts = async () => {
    if (!user || !lastDoc || loadingMore || !hasMore) return;

    try {
      setLoadingMore(true);
      const result = await fetchPosts(user.uid, filterOption, lastDoc);
      setPosts(prev => [...prev, ...result.posts]);
      setLastDoc(result.lastDoc);
      setHasMore(result.hasMore);
    } catch (error) {
      console.error('Error loading more posts:', error);
      toast.error('Failed to load more posts');
    } finally {
      setLoadingMore(false);
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
            Poosting
          </h1>
          <div className="flex items-center space-x-4">
            <ContentFilter 
              onFilterChange={setFilterOption}
              selectedOption={filterOption}
              isDarkMode={isDarkMode}
              language={language}
            />
            <Link
              to="/config"
              className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
              title="Settings"
            >
              <Settings className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
            </Link>
            <Link
              to="/notifications"
              className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
              title="Notifications"
            >
              <NotificationBadge />
            </Link>
          </div>
        </div>
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <StoriesGallery />
        <CreatePost />
        
        <PostList
          posts={posts}
          loading={loading}
          loadingMore={loadingMore}
          hasMore={hasMore}
          onLoadMore={loadMorePosts}
          isDarkMode={isDarkMode}
        />
      </main>
    </div>
  );
}