import { Star } from 'lucide-react';

interface EmptyMatchStateProps {
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Não há mais perfis',
    subtitle: 'Volte em {time} para mais matches!',
    noTime: 'Volte mais tarde para mais matches!'
  },
  'en': {
    title: 'No more profiles',
    subtitle: 'Come back in {time} for more matches!',
    noTime: 'Check back later for more matches!'
  }
};

export function EmptyMatchState({ isDarkMode = false, language = 'en' }: EmptyMatchStateProps) {
  const t = (key: string, params?: Record<string, string>) => {
    if (!translations) return key;

    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  return (
    <div className="flex flex-col items-center justify-center h-[60vh] text-center">
      <Star className={`h-16 w-16 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'} mb-4`} />
      <h2 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {t('title')}
      </h2>
      <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
        {t('noTime')}
      </p>
    </div>
  );
}