import { useState, useEffect, useRef } from 'react';
import { Camera, Loader2, UserPlus, UserMinus, Diamond } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { usePhoto } from '../contexts/PhotoContext';
import { doc, updateDoc, onSnapshot, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { VerificationBadge } from './VerificationBadge';
import { ProfileProgress } from './ProfileProgress';
import { toggleDiamondStatus } from '../utils/admin/users';
import toast from 'react-hot-toast';

interface ProfileHeaderProps {
  profile: {
    uid: string;
    name: string;
    username: string;
    photoURL: string;
    bio?: string;
    allies?: string[];
    isDiamond?: boolean;
  };
  currentUserId: string | undefined;
  isOwnProfile: boolean;
  isDarkMode?: boolean;
  language?: string;
}

export function ProfileHeader({ 
  profile, 
  currentUserId, 
  isOwnProfile, 
  isDarkMode = false,
  language = 'en'
}: ProfileHeaderProps) {
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [isUpdatingFollow, setIsUpdatingFollow] = useState(false);
  const [alliesCount, setAlliesCount] = useState(profile.allies?.length || 0);
  const [isFollowing, setIsFollowing] = useState(false);
  const [togglingDiamond, setTogglingDiamond] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const { updateUserPhoto: updateContextPhoto } = usePhoto();
  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  useEffect(() => {
    if (!profile.uid) return;

    // Subscribe to real-time updates of the user's allies
    const unsubscribe = onSnapshot(
      doc(db, 'users', profile.uid),
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setAlliesCount(data.allies?.length || 0);
          setIsFollowing(data.allies?.includes(currentUserId) || false);
        }
      }
    );

    return () => unsubscribe();
  }, [profile.uid, currentUserId]);

  const handlePhotoSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !user) return;

    const loadingToast = toast.loading('Updating your photo everywhere...');
    setUploadingPhoto(true);

    try {
      const storageRef = ref(storage, `avatars/${user.uid}`);
      await uploadBytes(storageRef, file);
      const photoURL = await getDownloadURL(storageRef);
      updateContextPhoto(photoURL);
      toast.success('Photo updated successfully!');
    } catch (error: any) {
      console.error('Photo update failed:', error);
      toast.error(error.message || 'Failed to update photo');
    } finally {
      setUploadingPhoto(false);
      toast.dismiss(loadingToast);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleFollowToggle = async () => {
    if (!currentUserId || isOwnProfile || isUpdatingFollow) return;

    try {
      setIsUpdatingFollow(true);
      const userRef = doc(db, 'users', profile.uid);

      // Update local state immediately for better UX
      const newAlliesCount = isFollowing ? alliesCount - 1 : alliesCount + 1;
      setAlliesCount(newAlliesCount);
      setIsFollowing(!isFollowing);

      if (isFollowing) {
        await updateDoc(userRef, {
          allies: arrayRemove(currentUserId)
        });

        toast.success('Unfollowed successfully', {
          icon: '👋',
          style: {
            background: '#000000',
            color: '#ffffff',
            fontWeight: 'bold'
          }
        });
      } else {
        await updateDoc(userRef, {
          allies: arrayUnion(currentUserId)
        });

        toast.success('Following successfully', {
          icon: '🤝',
          style: {
            background: '#000000',
            color: '#ffffff',
            fontWeight: 'bold'
          }
        });
      }
    } catch (error) {
      console.error('Error updating follow status:', error);
      toast.error('Failed to update follow status');
      // Revert local state on error
      setAlliesCount(isFollowing ? alliesCount + 1 : alliesCount - 1);
      setIsFollowing(!isFollowing);
    } finally {
      setIsUpdatingFollow(false);
    }
  };

  const handleToggleDiamond = async () => {
    if (!isAdmin || !user?.email) return;

    try {
      setTogglingDiamond(true);
      await toggleDiamondStatus(profile.uid, user.email);
    } catch (error: any) {
      console.error('Error toggling diamond status:', error);
      toast.error(error.message || 'Failed to update diamond status');
    } finally {
      setTogglingDiamond(false);
    }
  };

  return (
    <div className="relative">
      <div className={`h-40 ${isDarkMode ? 'bg-gradient-to-r from-gray-800 to-gray-700' : 'bg-gradient-to-r from-gray-200 to-gray-300'}`} />
      
      <div className="px-4 pb-6">
        <div className="flex flex-col items-center -mt-20">
          <div className="relative">
            <img
              src={profile.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${profile.uid}`}
              alt={profile.name}
              className="h-40 w-40 rounded-full border-4 border-white object-cover bg-white shadow-lg"
            />
            {isOwnProfile && (
              <>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoSelect}
                  className="hidden"
                />
                <button
                  onClick={() => fileInputRef.current?.click()}
                  disabled={uploadingPhoto}
                  className={`absolute bottom-2 right-2 p-2 rounded-full shadow-lg ${
                    isDarkMode 
                      ? 'bg-gray-800 hover:bg-gray-700' 
                      : 'bg-white hover:bg-gray-100'
                  } transition-colors`}
                  title="Change profile photo"
                >
                  {uploadingPhoto ? (
                    <Loader2 className="h-5 w-5 animate-spin text-blue-500" />
                  ) : (
                    <Camera className="h-5 w-5 text-blue-500" />
                  )}
                </button>
              </>
            )}
          </div>

          <div className="text-center w-full mt-4">
            <div className="flex items-center justify-center space-x-2">
              <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {profile.name}
              </h2>
              <VerificationBadge alliesCount={alliesCount} isDiamond={profile.isDiamond} />
              {isAdmin && (
                <button
                  onClick={handleToggleDiamond}
                  disabled={togglingDiamond}
                  className={`p-1 rounded-full ${
                    profile.isDiamond 
                      ? 'bg-blue-100 text-blue-500 hover:bg-blue-200' 
                      : 'bg-gray-100 text-gray-500 hover:bg-gray-200'
                  }`}
                  title={profile.isDiamond ? 'Remove Diamond Status' : 'Add Diamond Status'}
                >
                  <Diamond className="h-4 w-4" />
                </button>
              )}
            </div>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-500'}>
              @{profile.username}
            </p>

            <div className="mt-4 flex flex-col items-center space-y-2">
              {!isOwnProfile && currentUserId && (
                <button
                  onClick={handleFollowToggle}
                  disabled={isUpdatingFollow}
                  className={`px-6 py-2 rounded-full font-medium flex items-center justify-center space-x-2 disabled:opacity-50 ${
                    isFollowing 
                      ? isDarkMode
                        ? 'bg-red-600 text-white hover:bg-red-700'
                        : 'bg-red-500 text-white hover:bg-red-600'
                      : isDarkMode
                        ? 'bg-blue-600 text-white hover:bg-blue-700'
                        : 'bg-black text-white hover:bg-gray-800'
                  }`}
                >
                  {isUpdatingFollow ? (
                    <Loader2 className="h-5 w-5 animate-spin" />
                  ) : isFollowing ? (
                    <>
                      <UserMinus className="h-5 w-5" />
                      <span>Unfollow</span>
                    </>
                  ) : (
                    <>
                      <UserPlus className="h-5 w-5" />
                      <span>Follow</span>
                    </>
                  )}
                </button>
              )}
            </div>

            <ProfileProgress 
              alliesCount={alliesCount} 
              isDarkMode={isDarkMode}
              language={language}
            />
          </div>
        </div>
      </div>
    </div>
  );
}