import { useState, useEffect } from 'react';
import { LogOut, BookMarked, Save, Loader2, Search, User } from 'lucide-react';
import { auth, db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { MainMenu } from '../components/MainMenu';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

interface UserNotes {
  uid: string;
  username: string;
  name: string;
  photoURL: string;
  notes: string;
}

export function Book() {
  const [notes, setNotes] = useState('');
  const [saving, setSaving] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<UserNotes[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserNotes | null>(null);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (!user) return;

    const loadNotes = async () => {
      try {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          setNotes(userDoc.data().notes || '');
        }
      } catch (error) {
        console.error('Error loading notes:', error);
        toast.error('Failed to load notes');
      }
    };

    loadNotes();
  }, [user]);

  const handleSave = async () => {
    if (!user) return;

    try {
      setSaving(true);
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        await updateDoc(userRef, { notes: notes.trim() });
      } else {
        await setDoc(userRef, {
          uid: user.uid,
          notes: notes.trim(),
          createdAt: new Date()
        });
      }

      toast.success('Notes saved successfully');
    } catch (error) {
      console.error('Error saving notes:', error);
      toast.error('Failed to save notes');
    } finally {
      setSaving(false);
    }
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setSearchResults([]);
      return;
    }

    try {
      setLoading(true);
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('username', '>=', searchQuery.toLowerCase()),
        where('username', '<=', searchQuery.toLowerCase() + '\uf8ff')
      );

      const snapshot = await getDocs(q);
      const results = snapshot.docs
        .map(doc => {
          const data = doc.data();
          return {
            uid: doc.id,
            username: data.username,
            name: data.name,
            photoURL: data.photoURL,
            notes: data.notes || ''
          };
        });

      setSearchResults(results);
    } catch (error) {
      console.error('Error searching users:', error);
      toast.error('Failed to search users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      const timer = setTimeout(handleSearch, 500);
      return () => clearTimeout(timer);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <BookMarked className={`h-6 w-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              Book Notes
            </h1>
          </div>
          <button
            onClick={() => auth.signOut()}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <LogOut className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <div className="relative">
            <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search users' notes..."
              className={`w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
            />
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {searchQuery ? (
          <div>
            {loading ? (
              <div className="flex justify-center p-8">
                <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
              </div>
            ) : searchResults.length > 0 ? (
              <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
                {searchResults.map((result) => (
                  <div 
                    key={result.uid}
                    className={`p-4 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} cursor-pointer`}
                    onClick={() => setSelectedUser(selectedUser?.uid === result.uid ? null : result)}
                  >
                    <div className="flex items-start space-x-3">
                      <Link to={`/profile/${result.username}`}>
                        <img
                          src={result.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${result.uid}`}
                          alt={result.name}
                          className="h-10 w-10 rounded-full"
                        />
                      </Link>
                      <div className="flex-1 min-w-0">
                        <Link 
                          to={`/profile/${result.username}`}
                          className={`font-medium hover:underline ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
                        >
                          {result.name}
                        </Link>
                        <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                          @{result.username}
                        </p>
                        {selectedUser?.uid === result.uid && (
                          <div className={`mt-4 p-4 rounded-lg ${
                            isDarkMode ? 'bg-gray-700' : 'bg-gray-50'
                          }`}>
                            <pre className={`whitespace-pre-wrap break-words text-sm ${
                              isDarkMode ? 'text-gray-300' : 'text-gray-700'
                            }`}>
                              {result.notes || 'No notes yet'}
                            </pre>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-8 text-center">
                <User className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
                <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                  No users found matching "{searchQuery}"
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="p-4 space-y-4">
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Write your public notes here..."
              className={`w-full h-64 p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
            />
            
            <button
              onClick={handleSave}
              disabled={saving}
              className={`w-full flex items-center justify-center space-x-2 px-4 py-3 ${
                isDarkMode 
                  ? 'bg-blue-600 text-white hover:bg-blue-700' 
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              } rounded-lg disabled:opacity-50 transition-colors`}
            >
              {saving ? (
                <>
                  <Loader2 className="h-5 w-5 animate-spin" />
                  <span>Saving...</span>
                </>
              ) : (
                <>
                  <Save className="h-5 w-5" />
                  <span>Save Public Notes</span>
                </>
              )}
            </button>
          </div>
        )}
      </main>
    </div>
  );
}