import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import toast from 'react-hot-toast';

const ADMIN_EMAIL = 'afonsoalcantara@outlook.com';

export async function isAdmin(email: string | null): Promise<boolean> {
  return email?.toLowerCase() === ADMIN_EMAIL.toLowerCase();
}

export async function toggleDiamondStatus(userId: string, currentUserEmail: string | null): Promise<boolean> {
  if (!await isAdmin(currentUserEmail)) {
    throw new Error('Unauthorized: Admin access required');
  }

  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('User not found');
    }

    const currentDiamondStatus = userDoc.data().isDiamond || false;
    await updateDoc(userRef, {
      isDiamond: !currentDiamondStatus
    });

    toast.success(`Diamond status ${!currentDiamondStatus ? 'added' : 'removed'} successfully`);
    return !currentDiamondStatus;
  } catch (error) {
    console.error('Error toggling diamond status:', error);
    throw error;
  }
}