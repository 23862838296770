import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc, collection, query, orderBy, getDocs, onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { ArrowLeft, Loader2, MessageSquarePlus } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';
import { CommunityParticipants } from '../components/CommunityParticipants';
import { CreateTopic } from '../components/CreateTopic';
import { TopicList } from '../components/TopicList';
import { CommunityAdminControls } from '../components/CommunityAdminControls';
import ReactMarkdown from 'react-markdown';
import { createNewTopic } from '../utils/community/topics';
import { joinCommunity, leaveCommunity } from '../utils/community';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    back: 'Voltar',
    loading: 'Carregando...',
    errors: {
      communityNotFound: 'Comunidade não encontrada',
      failedToLoad: 'Falha ao carregar comunidade',
      failedToLoadTopics: 'Falha ao carregar tópicos',
      failedToCreateTopic: 'Falha ao criar tópico',
      failedToJoin: 'Falha ao entrar na comunidade',
      failedToLeave: 'Falha ao sair da comunidade'
    },
    success: {
      topicCreated: 'Tópico criado com sucesso',
      joined: 'Você entrou na comunidade com sucesso',
      left: 'Você saiu da comunidade com sucesso'
    },
    buttons: {
      createTopic: 'Criar Novo Tópico',
      backToCommunities: 'Voltar para Comunidades'
    },
    topics: {
      title: 'Tópicos',
      noTopics: 'Nenhum tópico ainda',
      beFirst: 'Seja o primeiro a criar um tópico!',
      responses: 'respostas',
      lastResponse: 'Última resposta'
    }
  },
  'en': {
    back: 'Back',
    loading: 'Loading...',
    errors: {
      communityNotFound: 'Community not found',
      failedToLoad: 'Failed to load community',
      failedToLoadTopics: 'Failed to load topics',
      failedToCreateTopic: 'Failed to create topic',
      failedToJoin: 'Failed to join community',
      failedToLeave: 'Failed to leave community'
    },
    success: {
      topicCreated: 'Topic created successfully',
      joined: 'Successfully joined community',
      left: 'Successfully left community'
    },
    buttons: {
      createTopic: 'Create New Topic',
      backToCommunities: 'Back to Communities'
    },
    topics: {
      title: 'Topics',
      noTopics: 'No topics yet',
      beFirst: 'Be the first to create a topic!',
      responses: 'responses',
      lastResponse: 'Last response'
    }
  }
};

interface Community {
  id: string;
  title: string;
  question: string;
  imageUrl?: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  participants: string[];
}

interface Topic {
  id: string;
  title: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  responseCount: number;
  lastResponseAt?: Date;
}

export function CommunityDetails() {
  const { id } = useParams<{ id: string }>();
  const [community, setCommunity] = useState<Community | null>(null);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [participants, setParticipants] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateTopic, setShowCreateTopic] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const dateLocale = language === 'pt-BR' ? ptBR : enUS;

  useEffect(() => {
    if (!id) return;

    const communityRef = doc(db, 'communities', id);
    const unsubscribeCommunity = onSnapshot(
      communityRef,
      async (docSnapshot) => {
        if (!docSnapshot.exists()) {
          toast.error(t('errors.communityNotFound'));
          return;
        }

        const communityData = {
          id: docSnapshot.id,
          ...docSnapshot.data(),
          participants: docSnapshot.data().participants || [],
          timestamp: docSnapshot.data().timestamp?.toDate()
        } as Community;

        setCommunity(communityData);

        if (communityData.participants?.length) {
          const usersRef = collection(db, 'users');
          const participantsData = await Promise.all(
            communityData.participants.map(async (uid) => {
              try {
                const userDoc = await getDoc(doc(usersRef, uid));
                if (!userDoc.exists()) return null;
                
                const userData = userDoc.data();
                return {
                  uid,
                  name: userData.name || 'Unknown User',
                  username: userData.username || uid,
                  photoURL: userData.photoURL || null,
                  allies: userData.allies || []
                };
              } catch (error) {
                console.error(`Error fetching participant ${uid}:`, error);
                return null;
              }
            })
          );

          setParticipants(participantsData.filter((p) => p !== null));
        } else {
          setParticipants([]);
        }
      },
      (error) => {
        console.error('Error listening to community:', error);
        toast.error(t('errors.failedToLoad'));
      }
    );

    const topicsRef = collection(db, 'communities', id, 'topics');
    const topicsQuery = query(topicsRef, orderBy('lastResponseAt', 'desc'));
    
    const unsubscribeTopics = onSnapshot(
      topicsQuery,
      async (snapshot) => {
        const topicsData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = doc.data();
            
            const responsesRef = collection(db, 'communities', id, 'topics', doc.id, 'responses');
            const responsesSnapshot = await getDocs(responsesRef);
            
            return {
              id: doc.id,
              ...data,
              timestamp: data.timestamp?.toDate(),
              lastResponseAt: data.lastResponseAt?.toDate(),
              responseCount: responsesSnapshot.size
            } as Topic;
          })
        );

        setTopics(topicsData);
        setLoading(false);
      },
      (error) => {
        console.error('Error listening to topics:', error);
        toast.error(t('errors.failedToLoadTopics'));
        setLoading(false);
      }
    );

    return () => {
      unsubscribeCommunity();
      unsubscribeTopics();
    };
  }, [id, language]);

  const handleCreateTopic = async (title: string, content: string) => {
    if (!user || !id || !community) return;

    try {
      const newTopic = await createNewTopic({
        communityId: id,
        title,
        content,
        user: {
          id: user.uid,
          name: user.displayName || '',
          username: user.displayName?.replace('@', '') || '',
          photoURL: user.photoURL || ''
        }
      });

      setTopics(prev => [newTopic, ...prev]);
      setShowCreateTopic(false);
      toast.success(t('success.topicCreated'));
    } catch (error) {
      console.error('Error creating topic:', error);
      toast.error(t('errors.failedToCreateTopic'));
    }
  };

  const handleJoinRequest = async () => {
    if (!user || !id || isJoining) return;

    try {
      setIsJoining(true);
      const result = await joinCommunity(id, user.uid);
      if (result.success) {
        toast.success(t('success.joined'));
      } else {
        toast.error(result.error || t('errors.failedToJoin'));
      }
    } catch (error) {
      console.error('Error joining community:', error);
      toast.error(t('errors.failedToJoin'));
    } finally {
      setIsJoining(false);
    }
  };

  const handleLeaveRequest = async () => {
    if (!user || !id || isJoining) return;

    try {
      setIsJoining(true);
      const result = await leaveCommunity(id, user.uid);
      if (result.success) {
        toast.success(t('success.left'));
      } else {
        toast.error(result.error || t('errors.failedToLeave'));
      }
    } catch (error) {
      console.error('Error leaving community:', error);
      toast.error(t('errors.failedToLeave'));
    } finally {
      setIsJoining(false);
    }
  };

  if (loading) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} flex items-center justify-center`}>
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  if (!community) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} flex flex-col items-center justify-center p-4`}>
        <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {t('errors.communityNotFound')}
        </h2>
        <Link
          to="/communities"
          className={`flex items-center space-x-2 ${
            isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-500 hover:text-blue-600'
          }`}
        >
          <ArrowLeft className="h-5 w-5" />
          <span>{t('buttons.backToCommunities')}</span>
        </Link>
      </div>
    );
  }

  const isParticipant = user ? community.participants?.includes(user.uid) : false;

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <Link
            to="/communities"
            className="flex items-center space-x-2"
          >
            <ArrowLeft className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
            <span className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{t('back')}</span>
          </Link>
          <CommunityAdminControls communityId={community.id} />
        </div>
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <div className={`p-4 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex items-start space-x-4">
            {community.imageUrl && (
              <img
                src={community.imageUrl}
                alt={community.title}
                className="w-20 h-20 rounded-lg object-cover"
              />
            )}
            <div className="flex-1">
              <h1 className={`text-2xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {community.title}
              </h1>
              <div className={`prose max-w-none ${isDarkMode ? 'prose-invert' : ''}`}>
                <ReactMarkdown>{community.question}</ReactMarkdown>
              </div>
            </div>
          </div>
        </div>

        <div className={`p-4 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <CommunityParticipants
            participants={participants}
            isParticipant={isParticipant}
            isRequesting={isJoining}
            communityId={id || ''}
            userId={user?.uid || ''}
            onJoinRequest={handleJoinRequest}
            onLeaveRequest={handleLeaveRequest}
            isDarkMode={isDarkMode}
            language={language}
          />
        </div>

        {isParticipant && (
          <div className={`p-4 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
            <button
              onClick={() => setShowCreateTopic(true)}
              className={`w-full flex items-center justify-center space-x-2 px-4 py-2 rounded-lg ${
                isDarkMode 
                  ? 'bg-blue-600 text-white hover:bg-blue-700' 
                  : 'bg-black text-white hover:bg-gray-900'
              }`}
            >
              <MessageSquarePlus className="h-5 w-5" />
              <span>{t('buttons.createTopic')}</span>
            </button>
          </div>
        )}

        <TopicList 
          communityId={community.id} 
          topics={topics} 
          isDarkMode={isDarkMode}
          language={language}
        />

        {showCreateTopic && (
          <CreateTopic
            onClose={() => setShowCreateTopic(false)}
            onCreate={handleCreateTopic}
            isDarkMode={isDarkMode}
            language={language}
          />
        )}
      </main>
    </div>
  );
}