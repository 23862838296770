import { useState, useEffect } from 'react';
import { Heart, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Profile } from '../../types/match';
import { MatchBanner } from './MatchBanner';
import toast from 'react-hot-toast';

interface MatchCardProps {
  profile: Profile;
  onSwipe: (liked: boolean) => Promise<void>;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    like: 'Gostei',
    dislike: 'Não gostei',
    matched: 'Match! ❤️',
    waitMessage: 'Aguarde 5 minutos antes de dar match novamente',
    matchLimit: 'Você atingiu o limite de 3 matches por dia. Tente novamente em 24 horas'
  },
  'en': {
    like: 'Like',
    dislike: 'Dislike',
    matched: 'Matched! ❤️',
    waitMessage: 'Wait 5 minutes before matching again',
    matchLimit: 'You\'ve reached the limit of 3 matches per day. Try again in 24 hours'
  }
};

const SWIPE_THRESHOLD = 100;

export function MatchCard({ profile, onSwipe, isDarkMode = false, language = 'en' }: MatchCardProps) {
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(null);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [showBanner, setShowBanner] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    const point = 'touches' in e ? e.touches[0] : e;
    setDragStart({ x: point.clientX, y: point.clientY });
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!dragStart) return;
    
    const point = 'touches' in e ? e.touches[0] : e;
    const newOffset = {
      x: point.clientX - dragStart.x,
      y: point.clientY - dragStart.y
    };
    setOffset(newOffset);
  };

  const handleDragEnd = async () => {
    if (!dragStart) return;

    if (Math.abs(offset.x) > SWIPE_THRESHOLD) {
      const liked = offset.x > 0;
      if (liked) {
        setShowBanner(true);
        await addDoc(collection(db, 'notifications'), {
          type: 'profile_match',
          recipientId: profile.uid,
          senderId: profile.uid,
          senderName: profile.name,
          senderUsername: profile.username,
          senderPhotoURL: profile.photoURL,
          message: t('matched'),
          timestamp: serverTimestamp(),
          read: false,
          matchData: {
            matchType: 'heart',
            matchStatus: 'matched',
            matchTimestamp: serverTimestamp()
          }
        });
      }
      await onSwipe(liked);
    }

    setDragStart(null);
    setOffset({ x: 0, y: 0 });
  };

  const rotation = (offset.x / SWIPE_THRESHOLD) * 20;
  const scale = Math.max(1 - Math.abs(offset.x) / 1000, 0.9);
  const opacity = Math.max(1 - Math.abs(offset.x) / 500, 0.5);

  // Generate optimized image URL based on viewport size
  const getOptimizedImageUrl = (url: string) => {
    // For Cloudinary URLs
    if (url.includes('cloudinary.com')) {
      return url.replace('/upload/', '/upload/w_1080,h_1920,c_fill,f_auto,q_auto/');
    }
    // For Imgix URLs
    else if (url.includes('imgix.net')) {
      return `${url}?w=1080&h=1920&fit=crop&auto=format,compress`;
    }
    // For Firebase Storage URLs
    else if (url.includes('firebasestorage.googleapis.com')) {
      return `${url}_1080x1920`;
    }
    // For Unsplash URLs
    else if (url.includes('unsplash.com')) {
      return `${url}&w=1080&h=1920&fit=crop&q=80&auto=format`;
    }
    return url;
  };

  return (
    <div
      className={`absolute w-full max-w-sm ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-xl shadow-lg overflow-hidden cursor-grab active:cursor-grabbing`}
      style={{
        transform: `rotate(${rotation}deg) scale(${scale}) translate(${offset.x}px, ${offset.y}px)`,
        opacity,
        transition: dragStart ? 'none' : 'all 0.5s ease'
      }}
      onMouseDown={handleDragStart}
      onMouseMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchMove={handleDragMove}
      onTouchEnd={handleDragEnd}
    >
      {showBanner && (
        <MatchBanner 
          photoURL={profile.photoURL} 
          name={profile.name} 
          isDarkMode={isDarkMode}
          language={language}
        />
      )}

      <Link to={`/profile/${profile.username}`}>
        <div className="relative aspect-square w-full">
          {/* Loading skeleton */}
          {!imageLoaded && (
            <div className={`absolute inset-0 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} animate-pulse`} />
          )}
          
          {/* Optimized image */}
          <img
            src={getOptimizedImageUrl(profile.photoURL)}
            alt={profile.name}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            onLoad={() => setImageLoaded(true)}
            loading="eager"
          />
        </div>
      </Link>
      
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-6">
        <h2 className="text-2xl font-bold text-white mb-1">
          {profile.name}
        </h2>
        <p className="text-white/80">@{profile.username}</p>
      </div>

      <div 
        className="absolute top-4 left-4 bg-green-500 text-white px-4 py-2 rounded-full transform rotate-[-20deg]"
        style={{ opacity: offset.x > SWIPE_THRESHOLD ? 1 : 0 }}
        title={t('like')}
      >
        <Heart className="h-6 w-6 fill-current" />
      </div>
      <div 
        className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-full transform rotate-[20deg]"
        style={{ opacity: offset.x < -SWIPE_THRESHOLD ? 1 : 0 }}
        title={t('dislike')}
      >
        <X className="h-6 w-6" />
      </div>
    </div>
  );
}