import { Loader2, Video } from 'lucide-react';
import type { YouTubeVideo } from '../../utils/youtube';

interface VideoGridProps {
  videos: YouTubeVideo[];
  loading: boolean;
  onVideoSelect: (videoId: string) => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    loading: 'Carregando...',
    noVideos: 'Nenhum vídeo encontrado',
    searchVideos: 'Buscar Vídeos',
    enterVideo: 'Digite o nome de um vídeo para buscar'
  },
  'en': {
    loading: 'Loading...',
    noVideos: 'No videos found',
    searchVideos: 'Search Videos',
    enterVideo: 'Enter a video name to search'
  }
};

export function VideoGrid({ videos, loading, onVideoSelect, isDarkMode = false, language = 'en' }: VideoGridProps) {
  const getVideoId = (video: YouTubeVideo): string => {
    return typeof video.id === 'string' ? video.id : video.id.videoId;
  };

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
      {videos.map((video) => (
        <div
          key={getVideoId(video)}
          onClick={() => onVideoSelect(getVideoId(video))}
          className={`cursor-pointer ${
            isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
          } rounded-lg overflow-hidden transition-colors`}
        >
          <div className="relative aspect-video">
            <img
              src={video.snippet.thumbnails.high.url}
              alt={video.snippet.title}
              className="w-full h-full object-cover"
              loading="lazy"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center p-4">
              <div className="absolute top-2 left-2">
                <Video className="h-6 w-6 text-white" />
              </div>
            </div>
          </div>
          <div className="p-3">
            <h3 className={`font-medium line-clamp-2 text-sm ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {video.snippet.title}
            </h3>
            <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {video.snippet.channelTitle}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}