import { useState, useEffect, useRef } from 'react';
import { Search, X, Loader2, Music, Play, Pause } from 'lucide-react';
import { SpotifyApi } from '@spotify/web-api-ts-sdk';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Buscar Músicas',
    searchPlaceholder: 'Buscar músicas...',
    loading: 'Carregando...',
    noResults: 'Nenhuma música encontrada',
    searchMusic: 'Buscar Músicas',
    enterSong: 'Digite o nome de uma música para buscar',
    select: 'Selecionar',
    noPreview: 'Prévia não disponível para esta música',
    errors: {
      spotifyError: 'Falha ao conectar ao Spotify',
      searchError: 'Falha ao buscar músicas',
      previewError: 'Falha ao reproduzir prévia'
    }
  },
  'en': {
    title: 'Search Music',
    searchPlaceholder: 'Search for songs...',
    loading: 'Loading...',
    noResults: 'No songs found',
    searchMusic: 'Search Music',
    enterSong: 'Enter a song name to search',
    select: 'Select',
    noPreview: 'No preview available for this song',
    errors: {
      spotifyError: 'Failed to connect to Spotify',
      searchError: 'Failed to search tracks',
      previewError: 'Failed to play preview'
    }
  }
};

interface SpotifySearchProps {
  onSelect: (track: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  }) => void;
  onClose: () => void;
  isDarkMode?: boolean;
}

const SPOTIFY_CLIENT_ID = 'b5063a74aa6f41018315fe19fd3c7cb8';
const SPOTIFY_CLIENT_SECRET = 'c420550cced749d6966af287bb092a89';

export function SpotifySearch({ onSelect, onClose, isDarkMode = false }: SpotifySearchProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [tracks, setTracks] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [spotify, setSpotify] = useState<SpotifyApi | null>(null);
  const [playingTrackId, setPlayingTrackId] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    const initializeSpotify = async () => {
      try {
        const api = SpotifyApi.withClientCredentials(
          SPOTIFY_CLIENT_ID,
          SPOTIFY_CLIENT_SECRET
        );
        setSpotify(api);
      } catch (error) {
        console.error('Error initializing Spotify:', error);
        toast.error(t('errors.spotifyError'));
      }
    };

    initializeSpotify();

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [language]);

  const searchTracks = async () => {
    if (!searchQuery.trim() || !spotify) return;

    try {
      setLoading(true);
      const response = await spotify.search(searchQuery, ['track'], undefined, 50);
      
      const tracksWithPreview = response.tracks.items.filter(track => track.preview_url);
      setTracks(tracksWithPreview);

      if (tracksWithPreview.length === 0 && response.tracks.items.length > 0) {
        toast.error(t('noPreview'));
      }
    } catch (error) {
      console.error('Error searching tracks:', error);
      toast.error(t('errors.searchError'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery) {
        searchTracks();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const handleSelect = (track: any) => {
    onSelect({
      id: track.id,
      name: track.name,
      artists: track.artists.map((artist: any) => artist.name),
      albumName: track.album.name,
      albumImage: track.album.images[0]?.url,
      previewUrl: track.preview_url,
      spotifyUrl: track.external_urls.spotify
    });
    onClose();
  };

  const togglePreview = (track: any) => {
    if (!track.preview_url) {
      toast.error(t('noPreview'));
      return;
    }

    if (playingTrackId === track.id) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      setPlayingTrackId(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }

      const audio = new Audio(track.preview_url);
      audio.volume = 0.5;
      
      audio.onended = () => {
        setPlayingTrackId(null);
        audioRef.current = null;
      };

      audio.play().catch(error => {
        console.error('Error playing preview:', error);
        toast.error(t('errors.previewError'));
      });

      audioRef.current = audio;
      setPlayingTrackId(track.id);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-md rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className={`p-4 border-b flex justify-between items-center ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <h2 className={`text-lg font-semibold flex items-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            <Music className="h-5 w-5 mr-2 text-green-500" />
            {t('title')}
          </h2>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>

        <div className="p-4">
          <div className="relative mb-4">
            <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('searchPlaceholder')}
              className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
              autoFocus
            />
          </div>

          {loading ? (
            <div className="flex justify-center py-8">
              <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
            </div>
          ) : tracks.length > 0 ? (
            <div className="max-h-96 overflow-y-auto">
              {tracks.map((track) => (
                <div
                  key={track.id}
                  className={`flex items-center space-x-3 p-3 ${
                    isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
                  } rounded-lg transition-colors`}
                >
                  <div className="relative">
                    <img
                      src={track.album.images[0]?.url}
                      alt={track.album.name}
                      className="w-12 h-12 rounded-lg"
                    />
                    <button
                      onClick={() => togglePreview(track)}
                      className={`absolute inset-0 flex items-center justify-center ${
                        isDarkMode ? 'bg-black/40' : 'bg-black/30'
                      } hover:bg-black/50 transition-opacity rounded-lg group`}
                    >
                      {playingTrackId === track.id ? (
                        <Pause className="h-6 w-6 text-white opacity-0 group-hover:opacity-100 transition-opacity" />
                      ) : (
                        <Play className="h-6 w-6 text-white opacity-0 group-hover:opacity-100 transition-opacity" />
                      )}
                    </button>
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className={`font-medium truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {track.name}
                    </p>
                    <p className={`text-sm truncate ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      {track.artists.map((artist: any) => artist.name).join(', ')}
                    </p>
                  </div>
                  <button
                    onClick={() => handleSelect(track)}
                    className="px-3 py-1 bg-green-500 text-white text-sm rounded-full hover:bg-green-600 transition-colors"
                  >
                    {t('select')}
                  </button>
                </div>
              ))}
            </div>
          ) : searchQuery ? (
            <p className={`text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} py-8`}>
              {t('noResults')}
            </p>
          ) : (
            <div className="text-center py-8">
              <Music className={`h-20 w-20 mx-auto ${isDarkMode ? 'text-green-400' : 'text-green-500'} mb-4`} />
              <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                {t('enterSong')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}