import { useState, useEffect, useRef } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { MoreHorizontal, Loader2, Heart, Languages, MessageCircle, Send, Trash2 } from 'lucide-react';
import { auth, db } from '../lib/firebase';
import { doc, deleteDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove, addDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../components/AuthProvider';
import { isContentBlocked, isUserBlocked } from '../utils/blocking';
import { VerificationBadge } from './VerificationBadge';
import { PostContent } from './PostContent';
import { PostFooter } from './PostFooter';
import { PostLifetime } from './PostLifetime';
import { PollView } from './PollView';
import { Comments } from './Comments';
import { UserPosts } from './UserPosts';
import { UserBio } from './UserBio';
import { deleteAnyPost } from '../utils/admin';
import toast from 'react-hot-toast';

interface PostProps {
  id: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL?: string;
  timestamp: Date;
  onDelete?: () => void;
  allies?: string[];
  poll?: {
    question: string;
    options: {
      text: string;
      votes: string[];
    }[];
    totalVotes: number;
    endTime: Date;
  };
  isDarkMode?: boolean;
  isDiamond?: boolean;
}

export function Post(props: PostProps) {
  const [isHidden, setIsHidden] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [showUserPosts, setShowUserPosts] = useState(false);
  const [showUserBio, setShowUserBio] = useState(false);
  const [isVoting, setIsVoting] = useState(false);
  const [pollData, setPollData] = useState(props.poll);
  const [language, setLanguage] = useState('en');
  const { user } = useAuth();
  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';
  const postRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  useEffect(() => {
    if (!user) return;

    const contentBlocked = isContentBlocked(user.uid, props.id);
    const authorBlocked = isUserBlocked(user.uid, props.authorId);
    setIsHidden(contentBlocked || authorBlocked);
  }, [user, props.id, props.authorId]);

  useEffect(() => {
    const now = new Date().getTime();
    const postTime = props.timestamp.getTime();
    const timeDiff = now - postTime;
    const hoursOld = timeDiff / (1000 * 60 * 60);
    
    if (hoursOld > 24) {
      setIsExpired(true);
      setIsHidden(true);
    }
  }, [props.timestamp]);

  useEffect(() => {
    if (!props.poll) return;

    const postRef = doc(db, 'posts', props.id);
    const unsubscribe = onSnapshot(postRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        if (data.poll) {
          setPollData(data.poll);
        }
      }
    });

    return () => unsubscribe();
  }, [props.id, props.poll]);

  const handleDelete = async () => {
    if (!user || isDeleting) return;

    const confirmDelete = window.confirm('Are you sure you want to delete this post?');
    if (!confirmDelete) return;

    setIsDeleting(true);
    try {
      if (isAdmin && user.uid !== props.authorId) {
        await deleteAnyPost(props.id, user.email);
      } else {
        await deleteDoc(doc(db, 'posts', props.id));
      }
      
      props.onDelete?.();
      toast.success('Post deleted successfully');
    } catch (error) {
      console.error('Error deleting post:', error);
      toast.error('Failed to delete post');
    } finally {
      setIsDeleting(false);
    }
  };

  const handlePollVote = async (optionIndex: number) => {
    if (!user || !pollData || isVoting) return;

    if (new Date() > new Date(pollData.endTime)) {
      toast.error('This poll has ended');
      return;
    }

    const hasVoted = pollData.options.some(option => option.votes.includes(user.uid));
    if (hasVoted) {
      toast.error('You have already voted in this poll');
      return;
    }

    setIsVoting(true);

    try {
      const postRef = doc(db, 'posts', props.id);
      
      const updatedOptions = pollData.options.map((option, index) => {
        if (index === optionIndex) {
          return {
            ...option,
            votes: [...option.votes, user.uid]
          };
        }
        return option;
      });

      await updateDoc(postRef, {
        'poll.options': updatedOptions,
        'poll.totalVotes': pollData.totalVotes + 1
      });

      toast.success('Vote recorded successfully');
    } catch (error) {
      console.error('Error voting in poll:', error);
      toast.error('Failed to record vote');
    } finally {
      setIsVoting(false);
    }
  };

  if (isHidden || isExpired) {
    return null;
  }

  return (
    <article ref={postRef} className={`relative ${props.isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <div className="p-4">
        <div className="flex items-start space-x-3 mb-4">
          <Link to={`/profile/${props.authorUsername}`}>
            <img
              src={props.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${props.authorId}`}
              alt={props.authorName}
              className="h-10 w-10 rounded-full"
            />
          </Link>
          <div className="flex-1 min-w-0">
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <div className="flex items-center space-x-2">
                  <Link 
                    to={`/profile/${props.authorUsername}`}
                    className={`font-medium hover:underline ${props.isDarkMode ? 'text-white' : 'text-gray-900'}`}
                  >
                    {props.authorName}
                  </Link>
                  <VerificationBadge alliesCount={props.allies?.length || 0} isDiamond={props.isDiamond} />
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => setShowUserPosts(true)}
                      className={`px-2 py-1 text-xs rounded-full ${
                        props.isDarkMode 
                          ? 'bg-gray-700 text-white hover:bg-gray-600' 
                          : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                      } transition-colors`}
                    >
                      Posts
                    </button>
                    <button
                      onClick={() => setShowUserBio(true)}
                      className={`px-2 py-1 text-xs rounded-full ${
                        props.isDarkMode 
                          ? 'bg-gray-700 text-white hover:bg-gray-600' 
                          : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                      } transition-colors`}
                    >
                      bio
                    </button>
                  </div>
                </div>
                <span className={`text-sm ${props.isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {formatDistanceToNow(props.timestamp, { 
                    addSuffix: true,
                    locale: language === 'pt-BR' ? ptBR : undefined 
                  })}
                </span>
              </div>
              {(user?.uid === props.authorId || isAdmin) && (
                <button
                  onClick={handleDelete}
                  disabled={isDeleting}
                  className={`p-2 text-red-500 ${
                    props.isDarkMode ? 'hover:bg-red-900/30' : 'hover:bg-red-50'
                  } rounded-full transition-colors disabled:opacity-50`}
                  title={isAdmin && user.uid !== props.authorId ? 'Delete post (Admin)' : 'Delete post'}
                >
                  {isDeleting ? (
                    <Loader2 className="h-5 w-5 animate-spin" />
                  ) : (
                    <Trash2 className="h-5 w-5" />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>

        <PostContent 
          {...props} 
          poll={pollData}
          onPollVote={handlePollVote}
          isVoting={isVoting}
          currentUserId={user?.uid}
        />
        
        <PostLifetime timestamp={props.timestamp} isDarkMode={props.isDarkMode} />
        <PostFooter {...props} />
      </div>

      <Comments postId={props.id} authorId={props.authorId} isDarkMode={props.isDarkMode} />

      {showUserPosts && (
        <UserPosts
          userId={props.authorId}
          username={props.authorUsername}
          onClose={() => setShowUserPosts(false)}
          isDarkMode={props.isDarkMode}
        />
      )}

      {showUserBio && (
        <UserBio
          userId={props.authorId}
          username={props.authorUsername}
          onClose={() => setShowUserBio(false)}
          isDarkMode={props.isDarkMode}
        />
      )}
    </article>
  );
}