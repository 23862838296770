import { Link } from 'react-router-dom';
import { VerificationBadge } from './VerificationBadge';
import type { User } from '../types/user';

interface UserListProps {
  users: User[];
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    joined: 'Entrou em'
  },
  'en': {
    joined: 'Joined'
  }
};

export function UserList({ users, isDarkMode = false, language = 'en' }: UserListProps) {
  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  return (
    <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
      {users.map((user) => (
        <Link
          key={user.uid}
          to={`/profile/${user.username}`}
          className={`block p-4 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition-colors`}
        >
          <div className="flex items-center space-x-4">
            <img
              src={user.photoURL}
              alt={user.name}
              className="h-12 w-12 rounded-full"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.uid}`;
              }}
            />
            <div className="flex-1 min-w-0">
              <div className="flex items-center space-x-2">
                <p className={`text-sm font-medium truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {user.name}
                </p>
                {user.allies && user.allies.length > 0 && (
                  <VerificationBadge alliesCount={user.allies.length} />
                )}
              </div>
              <p className={`text-sm truncate ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                @{user.username}
              </p>
              {user.bio && (
                <p className={`text-sm truncate mt-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  {user.bio}
                </p>
              )}
              <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
                {t('joined')} {user.createdAt.toLocaleDateString()}
              </p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}