import { useState, useEffect, useRef } from 'react';
import { Camera, Music, Loader2, Image } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { analyticsEvents } from '../lib/firebase';
import { validateStoryFile, validateVideoDuration } from '../utils/stories/validation';
import { uploadStory } from '../utils/stories/upload';
import { SpotifySearch } from './SpotifySearch';
import { moderateImage } from '../utils/vision';
import toast from 'react-hot-toast';

interface CreateStoryProps {
  onStoryCreated: (story: any) => void;
  isDarkMode?: boolean;
  language?: string;
  translations?: any;
}

const STORY_COOLDOWN = 60 * 60 * 1000; // 1 hour in milliseconds

export function CreateStory({ onStoryCreated, isDarkMode = false, language = 'en', translations }: CreateStoryProps) {
  const [uploading, setUploading] = useState(false);
  const [showSpotifySearch, setShowSpotifySearch] = useState(false);
  const [lastStoryTime, setLastStoryTime] = useState<Date | null>(null);
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const [moderating, setModerating] = useState(false);
  const { user } = useAuth();
  const photoInputRef = useRef<HTMLInputElement>(null);
  const galleryInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const lastPost = localStorage.getItem('lastStoryTime');
    if (lastPost) {
      setLastStoryTime(new Date(lastPost));
    }
  }, []);

  useEffect(() => {
    if (!lastStoryTime) return;

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const lastPost = lastStoryTime.getTime();
      const remaining = Math.max(0, STORY_COOLDOWN - (now - lastPost));
      setCooldownRemaining(remaining);

      if (remaining === 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [lastStoryTime]);

  const resetFileInputs = () => {
    if (photoInputRef.current) photoInputRef.current.value = '';
    if (galleryInputRef.current) galleryInputRef.current.value = '';
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !user) return;

    if (cooldownRemaining > 0) {
      const minutesLeft = Math.ceil(cooldownRemaining / 60000);
      toast.error(`Please wait ${minutesLeft} minutes before posting another story`);
      resetFileInputs();
      return;
    }

    try {
      setModerating(true);
      
      // Convert file to base64 for Cloud Vision
      const base64Image = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(file);
      });

      // Check image with Cloud Vision
      const isImageSafe = await moderateImage(base64Image);
      if (!isImageSafe) {
        toast.error('This image contains promotional content or inappropriate material. Please choose another image.');
        resetFileInputs();
        return;
      }

      setUploading(true);
      const loadingToast = toast.loading('Creating your story...');

      const story = await uploadStory({
        file,
        type: 'image',
        userId: user.uid,
        userName: user.displayName || '',
        userPhotoURL: user.photoURL
      });

      onStoryCreated(story);
      analyticsEvents.createStory();

      const now = new Date();
      setLastStoryTime(now);
      localStorage.setItem('lastStoryTime', now.toISOString());
      
      toast.dismiss(loadingToast);
      toast.success('Story created successfully!');
      resetFileInputs();
    } catch (error: any) {
      console.error('Error processing story:', error);
      toast.error(error.message || 'Failed to create story');
      resetFileInputs();
    } finally {
      setUploading(false);
      setModerating(false);
    }
  };

  const handleSpotifyTrackSelect = async (track: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  }) => {
    if (!user) return;

    if (cooldownRemaining > 0) {
      const minutesLeft = Math.ceil(cooldownRemaining / 60000);
      toast.error(`Please wait ${minutesLeft} minutes before posting another story`);
      return;
    }

    try {
      setUploading(true);
      const loadingToast = toast.loading('Creating your story...');

      const story = await uploadStory({
        type: 'spotify',
        userId: user.uid,
        userName: user.displayName || '',
        userPhotoURL: user.photoURL,
        spotifyTrack: track
      });

      onStoryCreated(story);
      analyticsEvents.createStory();

      const now = new Date();
      setLastStoryTime(now);
      localStorage.setItem('lastStoryTime', now.toISOString());
      
      toast.dismiss(loadingToast);
      toast.success('Story created successfully!');
      setShowSpotifySearch(false);
    } catch (error: any) {
      console.error('Error creating story:', error);
      toast.error(error.message || 'Failed to create story');
    } finally {
      setUploading(false);
    }
  };

  const t = (key: string, params?: Record<string, string>) => {
    if (!translations) return key;

    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-4">
        <button
          onClick={() => photoInputRef.current?.click()}
          disabled={uploading || moderating}
          className={`flex items-center space-x-2 px-3 py-1.5 text-xs rounded-full disabled:opacity-50 ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-black text-white hover:bg-gray-900'
          }`}
        >
          <Camera className="h-4 w-4" />
          <span>{t('createStory.camera')}</span>
        </button>

        <button
          onClick={() => galleryInputRef.current?.click()}
          disabled={uploading || moderating}
          className={`flex items-center space-x-2 px-3 py-1.5 text-xs rounded-full disabled:opacity-50 ${
            isDarkMode 
              ? 'bg-gray-700 text-white hover:bg-gray-600' 
              : 'bg-black text-white hover:bg-gray-900'
          }`}
        >
          <Image className="h-4 w-4" />
          <span>{t('createStory.gallery')}</span>
        </button>

        <button
          onClick={() => setShowSpotifySearch(true)}
          disabled={uploading || moderating}
          className="flex items-center space-x-2 px-3 py-1.5 bg-green-500 text-white text-xs rounded-full hover:bg-green-600 disabled:opacity-50"
        >
          <Music className="h-4 w-4" />
          <span>{t('createStory.music')}</span>
        </button>

        <input
          ref={photoInputRef}
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleFileSelect}
          className="hidden"
          onClick={(e) => {
            (e.target as HTMLInputElement).value = '';
          }}
        />

        <input
          ref={galleryInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileSelect}
          className="hidden"
          onClick={(e) => {
            (e.target as HTMLInputElement).value = '';
          }}
        />
      </div>

      {cooldownRemaining > 0 && (
        <div className={`text-sm text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          {t('createStory.waitMessage', { time: Math.ceil(cooldownRemaining / 60000).toString() })}
        </div>
      )}

      {(uploading || moderating) && (
        <div className="flex items-center space-x-2">
          <Loader2 className={`h-4 w-4 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          <span className={`text-sm ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`}>
            {moderating ? 'Checking image...' : 'Processing...'}
          </span>
        </div>
      )}

      {showSpotifySearch && (
        <SpotifySearch
          onSelect={handleSpotifyTrackSelect}
          onClose={() => setShowSpotifySearch(false)}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
}