import { useState, useEffect } from 'react';
import { Heart } from 'lucide-react';
import { auth } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { MatchCard } from '../components/match/MatchCard';
import { MatchActions } from '../components/match/MatchActions';
import { EmptyMatchState } from '../components/match/EmptyMatchState';
import { useMatchProfiles } from '../hooks/useMatchProfiles';

export function Match() {
  const { profiles, loading, currentIndex, handleSwipe, isOnCooldown, cooldownTimeLeft, loadingMessage } = useMatchProfiles();
  const { isDarkMode } = useTheme();
  const currentProfile = profiles[currentIndex];

  if (loading) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} flex items-center justify-center`}>
        <div className="text-center p-4">
          <Heart className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-red-400' : 'text-red-500'} animate-pulse`} />
          <p className={`text-lg ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {loadingMessage}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Heart className={`h-6 w-6 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              Poosting Match
            </h1>
          </div>
        </div>
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <div className="pt-[30px]">
          {isOnCooldown ? (
            <div className="flex flex-col items-center justify-center h-[60vh] text-center p-4">
              <Heart className={`h-16 w-16 ${isDarkMode ? 'text-red-400' : 'text-red-500'} mb-4`} />
              <h2 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Come back in {cooldownTimeLeft}!
              </h2>
              <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
                You've reached your daily match limit. Check back later for more profiles!
              </p>
            </div>
          ) : currentProfile ? (
            <div className="relative h-[60vh] flex items-center justify-center">
              <MatchCard 
                profile={currentProfile} 
                onSwipe={handleSwipe} 
                isDarkMode={isDarkMode}
              />
              <MatchActions 
                onSwipe={handleSwipe} 
                isDarkMode={isDarkMode}
              />
            </div>
          ) : (
            <EmptyMatchState 
              isDarkMode={isDarkMode}
            />
          )}
        </div>
      </main>
    </div>
  );
}