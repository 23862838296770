import { useState } from 'react';
import { Heart, Star, Trash2, Flame, Loader2 } from 'lucide-react';
import { doc, updateDoc, arrayUnion, arrayRemove, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    signInToVote: 'Faça login para interagir',
    voteRemoved: 'Voto removido com sucesso',
    errors: {
      voteFailed: 'Não foi possível registrar seu voto. Tente novamente.',
      trashCooldown: 'Você poderá marcar como lixo novamente em {hours} horas',
      matchLimit: 'Você já deu 3 matches hoje. Tente novamente em {hours} horas',
      matchCooldown: 'Aguarde {minutes} minutos antes de dar match novamente'
    },
    success: {
      likes: {
        icon: '❤️',
        message: 'Você curtiu esta publicação!'
      },
      stars: {
        icon: '⭐',
        message: 'Você favoritou esta publicação!'
      },
      trash: {
        icon: '🗑️',
        message: 'Publicação marcada como lixo!'
      },
      matches: {
        icon: '🔥',
        message: 'Match realizado com sucesso!'
      }
    }
  },
  'en': {
    signInToVote: 'Please sign in to vote',
    voteRemoved: 'Vote removed',
    errors: {
      voteFailed: 'Failed to register vote',
      trashCooldown: 'You can trash vote again in {hours} hours',
      matchLimit: 'You\'ve reached the limit of 3 matches per day. Try again in {hours} hours',
      matchCooldown: 'Wait {minutes} minutes before matching again'
    },
    success: {
      likes: {
        icon: '❤️',
        message: 'Post liked!'
      },
      stars: {
        icon: '⭐',
        message: 'Post starred!'
      },
      trash: {
        icon: '🗑️',
        message: 'Post trashed!'
      },
      matches: {
        icon: '🔥',
        message: 'Match made!'
      }
    }
  }
};

interface PostVotesProps {
  postId: string;
  authorId: string;
  initialVotes: {
    likes: string[];
    stars: string[];
    trash: string[];
    matches: string[];
  };
  language?: string;
}

const TRASH_COOLDOWN_KEY = 'poosting_trash_cooldown';
const MATCH_COOLDOWN_KEY = 'poosting_match_cooldown';
const MATCH_COUNT_KEY = 'poosting_match_count';

const TRASH_COOLDOWN = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const MATCH_COOLDOWN = 5 * 60 * 1000; // 5 minutes in milliseconds
const MATCH_LIMIT = 3; // 3 matches per day
const MATCH_RESET_PERIOD = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

interface CooldownData {
  timestamp: number;
  count?: number;
}

function getStoredCooldown(key: string): CooldownData | null {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch {
    return null;
  }
}

function setStoredCooldown(key: string, data: CooldownData) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function PostVotes({ postId, authorId, initialVotes, language = 'en' }: PostVotesProps) {
  const [votes, setVotes] = useState({
    likes: initialVotes.likes || [],
    stars: initialVotes.stars || [],
    trash: initialVotes.trash || [],
    matches: initialVotes.matches || []
  });
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  const checkTrashCooldown = (): boolean => {
    const cooldown = getStoredCooldown(TRASH_COOLDOWN_KEY);
    if (!cooldown) return true;

    const timeLeft = TRASH_COOLDOWN - (Date.now() - cooldown.timestamp);
    if (timeLeft <= 0) return true;

    const hoursLeft = Math.ceil(timeLeft / (1000 * 60 * 60));
    toast.error(t('errors.trashCooldown', { hours: hoursLeft.toString() }));
    return false;
  };

  const checkMatchLimits = (): boolean => {
    const matchData = getStoredCooldown(MATCH_COOLDOWN_KEY);
    const matchCount = getStoredCooldown(MATCH_COUNT_KEY);

    // Check daily limit
    if (matchCount) {
      const timeSinceReset = Date.now() - matchCount.timestamp;
      if (timeSinceReset < MATCH_RESET_PERIOD) {
        if ((matchCount.count || 0) >= MATCH_LIMIT) {
          const hoursLeft = Math.ceil((MATCH_RESET_PERIOD - timeSinceReset) / (1000 * 60 * 60));
          toast.error(t('errors.matchLimit', { hours: hoursLeft.toString() }));
          return false;
        }
      }
    }

    // Check cooldown between matches
    if (matchData) {
      const timeLeft = MATCH_COOLDOWN - (Date.now() - matchData.timestamp);
      if (timeLeft > 0) {
        const minutesLeft = Math.ceil(timeLeft / (1000 * 60));
        toast.error(t('errors.matchCooldown', { minutes: minutesLeft.toString() }));
        return false;
      }
    }

    return true;
  };

  const updateMatchCount = () => {
    const matchCount = getStoredCooldown(MATCH_COUNT_KEY);
    const now = Date.now();

    if (!matchCount || (now - matchCount.timestamp) >= MATCH_RESET_PERIOD) {
      setStoredCooldown(MATCH_COUNT_KEY, { timestamp: now, count: 1 });
    } else {
      setStoredCooldown(MATCH_COUNT_KEY, {
        timestamp: matchCount.timestamp,
        count: (matchCount.count || 0) + 1
      });
    }
  };

  const handleVote = async (type: 'likes' | 'stars' | 'trash' | 'matches') => {
    if (!user) {
      toast.error(t('signInToVote'));
      return;
    }

    if (loading) return;

    // Check cooldowns and limits
    if (type === 'trash' && !checkTrashCooldown()) return;
    if (type === 'matches' && !checkMatchLimits()) return;

    try {
      setLoading(true);
      const postRef = doc(db, 'posts', postId);
      const hasVoted = votes[type].includes(user.uid);

      // Remove vote from all categories first
      const updates: any = {};
      ['likes', 'stars', 'trash', 'matches'].forEach(voteType => {
        if (votes[voteType].includes(user.uid)) {
          updates[voteType] = arrayRemove(user.uid);
        }
      });

      // Add new vote if not removing
      if (!hasVoted) {
        updates[type] = arrayUnion(user.uid);

        // Update cooldowns and limits
        if (type === 'trash') {
          setStoredCooldown(TRASH_COOLDOWN_KEY, { timestamp: Date.now() });
        } else if (type === 'matches') {
          setStoredCooldown(MATCH_COOLDOWN_KEY, { timestamp: Date.now() });
          updateMatchCount();
        }
      }

      await updateDoc(postRef, updates);

      // Create notification for new vote
      if (!hasVoted && user.uid !== authorId) {
        const notificationType = `post_${type.slice(0, -1)}`; // Convert 'likes' to 'post_like', etc.
        
        await addDoc(collection(db, 'notifications'), {
          type: notificationType,
          recipientId: authorId,
          senderId: user.uid,
          senderName: user.displayName || '',
          senderUsername: user.displayName?.replace('@', '') || '',
          senderPhotoURL: user.photoURL,
          postId,
          timestamp: serverTimestamp(),
          read: false
        });

        // Show custom toast based on vote type
        const toastConfig = translations[language as keyof typeof translations].success[type];
        
        toast.success(toastConfig.message, {
          icon: toastConfig.icon,
          style: {
            background: '#000000',
            color: '#ffffff',
            fontWeight: 'bold'
          }
        });
      } else {
        toast.success(t('voteRemoved'), {
          style: {
            background: '#000000',
            color: '#ffffff'
          }
        });
      }

      // Update local state
      setVotes(prev => {
        const newVotes = {
          likes: prev.likes.filter(id => id !== user.uid),
          stars: prev.stars.filter(id => id !== user.uid),
          trash: prev.trash.filter(id => id !== user.uid),
          matches: prev.matches.filter(id => id !== user.uid)
        };
        if (!hasVoted) {
          newVotes[type] = [...newVotes[type], user.uid];
        }
        return newVotes;
      });
    } catch (error) {
      console.error('Error voting:', error);
      toast.error(t('errors.voteFailed'), {
        style: {
          background: '#000000',
          color: '#ffffff'
        }
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <button
        onClick={() => handleVote('likes')}
        disabled={loading}
        className={`flex items-center space-x-1 ${
          votes.likes.includes(user?.uid || '')
            ? 'text-red-500'
            : 'text-gray-500 hover:text-red-500'
        } transition-colors disabled:opacity-50`}
        title="Like"
      >
        <Heart className={`h-5 w-5 ${
          votes.likes.includes(user?.uid || '') ? 'fill-current' : ''
        }`} />
        <span className="text-sm">{votes.likes.length}</span>
      </button>

      <button
        onClick={() => handleVote('stars')}
        disabled={loading}
        className={`flex items-center space-x-1 ${
          votes.stars.includes(user?.uid || '')
            ? 'text-yellow-500'
            : 'text-gray-500 hover:text-yellow-500'
        } transition-colors disabled:opacity-50`}
        title="Star"
      >
        <Star className={`h-5 w-5 ${
          votes.stars.includes(user?.uid || '') ? 'fill-current' : ''
        }`} />
        <span className="text-sm">{votes.stars.length}</span>
      </button>

      <button
        onClick={() => handleVote('trash')}
        disabled={loading}
        className={`flex items-center space-x-1 ${
          votes.trash.includes(user?.uid || '')
            ? 'text-red-500'
            : 'text-gray-500 hover:text-red-500'
        } transition-colors disabled:opacity-50`}
        title="Mark as trash"
      >
        <Trash2 className="h-5 w-5" />
        <span className="text-sm">{votes.trash.length}</span>
      </button>

      <button
        onClick={() => handleVote('matches')}
        disabled={loading}
        className={`flex items-center space-x-1 ${
          votes.matches.includes(user?.uid || '')
            ? 'text-red-500'
            : 'text-gray-500 hover:text-red-500'
        } transition-colors disabled:opacity-50`}
        title="Match"
      >
        <Flame className="h-5 w-5" />
        <span className="text-sm">{votes.matches.length}</span>
      </button>

      {loading && (
        <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
      )}
    </div>
  );
}