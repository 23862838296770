import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence, initializeFirestore, CACHE_SIZE_UNLIMITED } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAn1KqYaIRWlYOwcoq8wfVv7mlRlG7ItjY",
  authDomain: "poosting-a6ff0.firebaseapp.com",
  projectId: "poosting-a6ff0",
  storageBucket: "poosting-a6ff0.firebasestorage.app",
  messagingSenderId: "263557657210",
  appId: "1:263557657210:web:061397adac2f35fdf1c1c3",
  measurementId: "G-HENWLZ1J54"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Initialize Firestore with settings for better performance
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});

// Enable offline persistence
enableIndexedDbPersistence(db).catch((err) => {
  console.error('Failed to enable offline persistence:', err);
});

export { db };
export const storage = getStorage(app);

let analytics: any = null;

if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}

export const logAnalyticsEvent = (eventName: string, eventParams?: object) => {
  if (analytics) {
    try {
      logEvent(analytics, eventName, eventParams);
    } catch (error) {
      console.error('Error logging analytics event:', error);
    }
  }
};

export const analyticsEvents = {
  login: () => logAnalyticsEvent('login'),
  signup: () => logAnalyticsEvent('sign_up'),
  createPost: () => logAnalyticsEvent('create_post'),
  likePost: () => logAnalyticsEvent('like_post'),
  commentPost: () => logAnalyticsEvent('comment_post'),
  viewProfile: (username: string) => logAnalyticsEvent('view_profile', { username }),
  updateProfile: () => logAnalyticsEvent('update_profile'),
  searchUser: () => logAnalyticsEvent('search_user'),
  followUser: () => logAnalyticsEvent('follow_user'),
  unfollowUser: () => logAnalyticsEvent('unfollow_user'),
  createStory: () => logAnalyticsEvent('create_story'),
  viewStory: () => logAnalyticsEvent('view_story'),
  aiChat: () => logAnalyticsEvent('ai_chat'),
  aiMessage: () => logAnalyticsEvent('ai_message')
};