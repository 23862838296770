import { useState, useEffect } from 'react';
import { X, Search, Loader2, Users } from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import toast from 'react-hot-toast';

interface ChatInviteProps {
  onClose: () => void;
  onInviteAccepted: (userId: string) => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Iniciar',
    searchPlaceholder: 'Buscar usuários...',
    loading: 'Carregando...',
    noResults: 'Nenhum usuário encontrado',
    searchUsers: 'Buscar Usuários',
    searchInstructions: 'Busque por usuários para iniciar uma conversa',
    errors: {
      searchFailed: 'Falha ao buscar usuários'
    }
  },
  'en': {
    title: 'New Chat',
    searchPlaceholder: 'Search users...',
    loading: 'Loading...',
    noResults: 'No users found',
    searchUsers: 'Search Users',
    searchInstructions: 'Search for users to start a chat',
    errors: {
      searchFailed: 'Failed to search users'
    }
  }
};

export function ChatInvite({ onClose, onInviteAccepted, isDarkMode = false, language = 'en' }: ChatInviteProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { user: currentUser } = useAuth();

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const handleSearch = async () => {
    if (!searchQuery.trim() || !currentUser) return;

    try {
      setLoading(true);
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('username', '>=', searchQuery.toLowerCase()),
        where('username', '<=', searchQuery.toLowerCase() + '\uf8ff')
      );

      const snapshot = await getDocs(q);
      const userData = snapshot.docs
        .map(doc => ({
          uid: doc.id,
          ...doc.data()
        }))
        .filter(user => user.uid !== currentUser.uid) as any[];

      setUsers(userData);
    } catch (error) {
      console.error('Error searching users:', error);
      toast.error(t('errors.searchFailed'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery) {
      const timer = setTimeout(handleSearch, 500);
      return () => clearTimeout(timer);
    } else {
      setUsers([]);
    }
  }, [searchQuery]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-md rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className={`flex justify-between items-center p-4 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('title')}
          </h2>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>

        <div className="p-4">
          <div className="relative mb-4">
            <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('searchPlaceholder')}
              className={`w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
              autoFocus
            />
          </div>

          {loading ? (
            <div className="flex justify-center py-8">
              <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            </div>
          ) : users.length > 0 ? (
            <div className="max-h-96 overflow-y-auto">
              {users.map((user) => (
                <button
                  key={user.uid}
                  onClick={() => {
                    onInviteAccepted(user.uid);
                    onClose();
                  }}
                  className={`w-full flex items-center space-x-3 p-3 ${
                    isDarkMode 
                      ? 'hover:bg-gray-700' 
                      : 'hover:bg-gray-50'
                  } rounded-lg transition-colors`}
                >
                  <img
                    src={user.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.uid}`}
                    alt={user.name}
                    className="h-10 w-10 rounded-full"
                  />
                  <div className="text-left">
                    <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {user.name}
                    </p>
                    <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      @{user.username}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          ) : searchQuery ? (
            <p className={`text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} py-8`}>
              {t('noResults')}
            </p>
          ) : (
            <div className="text-center py-8">
              <Users className={`h-12 w-12 mx-auto ${isDarkMode ? 'text-gray-600' : 'text-gray-400'} mb-4`} />
              <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-900'}`}>
                {t('searchUsers')}
              </p>
              <p className={`text-sm mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('searchInstructions')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}