import { useState, useEffect } from 'react';
import { X, Plus, Minus, Loader2 } from 'lucide-react';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Criar Enquete',
    question: {
      label: 'Pergunta',
      placeholder: 'Faça uma pergunta...'
    },
    options: {
      label: 'Opções',
      placeholder: (index: number) => `Opção ${index + 1}`,
      addOption: 'Adicionar Opção',
      maxOptions: 'Máximo 4 opções permitidas',
      minOptions: 'Mínimo 2 opções necessárias'
    },
    duration: 'Enquete ficará ativa por 24 horas',
    buttons: {
      cancel: 'Cancelar',
      create: 'Criar Enquete',
      creating: 'Criando...'
    }
  },
  'en': {
    title: 'Create Poll',
    question: {
      label: 'Question',
      placeholder: 'Ask a question...'
    },
    options: {
      label: 'Options',
      placeholder: (index: number) => `Option ${index + 1}`,
      addOption: 'Add Option',
      maxOptions: 'Maximum 4 options allowed',
      minOptions: 'Minimum 2 options required'
    },
    duration: 'Poll will be active for 24 hours',
    buttons: {
      cancel: 'Cancel',
      create: 'Create Poll',
      creating: 'Creating...'
    }
  }
};

interface PollOption {
  text: string;
  votes: string[];
}

interface Poll {
  question: string;
  options: PollOption[];
  totalVotes: number;
  endTime: Date;
}

interface CreatePollProps {
  onPollCreated: (poll: Poll) => void;
  onClose: () => void;
  isDarkMode?: boolean;
}

export function CreatePoll({ onPollCreated, onClose, isDarkMode = false }: CreatePollProps) {
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState<string[]>(['', '']);
  const [submitting, setSubmitting] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    // Detect user's language
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    // Handle function translations (for option placeholders)
    if (typeof value === 'function') {
      return value;
    }
    
    return value as string;
  };

  const addOption = () => {
    if (options.length < 4) {
      setOptions([...options, '']);
    } else {
      toast.error(t('options.maxOptions'));
    }
  };

  const removeOption = (index: number) => {
    if (options.length > 2) {
      setOptions(options.filter((_, i) => i !== index));
    } else {
      toast.error(t('options.minOptions'));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!question.trim() || !options.filter(opt => opt.trim()).length || submitting) return;

    // Validate options
    const validOptions = options.filter(opt => opt.trim());
    if (validOptions.length < 2) {
      toast.error(t('options.minOptions'));
      return;
    }

    if (validOptions.length !== new Set(validOptions).size) {
      toast.error('Options must be unique');
      return;
    }

    // Create poll with 24-hour duration
    const poll: Poll = {
      question: question.trim(),
      options: validOptions.map(text => ({ text, votes: [] })),
      totalVotes: 0,
      endTime: new Date(Date.now() + 24 * 60 * 60 * 1000) // 24 hours from now
    };

    onPollCreated(poll);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-md rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className={`p-4 border-b flex justify-between items-center ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('title')}
          </h2>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label className={`block text-sm font-medium mb-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              {t('question.label')}
            </label>
            <input
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder={t('question.placeholder')}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
              maxLength={100}
            />
          </div>

          <div className="space-y-3">
            <label className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              {t('options.label')}
            </label>
            {options.map((option, index) => (
              <div key={index} className="flex items-center space-x-2">
                <input
                  type="text"
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...options];
                    newOptions[index] = e.target.value;
                    setOptions(newOptions);
                  }}
                  placeholder={t('options.placeholder')(index)}
                  className={`flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                  }`}
                  maxLength={50}
                />
                {options.length > 2 && (
                  <button
                    type="button"
                    onClick={() => removeOption(index)}
                    className={`p-2 ${
                      isDarkMode 
                        ? 'text-red-400 hover:bg-red-900/30' 
                        : 'text-red-500 hover:bg-red-50'
                    } rounded-full`}
                  >
                    <Minus className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
            {options.length < 4 && (
              <button
                type="button"
                onClick={addOption}
                className={`flex items-center space-x-2 ${
                  isDarkMode 
                    ? 'text-blue-400 hover:text-blue-300' 
                    : 'text-blue-500 hover:text-blue-600'
                }`}
              >
                <Plus className="h-5 w-5" />
                <span>{t('options.addOption')}</span>
              </button>
            )}
          </div>

          <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            {t('duration')}
          </p>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className={`px-4 py-2 ${
                isDarkMode 
                  ? 'text-gray-300 hover:bg-gray-700' 
                  : 'text-gray-700 hover:bg-gray-100'
              } rounded-lg`}
            >
              {t('buttons.cancel')}
            </button>
            <button
              type="submit"
              disabled={submitting}
              className={`px-4 py-2 text-white rounded-lg disabled:opacity-50 ${
                isDarkMode 
                  ? 'bg-blue-600 hover:bg-blue-700' 
                  : 'bg-blue-500 hover:bg-blue-600'
              }`}
            >
              {submitting ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                t('buttons.create')
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}