import { useState, useEffect, useRef } from 'react';
import { collection, query, where, orderBy, getDocs, addDoc, serverTimestamp, limit, startAfter } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { MessageSquarePlus, Loader2, Search, ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { CreateCommunity } from '../components/CreateCommunity';
import { MainMenu } from '../components/MainMenu';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Comunidades',
    searchPlaceholder: 'Buscar comunidades...',
    createCommunity: 'Criar comunidade',
    alphabet: {
      title: 'Selecione uma letra',
      noCommunitiesLetter: 'Nenhuma comunidade começando com',
      selectLetter: 'Selecione uma letra para ver as comunidades'
    },
    community: {
      member: 'membro',
      members: 'membros'
    },
    loading: 'Carregando...',
    showMore: 'Mostrar Mais',
    noCommunitiesFound: 'Nenhuma comunidade encontrada',
    errors: {
      loadFailed: 'Falha ao carregar comunidades',
      createFailed: 'Falha ao criar comunidade'
    },
    success: {
      created: 'Comunidade criada com sucesso!'
    }
  },
  'en': {
    title: 'Communities',
    searchPlaceholder: 'Search communities...',
    createCommunity: 'Create community',
    alphabet: {
      title: 'Select a letter',
      noCommunitiesLetter: 'No communities starting with',
      selectLetter: 'Select a letter to view communities'
    },
    community: {
      member: 'member',
      members: 'members'
    },
    loading: 'Loading...',
    showMore: 'Show More',
    noCommunitiesFound: 'No communities found',
    errors: {
      loadFailed: 'Failed to load communities',
      createFailed: 'Failed to create community'
    },
    success: {
      created: 'Community created successfully!'
    }
  }
};

interface Community {
  id: string;
  title: string;
  question: string;
  imageUrl?: string;
  authorId: string;
  timestamp: Date;
  participants: string[];
  _key?: string;
}

interface GroupedCommunities {
  [key: string]: {
    items: Community[];
    lastDoc: any;
    hasMore: boolean;
    loading: boolean;
  };
}

const ALPHABET = '#ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const ITEMS_PER_PAGE = 10;

export function Communities() {
  const [communities, setCommunities] = useState<Community[]>([]);
  const [groupedCommunities, setGroupedCommunities] = useState<GroupedCommunities>({});
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const alphabetContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    loadInitialCommunities();
  }, []);

  const loadInitialCommunities = async () => {
    try {
      setLoading(true);
      const communitiesRef = collection(db, 'communities');
      const q = query(communitiesRef, orderBy('title', 'asc'));
      const snapshot = await getDocs(q);
      
      const communitiesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate(),
        participants: doc.data().participants || [],
        _key: `community-${doc.id}-${Date.now()}-${Math.random()}`
      })) as Community[];

      setCommunities(communitiesData);

      const grouped: GroupedCommunities = {};
      communitiesData.forEach(community => {
        const firstLetter = community.title.charAt(0).toUpperCase();
        const letter = /[A-Z]/.test(firstLetter) ? firstLetter : '#';
        
        if (!grouped[letter]) {
          grouped[letter] = {
            items: [],
            lastDoc: null,
            hasMore: false,
            loading: false
          };
        }
        
        if (grouped[letter].items.length < ITEMS_PER_PAGE) {
          grouped[letter].items.push(community);
        }
      });

      ALPHABET.forEach(letter => {
        if (grouped[letter]) {
          const totalForLetter = communitiesData.filter(c => {
            const communityLetter = /[A-Z]/.test(c.title.charAt(0).toUpperCase()) 
              ? c.title.charAt(0).toUpperCase() 
              : '#';
            return communityLetter === letter;
          }).length;
          
          grouped[letter].hasMore = totalForLetter > ITEMS_PER_PAGE;
        }
      });

      setGroupedCommunities(grouped);
      
      const firstAvailableLetter = ALPHABET.find(letter => grouped[letter]?.items.length > 0) || null;
      setSelectedLetter(firstAvailableLetter);
    } catch (error) {
      console.error('Error fetching communities:', error);
      toast.error(t('errors.loadFailed'));
    } finally {
      setLoading(false);
    }
  };

  const loadMoreForLetter = async (letter: string) => {
    if (!groupedCommunities[letter] || !groupedCommunities[letter].hasMore || groupedCommunities[letter].loading) {
      return;
    }

    try {
      setGroupedCommunities(prev => ({
        ...prev,
        [letter]: { ...prev[letter], loading: true }
      }));

      const communitiesRef = collection(db, 'communities');
      const lastDoc = groupedCommunities[letter].lastDoc;
      
      const q = query(
        communitiesRef,
        where('title', '>=', letter),
        where('title', '<', String.fromCharCode(letter.charCodeAt(0) + 1)),
        orderBy('title', 'asc'),
        startAfter(lastDoc),
        limit(ITEMS_PER_PAGE)
      );

      const snapshot = await getDocs(q);
      const newCommunities = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate(),
        participants: doc.data().participants || [],
        _key: `community-${doc.id}-${Date.now()}-${Math.random()}`
      })) as Community[];

      setGroupedCommunities(prev => ({
        ...prev,
        [letter]: {
          items: [...prev[letter].items, ...newCommunities],
          lastDoc: snapshot.docs[snapshot.docs.length - 1],
          hasMore: snapshot.docs.length === ITEMS_PER_PAGE,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error loading more communities:', error);
      toast.error(t('errors.loadFailed'));
      setGroupedCommunities(prev => ({
        ...prev,
        [letter]: { ...prev[letter], loading: false }
      }));
    }
  };

  const handleCreateCommunity = async (title: string, question: string, imageFile: File | null) => {
    if (!user) return;

    try {
      let imageUrl = '';
      
      if (imageFile) {
        const storageRef = ref(storage, `communities/${Date.now()}-${imageFile.name}`);
        await uploadBytes(storageRef, imageFile);
        imageUrl = await getDownloadURL(storageRef);
      }

      const newCommunity = {
        title: title.trim(),
        question: question.trim(),
        imageUrl,
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', '') || '',
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        lastResponseAt: serverTimestamp(),
        participants: [user.uid]
      };

      const docRef = await addDoc(collection(db, 'communities'), newCommunity);
      
      const updatedCommunity = {
        ...newCommunity,
        id: docRef.id,
        timestamp: new Date(),
        lastResponseAt: new Date(),
        participants: [user.uid]
      } as Community;

      setCommunities(prev => [...prev, updatedCommunity]);
      
      const firstLetter = title.charAt(0).toUpperCase();
      const letter = /[A-Z]/.test(firstLetter) ? firstLetter : '#';
      
      setGroupedCommunities(prev => {
        const updatedGroup = prev[letter] || {
          items: [],
          lastDoc: null,
          hasMore: false,
          loading: false
        };
        
        return {
          ...prev,
          [letter]: {
            ...updatedGroup,
            items: [...updatedGroup.items, updatedCommunity].sort((a, b) => 
              a.title.localeCompare(b.title)
            ).slice(0, ITEMS_PER_PAGE),
            hasMore: updatedGroup.items.length >= ITEMS_PER_PAGE
          }
        };
      });

      setShowCreateModal(false);
      toast.success(t('success.created'));
    } catch (error) {
      console.error('Error creating community:', error);
      toast.error(t('errors.createFailed'));
    }
  };

  const checkScrollButtons = () => {
    if (!alphabetContainerRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = alphabetContainerRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener('resize', checkScrollButtons);
    return () => window.removeEventListener('resize', checkScrollButtons);
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    if (!alphabetContainerRef.current) return;

    const scrollAmount = 200;
    const container = alphabetContainerRef.current;
    const newScrollLeft = direction === 'left' 
      ? container.scrollLeft - scrollAmount 
      : container.scrollLeft + scrollAmount;

    container.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    checkScrollButtons();
  };

  // Filter communities based on search query
  const filteredCommunities = searchQuery
    ? communities.filter(community => 
        community.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
            {t('title')}
          </h1>
          <button
            onClick={() => setShowCreateModal(true)}
            className={`p-2 rounded-full ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-black text-white hover:bg-gray-800'
            }`}
            title={t('createCommunity')}
          >
            <MessageSquarePlus className="h-5 w-5" />
          </button>
        </div>
        <MainMenu />

        <div className="max-w-2xl mx-auto px-4 py-3">
          {/* Search Input */}
          <div className="relative mb-4">
            <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('searchPlaceholder')}
              className={`w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
            />
          </div>

          {/* Alphabet filter - only show if not searching */}
          {!searchQuery && (
            <div className="relative">
              {/* Left Arrow */}
              {showLeftArrow && (
                <button
                  onClick={() => scroll('left')}
                  className={`absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-1 ${
                    isDarkMode 
                      ? 'bg-gray-700 text-white hover:bg-gray-600' 
                      : 'bg-white text-gray-600 hover:bg-gray-100'
                  } rounded-full shadow-lg transition-colors`}
                >
                  <ChevronLeft className="h-5 w-5" />
                </button>
              )}

              {/* Scrollable Container */}
              <div 
                ref={alphabetContainerRef}
                className="flex space-x-1 overflow-x-auto scrollbar-hide scroll-smooth px-6"
                onScroll={handleScroll}
              >
                {ALPHABET.map(letter => {
                  const hasItems = groupedCommunities[letter]?.items.length > 0;
                  return (
                    <button
                      key={letter}
                      onClick={() => hasItems && setSelectedLetter(letter)}
                      disabled={!hasItems}
                      className={`min-w-[32px] h-8 flex items-center justify-center rounded-md text-sm font-medium transition-colors ${
                        selectedLetter === letter
                          ? isDarkMode
                            ? 'bg-blue-600 text-white'
                            : 'bg-black text-white'
                          : hasItems
                          ? isDarkMode
                            ? 'text-gray-300 hover:bg-gray-700'
                            : 'text-gray-900 hover:bg-gray-100'
                          : isDarkMode
                          ? 'text-gray-600 cursor-not-allowed'
                          : 'text-gray-300 cursor-not-allowed'
                      }`}
                    >
                      {letter}
                    </button>
                  );
                })}
              </div>

              {/* Right Arrow */}
              {showRightArrow && (
                <button
                  onClick={() => scroll('right')}
                  className={`absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-1 ${
                    isDarkMode 
                      ? 'bg-gray-700 text-white hover:bg-gray-600' 
                      : 'bg-white text-gray-600 hover:bg-gray-100'
                  } rounded-full shadow-lg transition-colors`}
                >
                  <ChevronRight className="h-5 w-5" />
                </button>
              )}
            </div>
          )}
        </div>
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          </div>
        ) : searchQuery ? (
          // Show search results
          <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
            {filteredCommunities.length > 0 ? (
              filteredCommunities.map((community) => (
                <Link
                  key={community._key || community.id}
                  to={`/communities/${community.id}`}
                  className={`block p-4 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition-colors`}
                >
                  <div className="flex items-start space-x-4">
                    {community.imageUrl ? (
                      <img
                        src={community.imageUrl}
                        alt={community.title}
                        className="w-20 h-20 rounded-lg object-cover bg-gray-100"
                        loading="lazy"
                      />
                    ) : (
                      <div className={`w-20 h-20 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} rounded-lg flex items-center justify-center`}>
                        <MessageSquarePlus className={`h-8 w-8 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`} />
                      </div>
                    )}
                    <div className="flex-1 min-w-0">
                      <h2 className={`text-lg font-semibold mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        {community.title}
                      </h2>
                      <p className={`line-clamp-2 mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                        {community.question}
                      </p>
                      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {community.participants.length}{' '}
                        {community.participants.length === 1 ? t('community.member') : t('community.members')}
                      </p>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="p-8 text-center">
                <p className={`text-lg ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {t('noCommunitiesFound')}
                </p>
              </div>
            )}
          </div>
        ) : selectedLetter && groupedCommunities[selectedLetter]?.items.length > 0 ? (
          // Show communities for selected letter
          <>
            <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
              {groupedCommunities[selectedLetter].items.map((community) => (
                <Link
                  key={community._key || community.id}
                  to={`/communities/${community.id}`}
                  className={`block p-4 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition-colors`}
                >
                  <div className="flex items-start space-x-4">
                    {community.imageUrl ? (
                      <img
                        src={community.imageUrl}
                        alt={community.title}
                        className="w-20 h-20 rounded-lg object-cover bg-gray-100"
                        loading="lazy"
                      />
                    ) : (
                      <div className={`w-20 h-20 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} rounded-lg flex items-center justify-center`}>
                        <MessageSquarePlus className={`h-8 w-8 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`} />
                      </div>
                    )}
                    <div className="flex-1 min-w-0">
                      <h2 className={`text-lg font-semibold mb-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        {community.title}
                      </h2>
                      <p className={`line-clamp-2 mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                        {community.question}
                      </p>
                      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        {community.participants.length}{' '}
                        {community.participants.length === 1 ? t('community.member') : t('community.members')}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>

            {groupedCommunities[selectedLetter].hasMore && (
              <div className="p-4 flex justify-center">
                <button
                  onClick={() => loadMoreForLetter(selectedLetter)}
                  disabled={groupedCommunities[selectedLetter].loading}
                  className={`px-6 py-2 rounded-full disabled:opacity-50 flex items-center space-x-2 ${
                    isDarkMode 
                      ? 'bg-gray-700 text-white hover:bg-gray-600' 
                      : 'bg-black text-white hover:bg-gray-800'
                  }`}
                >
                  {groupedCommunities[selectedLetter].loading ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <span>{t('loading')}</span>
                    </>
                  ) : (
                    <span>{t('showMore')}</span>
                  )}
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="p-8 text-center">
            <p className={`text-lg mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {selectedLetter 
                ? `${t('alphabet.noCommunitiesLetter')} '${selectedLetter}'` 
                : t('alphabet.selectLetter')}
            </p>
          </div>
        )}
      </main>

      {showCreateModal && (
        <CreateCommunity
          onClose={() => setShowCreateModal(false)}
          onCreate={handleCreateCommunity}
          isDarkMode={isDarkMode}
          language={language}
        />
      )}
    </div>
  );
}