import { useState, useEffect, useRef } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import { X, ChevronLeft, ChevronRight, Loader2, Trash2, Heart } from 'lucide-react';
import { doc, deleteDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove, addDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { deleteAnyStory } from '../utils/admin/stories';
import { StoryMenu } from './StoryMenu';
import toast from 'react-hot-toast';

interface Story {
  id: string;
  mediaUrl: string;
  mediaType: 'image' | 'video' | 'spotify';
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  likes: string[];
  spotifyTrack?: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  };
}

interface StoryViewerProps {
  stories: Story[];
  initialIndex: number;
  onClose: () => void;
  onDelete?: (storyId: string) => void;
  isDarkMode?: boolean;
}

export function StoryViewer({ stories, initialIndex, onClose, onDelete, isDarkMode = false }: StoryViewerProps) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isPaused, setIsPaused] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLikeAnimating, setIsLikeAnimating] = useState(false);
  const [currentLikes, setCurrentLikes] = useState<string[]>([]);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [optimizedImageUrl, setOptimizedImageUrl] = useState<string | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { user } = useAuth();
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const likeButtonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const loadingTimeoutRef = useRef<number>();

  const currentStory = stories[currentIndex];
  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  useEffect(() => {
    if (!currentStory || !user) return;

    // Subscribe to real-time likes updates
    const storyRef = doc(db, 'stories', currentStory.id);
    const unsubscribe = onSnapshot(storyRef, (doc) => {
      if (doc.exists()) {
        const likes = doc.data().likes || [];
        setCurrentLikes(likes);
      }
    });

    // Auto-play video if current story is video type
    if (currentStory.mediaType === 'video' && videoRef.current) {
      setIsVideoLoading(true);
      videoRef.current.load();
    }

    // Optimize image if it's an image story
    if (currentStory.mediaType === 'image') {
      optimizeImage(currentStory.mediaUrl);
    }

    return () => unsubscribe();
  }, [currentStory, user]);

  useEffect(() => {
    // Calculate and set the container size to maintain 1080x1920 aspect ratio
    const updateContainerSize = () => {
      if (!containerRef.current) return;
      
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const targetAspectRatio = 1080 / 1920;
      
      let width, height;
      
      if (windowWidth / windowHeight > targetAspectRatio) {
        height = windowHeight;
        width = height * targetAspectRatio;
      } else {
        width = windowWidth;
        height = width / targetAspectRatio;
      }
      
      containerRef.current.style.width = `${width}px`;
      containerRef.current.style.height = `${height}px`;
    };

    updateContainerSize();
    window.addEventListener('resize', updateContainerSize);
    return () => window.removeEventListener('resize', updateContainerSize);
  }, []);

  const optimizeImage = async (url: string) => {
    try {
      let optimizedUrl = url;

      // Get viewport dimensions
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Calculate optimal dimensions while maintaining aspect ratio
      const targetWidth = Math.min(1080, viewportWidth * window.devicePixelRatio);
      const targetHeight = Math.min(1920, viewportHeight * window.devicePixelRatio);

      // Cloudinary optimization
      if (url.includes('cloudinary.com')) {
        optimizedUrl = url.replace('/upload/', `/upload/w_${targetWidth},h_${targetHeight},c_fill,f_auto,q_auto/`);
      }
      // Imgix optimization
      else if (url.includes('imgix.net')) {
        optimizedUrl = `${url}?w=${targetWidth}&h=${targetHeight}&fit=crop&auto=format,compress`;
      }
      // Firebase Storage optimization
      else if (url.includes('firebasestorage.googleapis.com')) {
        optimizedUrl = `${url}_${targetWidth}x${targetHeight}`;
      }
      // Unsplash optimization
      else if (url.includes('unsplash.com')) {
        optimizedUrl = `${url}&w=${targetWidth}&h=${targetHeight}&fit=crop&q=80&auto=format`;
      }

      setOptimizedImageUrl(optimizedUrl);

      // Preload the next image
      if (currentIndex < stories.length - 1) {
        const nextStory = stories[currentIndex + 1];
        if (nextStory.mediaType === 'image') {
          const img = new Image();
          img.src = optimizeImageUrl(nextStory.mediaUrl);
        }
      }
    } catch (error) {
      console.error('Error optimizing image:', error);
      setOptimizedImageUrl(url); // Fallback to original URL
    }
  };

  const optimizeImageUrl = (url: string): string => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const targetWidth = Math.min(1080, viewportWidth * window.devicePixelRatio);
    const targetHeight = Math.min(1920, viewportHeight * window.devicePixelRatio);

    if (url.includes('cloudinary.com')) {
      return url.replace('/upload/', `/upload/w_${targetWidth},h_${targetHeight},c_fill,f_auto,q_auto/`);
    } else if (url.includes('imgix.net')) {
      return `${url}?w=${targetWidth}&h=${targetHeight}&fit=crop&auto=format,compress`;
    } else if (url.includes('firebasestorage.googleapis.com')) {
      return `${url}_${targetWidth}x${targetHeight}`;
    } else if (url.includes('unsplash.com')) {
      return `${url}&w=${targetWidth}&h=${targetHeight}&fit=crop&q=80&auto=format`;
    }
    return url;
  };

  const handleImageLoad = () => {
    clearTimeout(loadingTimeoutRef.current);
    setImageLoaded(true);
  };

  const handleImageError = () => {
    clearTimeout(loadingTimeoutRef.current);
    setImageLoaded(true); // Still set to true to remove loading state
    toast.error('Failed to load image');
  };

  const handleLike = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!user || !currentStory || !likeButtonRef.current) return;

    try {
      const storyRef = doc(db, 'stories', currentStory.id);
      const isLiked = currentLikes.includes(user.uid);

      setCurrentLikes(prev => 
        isLiked 
          ? prev.filter(id => id !== user.uid)
          : [...prev, user.uid]
      );

      if (!isLiked) {
        setIsLikeAnimating(true);
        setTimeout(() => setIsLikeAnimating(false), 1000);
      }

      await updateDoc(storyRef, {
        likes: isLiked ? arrayRemove(user.uid) : arrayUnion(user.uid)
      });

      if (!isLiked && user.uid !== currentStory.authorId) {
        await addDoc(collection(db, 'notifications'), {
          type: 'story_like',
          storyId: currentStory.id,
          recipientId: currentStory.authorId,
          senderId: user.uid,
          senderName: user.displayName || '',
          senderUsername: user.displayName?.replace('@', '') || '',
          senderPhotoURL: user.photoURL,
          message: 'liked your story',
          timestamp: serverTimestamp(),
          read: false
        });
      }

      if (!isLiked) {
        likeButtonRef.current.classList.add('animate-like');
        setTimeout(() => {
          if (likeButtonRef.current) {
            likeButtonRef.current.classList.remove('animate-like');
          }
        }, 1000);
      }
    } catch (error) {
      console.error('Error liking story:', error);
      toast.error('Failed to like story');
      setCurrentLikes(currentStory.likes || []);
    }
  };

  const handleDelete = async () => {
    if (!user || !currentStory || isDeleting) return;

    const isOwner = user.uid === currentStory.authorId;
    if (!isOwner && !isAdmin) return;

    const confirmDelete = window.confirm('Are you sure you want to delete this story? This action cannot be undone.');
    if (!confirmDelete) return;

    setIsDeleting(true);

    try {
      if (isAdmin && !isOwner) {
        await deleteAnyStory(currentStory.id, user.email);
      } else {
        if (currentStory.mediaType !== 'spotify') {
          try {
            const filename = currentStory.mediaUrl.split('/').pop()?.split('?')[0];
            if (filename) {
              const storageRef = ref(storage, `stories/${filename}`);
              await deleteObject(storageRef);
            }
          } catch (error: any) {
            if (error.code !== 'storage/object-not-found') {
              console.error('Error deleting story media:', error);
            }
          }
        }

        await deleteDoc(doc(db, 'stories', currentStory.id));
      }

      if (onDelete) {
        onDelete(currentStory.id);
      }

      handleNext();
      toast.success('Story deleted successfully');
    } catch (error) {
      console.error('Error deleting story:', error);
      toast.error('Failed to delete story');
    } finally {
      setIsDeleting(false);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
      setIsPaused(false);
      setIsVideoLoading(false);
    }
  };

  const handleNext = () => {
    if (currentIndex < stories.length - 1) {
      setCurrentIndex(prev => prev + 1);
      setIsPaused(false);
      setIsVideoLoading(false);
    } else {
      onClose();
    }
  };

  const togglePause = (e: React.MouseEvent) => {
    if (
      e.target instanceof HTMLButtonElement ||
      e.target instanceof HTMLAnchorElement ||
      (e.target as Element).closest('button') ||
      (e.target as Element).closest('a')
    ) {
      return;
    }

    setIsPaused(!isPaused);
    
    if (videoRef.current) {
      if (isPaused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }

    if (audioRef.current) {
      if (isPaused) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  };

  if (!currentStory) return null;

  const isLiked = currentLikes.includes(user?.uid || '');

  return (
    <div className="fixed inset-0 bg-black z-50 flex items-center justify-center">
      <div 
        ref={containerRef}
        className="relative bg-black"
        style={{
          maxWidth: '100vw',
          maxHeight: '100vh',
          margin: 'auto'
        }}
        onClick={togglePause}
      >
        <div className="absolute inset-0">
          {currentStory.mediaType === 'spotify' ? (
            <div className="absolute inset-0 flex items-center justify-center">
              <img
                src={currentStory.spotifyTrack?.albumImage}
                alt={currentStory.spotifyTrack?.name}
                className="w-full h-full object-contain"
                loading="eager"
              />
              {currentStory.spotifyTrack?.previewUrl && (
                <audio
                  ref={audioRef}
                  src={currentStory.spotifyTrack.previewUrl}
                  autoPlay
                  className="hidden"
                />
              )}
            </div>
          ) : currentStory.mediaType === 'video' ? (
            <div className="relative w-full h-full">
              {isVideoLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <Loader2 className="h-12 w-12 animate-spin text-white" />
                </div>
              )}
              <video
                ref={videoRef}
                src={currentStory.mediaUrl}
                className="w-full h-full object-contain"
                playsInline
                muted={false}
                autoPlay
                onLoadStart={() => setIsVideoLoading(true)}
                onCanPlay={() => {
                  setIsVideoLoading(false);
                  if (videoRef.current) {
                    videoRef.current.play().catch(error => {
                      console.error('Error auto-playing video:', error);
                    });
                  }
                }}
              />
            </div>
          ) : (
            <>
              {/* Loading skeleton */}
              {!imageLoaded && (
                <div className="absolute inset-0 bg-gray-900 animate-pulse" />
              )}
              
              {/* Optimized image */}
              <img
                ref={imageRef}
                src={optimizedImageUrl || currentStory.mediaUrl}
                alt="Story"
                className={`w-full h-full object-contain transition-opacity duration-300 ${
                  imageLoaded ? 'opacity-100' : 'opacity-0'
                }`}
                onLoad={handleImageLoad}
                onError={handleImageError}
                loading="eager"
              />
            </>
          )}

          {isLikeAnimating && (
            <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
              <Heart className="h-32 w-32 text-red-500 fill-current animate-like-pop" />
            </div>
          )}
        </div>

        <div className="absolute top-0 left-0 right-0 p-4 z-10">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <Link to={`/profile/${currentStory.authorUsername}`}>
                <img
                  src={currentStory.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${currentStory.authorId}`}
                  alt={currentStory.authorName}
                  className="w-10 h-10 rounded-full border-2 border-white"
                />
              </Link>
              <div className="text-white">
                <Link 
                  to={`/profile/${currentStory.authorUsername}`}
                  className="font-semibold hover:underline"
                >
                  {currentStory.authorName}
                </Link>
                <p className="text-sm opacity-75">
                  {formatDistanceToNow(currentStory.timestamp, { addSuffix: true })}
                </p>
              </div>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
              className="p-2 text-white hover:bg-white/10 rounded-full transition-colors"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
        </div>

        <button
          onClick={(e) => {
            e.stopPropagation();
            handlePrevious();
          }}
          className="absolute left-4 top-1/2 -translate-y-1/2 p-2 text-white bg-black/50 hover:bg-black/70 rounded-full transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={currentIndex === 0}
        >
          <ChevronLeft className="h-6 w-6" />
        </button>

        <button
          onClick={(e) => {
            e.stopPropagation();
            handleNext();
          }}
          className="absolute right-4 top-1/2 -translate-y-1/2 p-2 text-white bg-black/50 hover:bg-black/70 rounded-full transition-colors"
        >
          <ChevronRight className="h-6 w-6" />
        </button>

        <div className="absolute bottom-16 left-0 right-0 flex justify-center items-center">
          <div className="flex flex-col items-center">
            <button
              ref={likeButtonRef}
              onClick={handleLike}
              className="p-3 hover:bg-white/10 rounded-full transition-colors transform active:scale-125"
            >
              <Heart 
                className={`h-10 w-10 ${
                  isLiked ? 'text-red-500 fill-current' : 'text-white'
                } transition-colors`}
              />
              {currentLikes.length > 0 && (
                <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-sm font-bold">
                  {currentLikes.length}
                </span>
              )}
            </button>
          </div>
        </div>

        {(user?.uid === currentStory.authorId || isAdmin) && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
            disabled={isDeleting}
            className="absolute bottom-4 right-16 p-2 text-white hover:bg-white/10 rounded-full transition-colors disabled:opacity-50"
            title="Delete story"
          >
            {isDeleting ? (
              <Loader2 className="h-8 w-8 animate-spin" />
            ) : (
              <Trash2 className="h-8 w-8" />
            )}
          </button>
        )}

        <div className="absolute bottom-4 right-4">
          <StoryMenu
            storyId={currentStory.id}
            authorId={currentStory.authorId}
            onBlock={handleNext}
          />
        </div>

        <div className="absolute top-0 left-0 right-0 h-1 bg-gray-800">
          <div className="h-full bg-white" style={{ width: `${((currentIndex + 1) / stories.length) * 100}%` }} />
        </div>
      </div>
    </div>
  );
}