import { BarChart } from 'lucide-react';

interface PollOption {
  text: string;
  votes: string[];
}

interface Poll {
  question: string;
  options: PollOption[];
  totalVotes: number;
  endTime: Date;
}

interface PollViewProps {
  poll: Poll;
  onVote: (optionIndex: number) => Promise<void>;
  hasVoted: boolean;
  isExpired: boolean;
  isVoting: boolean;
  isDarkMode?: boolean;
}

export function PollView({ poll, onVote, hasVoted, isExpired, isVoting, isDarkMode = false }: PollViewProps) {
  const calculatePercentage = (votes: string[]) => {
    if (poll.totalVotes === 0) return 0;
    return Math.round((votes.length / poll.totalVotes) * 100);
  };

  return (
    <div className={`mt-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg p-4`}>
      <div className="flex items-center space-x-2 mb-4">
        <BarChart className="h-5 w-5 text-purple-500" />
        <h3 className={`font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {poll.question}
        </h3>
      </div>

      <div className="space-y-3">
        {poll.options.map((option, index) => {
          const percentage = calculatePercentage(option.votes);
          
          return (
            <button
              key={index}
              onClick={() => !hasVoted && !isExpired && !isVoting && onVote(index)}
              disabled={hasVoted || isExpired || isVoting}
              className={`w-full relative ${
                hasVoted || isExpired
                  ? 'cursor-default'
                  : isDarkMode 
                    ? 'hover:bg-gray-600' 
                    : 'hover:bg-purple-50'
              }`}
            >
              <div className={`relative z-10 p-3 rounded-lg border ${
                isDarkMode 
                  ? 'border-gray-600 bg-gray-800' 
                  : 'border-gray-200 bg-white'
              }`}>
                <div className="flex justify-between items-center">
                  <span className={`${
                    option.votes.length > 0 ? 'font-medium' : ''
                  } ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {option.text}
                  </span>
                  {(hasVoted || isExpired) && (
                    <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                      {percentage}% ({option.votes.length} votes)
                    </span>
                  )}
                </div>
                {(hasVoted || isExpired) && (
                  <div
                    className={`absolute left-0 top-0 h-full ${
                      isDarkMode ? 'bg-purple-900/30' : 'bg-purple-100'
                    } rounded-lg transition-all duration-500`}
                    style={{ width: `${percentage}%`, zIndex: -1 }}
                  />
                )}
              </div>
            </button>
          );
        })}
      </div>

      <div className={`mt-4 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        <span>
          {poll.totalVotes} {poll.totalVotes === 1 ? 'vote' : 'votes'}
        </span>
      </div>
    </div>
  );
}