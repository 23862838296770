import { useEffect } from 'react';
import { Newspaper } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { useLocalization } from '../contexts/LocalizationContext';
import { useTheme } from '../contexts/ThemeContext';

export function News() {
  const { country } = useLocalization();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    // Load RSS widget script
    const script = document.createElement('script');
    script.src = 'https://widget.rss.app/v1/feed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Newspaper className={`h-6 w-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              Poosting News
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <div className="p-4">
          <div className={`my-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow`}>
            {country === 'BR' ? (
              <rssapp-feed id="_nJOaj3RuVQPYeVEq"></rssapp-feed>
            ) : (
              <rssapp-feed id="_UsZ9vQNqfd1QeTqd"></rssapp-feed>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}