import { collection, query, where, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { FilterOption } from './types';
import type { PostData } from './types';

const POSTS_PER_PAGE = 10;

export async function fetchPosts(
  userId: string,
  filterOption: FilterOption,
  lastDoc?: any
): Promise<{ posts: PostData[]; lastDoc: any; hasMore: boolean }> {
  try {
    const postsRef = collection(db, 'posts');
    let q;

    if (filterOption === 'all') {
      q = lastDoc 
        ? query(
            postsRef,
            orderBy('timestamp', 'desc'),
            startAfter(lastDoc),
            limit(POSTS_PER_PAGE)
          )
        : query(
            postsRef,
            orderBy('timestamp', 'desc'),
            limit(POSTS_PER_PAGE)
          );
    } else {
      q = lastDoc
        ? query(
            postsRef,
            where('authorId', '==', userId),
            orderBy('timestamp', 'desc'),
            startAfter(lastDoc),
            limit(POSTS_PER_PAGE)
          )
        : query(
            postsRef,
            where('authorId', '==', userId),
            orderBy('timestamp', 'desc'),
            limit(POSTS_PER_PAGE)
          );
    }

    const snapshot = await getDocs(q);
    const posts = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      timestamp: doc.data().timestamp?.toDate() || new Date()
    })) as PostData[];

    return {
      posts,
      lastDoc: snapshot.docs[snapshot.docs.length - 1] || null,
      hasMore: posts.length === POSTS_PER_PAGE
    };
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
}

export type { PostData, FilterOption };