import { useEffect, useRef } from 'react';

export function useResizeObserver() {
  const ref = useRef<HTMLDivElement>(null);
  const observerRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    try {
      let frameId: number;
      observerRef.current = new ResizeObserver((entries) => {
        // Use requestAnimationFrame to batch notifications
        frameId = requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) return;
          
          // Handle resize if needed
          // Currently we just need the observer for dynamic sizing
        });
      });

      observerRef.current.observe(element);

      return () => {
        if (frameId) {
          cancelAnimationFrame(frameId);
        }
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    } catch (error) {
      console.error('ResizeObserver error:', error);
    }
  }, []);

  return { ref };
}