import { Trophy, Loader2 } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { RankingList } from '../components/ranking/RankingList';
import { RankingHeader } from '../components/ranking/RankingHeader';
import { useRankingData } from '../hooks/useRankingData';
import { useTheme } from '../contexts/ThemeContext';
import { useState, useEffect } from 'react';

const translations = {
  'pt-BR': {
    title: 'Ranking Poosting',
    loading: 'Carregando...',
    errors: {
      loadFailed: 'Falha ao carregar ranking'
    }
  },
  'en': {
    title: 'Poosting Ranking',
    loading: 'Loading...',
    errors: {
      loadFailed: 'Failed to load ranking'
    }
  }
};

export function Ranking() {
  const { rankings, loading, error } = useRankingData();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Trophy className="h-6 w-6 text-yellow-500" />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <RankingHeader isDarkMode={isDarkMode} language={language} />
        
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          </div>
        ) : error ? (
          <div className={`p-8 text-center ${isDarkMode ? 'text-red-400' : 'text-red-500'}`}>
            {error}
          </div>
        ) : (
          <RankingList rankings={rankings} isDarkMode={isDarkMode} language={language} />
        )}
      </main>
    </div>
  );
}