import { auth } from '../lib/firebase';
import { useTheme } from '../contexts/ThemeContext';
import { MainMenu } from '../components/MainMenu';
import { NotificationList } from '../components/NotificationList';
import { useState, useEffect } from 'react';

const translations = {
  'pt-BR': {
    title: 'Notificações',
    loading: 'Carregando...',
    noNotifications: 'Nenhuma notificação ainda',
    whenInteract: 'Quando alguém interagir com seu conteúdo, você verá aqui'
  },
  'en': {
    title: 'Notifications',
    loading: 'Loading...',
    noNotifications: 'No notifications yet',
    whenInteract: 'When someone interacts with your content, you\'ll see it here'
  }
};

export function Notifications() {
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
            {t('title')}
          </h1>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <NotificationList language={language} />
      </main>
    </div>
  );
}