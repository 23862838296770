import { PostVotes } from './PostVotes';
import { PostMenu } from './PostMenu';

interface PostFooterProps {
  id: string;
  authorId: string;
  likes: string[];
  stars: string[];
  trash: string[];
  matches: string[];
  onBlock: () => void;
}

export function PostFooter({ id, authorId, likes, stars, trash, matches, onBlock }: PostFooterProps) {
  return (
    <div className="flex items-center justify-between">
      <PostVotes
        postId={id}
        authorId={authorId}
        initialVotes={{ likes, stars, trash, matches }}
      />
      <PostMenu postId={id} authorId={authorId} onBlock={onBlock} />
    </div>
  );
}