export const translations = {
  'pt-BR': {
    auth: {
      signIn: 'Entrar',
      signUp: 'Criar Conta',
      email: 'E-mail',
      password: 'Senha',
      name: 'Nome',
      username: 'Nome de usuário',
      bio: 'Biografia',
      rememberMe: 'Lembrar-me',
      termsAccept: 'Aceito os termos de uso e política de cookies',
      ageVerification: 'Tenho mais de 18 anos',
      createAccount: 'Criar conta',
      alreadyHaveAccount: 'Já tem uma conta? Entre',
      dontHaveAccount: 'Não tem uma conta? Cadastre-se'
    },
    navigation: {
      feed: 'Feed',
      stories: 'Stories',
      search: 'Buscar',
      communities: 'Comunidades',
      music: 'Música',
      videos: 'Vídeos',
      places: 'Lugares',
      news: 'Notícias',
      profile: 'Perfil',
      notifications: 'Notificações',
      chat: 'Chat',
      ranking: 'Ranking',
      match: 'Match',
      radio: 'Rádio',
      web: 'Web'
    },
    profile: {
      editProfile: 'Editar perfil',
      followers: 'seguidores',
      following: 'seguindo',
      posts: 'publicações',
      follow: 'Seguir',
      unfollow: 'Deixar de seguir',
      block: 'Bloquear',
      report: 'Denunciar',
      bio: 'Biografia',
      noBio: 'Sem biografia',
      editBio: 'Editar biografia',
      saveBio: 'Salvar',
      bioUpdated: 'Biografia atualizada com sucesso',
      failedToUpdate: 'Falha ao atualizar biografia'
    },
    notifications: {
      newLike: 'curtiu sua publicação',
      newComment: 'comentou em sua publicação',
      newFollower: 'começou a seguir você',
      newMatch: 'deu match com você',
      noNotifications: 'Nenhuma notificação ainda',
      whenInteract: 'Quando alguém interagir com seu conteúdo, você verá aqui'
    },
    chat: {
      typeMessage: 'Digite uma mensagem...',
      sendPhoto: 'Enviar foto',
      shareLocation: 'Compartilhar localização',
      newChat: 'Nova conversa',
      noMessages: 'Nenhuma mensagem ainda',
      startChat: 'Comece uma conversa!',
      deleteChat: 'Excluir conversa',
      confirmDelete: 'Tem certeza que deseja excluir esta conversa?'
    },
    communities: {
      createCommunity: 'Criar comunidade',
      joinCommunity: 'Participar',
      leaveCommunity: 'Sair',
      members: 'membros',
      createTopic: 'Criar tópico',
      topics: 'tópicos',
      noTopics: 'Nenhum tópico ainda',
      beFirst: 'Seja o primeiro a criar um tópico!'
    },
    places: {
      searchPlaces: 'Buscar lugares próximos...',
      getDirections: 'Como chegar',
      openNow: 'Aberto agora',
      closed: 'Fechado',
      reviews: 'avaliações'
    },
    common: {
      loading: 'Carregando...',
      error: 'Ocorreu um erro',
      tryAgain: 'Tentar novamente',
      cancel: 'Cancelar',
      save: 'Salvar',
      delete: 'Excluir',
      confirm: 'Confirmar',
      search: 'Buscar',
      noResults: 'Nenhum resultado encontrado',
      seeMore: 'Ver mais',
      seeLess: 'Ver menos'
    }
  },
  'en': {
    auth: {
      signIn: 'Sign In',
      signUp: 'Sign Up',
      email: 'Email',
      password: 'Password',
      name: 'Name',
      username: 'Username',
      bio: 'Bio',
      rememberMe: 'Remember me',
      termsAccept: 'I accept the terms of use and cookie policy',
      ageVerification: 'I am over 18 years old',
      createAccount: 'Create account',
      alreadyHaveAccount: 'Already have an account? Sign in',
      dontHaveAccount: 'Don\'t have an account? Sign up'
    },
    navigation: {
      feed: 'Feed',
      stories: 'Stories',
      search: 'Search',
      communities: 'Communities',
      music: 'Music',
      videos: 'Videos',
      places: 'Places',
      news: 'News',
      profile: 'Profile',
      notifications: 'Notifications',
      chat: 'Chat',
      ranking: 'Ranking',
      match: 'Match',
      radio: 'Radio',
      web: 'Web'
    },
    profile: {
      editProfile: 'Edit Profile',
      followers: 'followers',
      following: 'following',
      posts: 'posts',
      follow: 'Follow',
      unfollow: 'Unfollow',
      block: 'Block',
      report: 'Report',
      bio: 'Bio',
      noBio: 'No bio',
      editBio: 'Edit bio',
      saveBio: 'Save',
      bioUpdated: 'Bio updated successfully',
      failedToUpdate: 'Failed to update bio'
    },
    notifications: {
      newLike: 'liked your post',
      newComment: 'commented on your post',
      newFollower: 'started following you',
      newMatch: 'matched with you',
      noNotifications: 'No notifications yet',
      whenInteract: 'When someone interacts with your content, you\'ll see it here'
    },
    chat: {
      typeMessage: 'Type a message...',
      sendPhoto: 'Send photo',
      shareLocation: 'Share location',
      newChat: 'New chat',
      noMessages: 'No messages yet',
      startChat: 'Start a conversation!',
      deleteChat: 'Delete chat',
      confirmDelete: 'Are you sure you want to delete this chat?'
    },
    communities: {
      createCommunity: 'Create community',
      joinCommunity: 'Join',
      leaveCommunity: 'Leave',
      members: 'members',
      createTopic: 'Create topic',
      topics: 'topics',
      noTopics: 'No topics yet',
      beFirst: 'Be the first to create a topic!'
    },
    places: {
      searchPlaces: 'Search nearby places...',
      getDirections: 'Get directions',
      openNow: 'Open now',
      closed: 'Closed',
      reviews: 'reviews'
    },
    common: {
      loading: 'Loading...',
      error: 'An error occurred',
      tryAgain: 'Try again',
      cancel: 'Cancel',
      save: 'Save',
      delete: 'Delete',
      confirm: 'Confirm',
      search: 'Search',
      noResults: 'No results found',
      seeMore: 'See more',
      seeLess: 'See less'
    }
  }
};