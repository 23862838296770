import { useState } from 'react';
import { Heart, Star, Loader2 } from 'lucide-react';
import { doc, updateDoc, arrayUnion, arrayRemove, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';

interface ProfileVotesProps {
  profileId: string;
  currentUserId: string | undefined;
  initialVotes: {
    likes: string[];
    stars: string[];
  };
  profileName: string;
  profileUsername: string;
}

export function ProfileVotes({ 
  profileId, 
  currentUserId, 
  initialVotes,
  profileName,
  profileUsername
}: ProfileVotesProps) {
  const [votes, setVotes] = useState({
    likes: initialVotes.likes || [],
    stars: initialVotes.stars || []
  });
  const [isVoting, setIsVoting] = useState(false);

  const handleVote = async (type: 'likes' | 'stars') => {
    if (!currentUserId) {
      toast.error('Please sign in to vote', {
        style: {
          background: '#000000',
          color: '#ffffff'
        }
      });
      return;
    }

    if (currentUserId === profileId) {
      toast.error('You cannot vote on your own profile', {
        style: {
          background: '#000000',
          color: '#ffffff'
        }
      });
      return;
    }

    if (isVoting) return;

    try {
      setIsVoting(true);
      const userRef = doc(db, 'users', profileId);
      const hasVoted = votes[type].includes(currentUserId);

      // Remove vote from all categories first
      const updates: any = {};
      ['likes', 'stars'].forEach(voteType => {
        if (votes[voteType].includes(currentUserId)) {
          updates[voteType] = arrayRemove(currentUserId);
        }
      });

      // Add new vote if not removing
      if (!hasVoted) {
        updates[type] = arrayUnion(currentUserId);
      }

      await updateDoc(userRef, updates);

      // Create notification for new vote
      if (!hasVoted) {
        const notificationType = `profile_${type.slice(0, -1)}`; // Convert 'likes' to 'profile_like', etc.
        
        await addDoc(collection(db, 'notifications'), {
          type: notificationType,
          recipientId: profileId,
          senderId: currentUserId,
          senderName: profileName,
          senderUsername: profileUsername,
          timestamp: serverTimestamp(),
          read: false
        });

        // Show custom toast based on vote type
        const toastConfig = {
          likes: { icon: '❤️', message: 'Profile liked!' },
          stars: { icon: '⭐', message: 'Profile starred!' }
        };

        const config = toastConfig[type];
        toast.success(config.message, {
          icon: config.icon,
          style: {
            background: '#000000',
            color: '#ffffff',
            fontWeight: 'bold'
          }
        });
      } else {
        toast.success('Vote removed', {
          style: {
            background: '#000000',
            color: '#ffffff'
          }
        });
      }

      // Update local state
      setVotes(prev => {
        const newVotes = {
          likes: prev.likes.filter(id => id !== currentUserId),
          stars: prev.stars.filter(id => id !== currentUserId)
        };
        if (!hasVoted) {
          newVotes[type] = [...newVotes[type], currentUserId];
        }
        return newVotes;
      });
    } catch (error) {
      console.error('Error voting:', error);
      toast.error('Failed to vote', {
        style: {
          background: '#000000',
          color: '#ffffff'
        }
      });
    } finally {
      setIsVoting(false);
    }
  };

  return (
    <div className="flex items-center justify-center space-x-6 py-4">
      <button
        onClick={() => handleVote('likes')}
        disabled={isVoting}
        className={`flex flex-col items-center space-x-1 ${
          votes.likes.includes(currentUserId || '')
            ? 'text-red-500'
            : 'text-gray-500 hover:text-red-500'
        } transition-colors disabled:opacity-50`}
        title="Like"
      >
        <Heart className={`h-6 w-6 ${
          votes.likes.includes(currentUserId || '') ? 'fill-current' : ''
        }`} />
        <span className="text-sm">{votes.likes.length}</span>
      </button>

      <button
        onClick={() => handleVote('stars')}
        disabled={isVoting}
        className={`flex flex-col items-center space-x-1 ${
          votes.stars.includes(currentUserId || '')
            ? 'text-yellow-500'
            : 'text-gray-500 hover:text-yellow-500'
        } transition-colors disabled:opacity-50`}
        title="Star"
      >
        <Star className={`h-6 w-6 ${
          votes.stars.includes(currentUserId || '') ? 'fill-current' : ''
        }`} />
        <span className="text-sm">{votes.stars.length}</span>
      </button>

      {isVoting && (
        <Loader2 className="h-4 w-4 animate-spin text-blue-500" />
      )}
    </div>
  );
}