import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { X, Loader2, Crosshair } from 'lucide-react';
import { getCurrentPosition } from '../utils/geolocation';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Compartilhar Localização',
    currentLocation: 'Sua localização atual é detectada automaticamente',
    tapAnywhere: 'Toque em qualquer lugar no mapa para mudar a localização',
    useCurrentLocation: 'Usar localização atual',
    errors: {
      locationAccess: 'Não foi possível acessar sua localização',
      timeout: 'Tempo esgotado ao buscar localização',
      unavailable: 'Informação de localização indisponível',
      denied: 'Acesso à localização negado. Por favor, habilite os serviços de localização.'
    }
  },
  'en': {
    title: 'Share Location',
    currentLocation: 'Your current location is automatically detected',
    tapAnywhere: 'Tap anywhere on the map to change the location',
    useCurrentLocation: 'Use current location',
    errors: {
      locationAccess: 'Could not access your location',
      timeout: 'Location request timed out',
      unavailable: 'Location information unavailable',
      denied: 'Location access denied. Please enable location services.'
    }
  }
};

// Fix for default marker icons in react-leaflet
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

interface LocationPickerProps {
  onLocationSelect: (coords: { latitude: number; longitude: number }) => void;
  onClose: () => void;
}

function LocationMarker({ 
  position, 
  onLocationSelect 
}: { 
  position: L.LatLng | null;
  onLocationSelect: (coords: { latitude: number; longitude: number }) => void;
}) {
  const map = useMapEvents({
    click(e) {
      onLocationSelect({ latitude: e.latlng.lat, longitude: e.latlng.lng });
    },
  });

  return position === null ? null : (
    <Marker position={position} />
  );
}

export function LocationPicker({ onLocationSelect, onClose }: LocationPickerProps) {
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState<L.LatLng | null>(null);
  const [map, setMap] = useState<L.Map | null>(null);
  const [error, setError] = useState<string | null>(null);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    let mounted = true;

    const initializeLocation = async () => {
      try {
        setLoading(true);
        setError(null);

        const position = await getCurrentPosition({
          enableHighAccuracy: true,
          timeout: isIOS ? 15000 : 10000 // Longer timeout for iOS
        });

        if (!mounted) return;

        const newPosition = new L.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );

        setPosition(newPosition);
        map?.setView(newPosition, 15);
        
        // Automatically select current position
        onLocationSelect({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });

      } catch (error: any) {
        console.error('Error getting location:', error);
        if (!mounted) return;

        setError(error.message);
        // Default to São Paulo if location fails
        const defaultPosition = new L.LatLng(-23.5505, -46.6333);
        setPosition(defaultPosition);
        map?.setView(defaultPosition, 15);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    initializeLocation();

    return () => {
      mounted = false;
    };
  }, [map]);

  const handleMapClick = (e: L.LeafletMouseEvent) => {
    setPosition(e.latlng);
    onLocationSelect({ latitude: e.latlng.lat, longitude: e.latlng.lng });
  };

  const recenterMap = async () => {
    try {
      setLoading(true);
      setError(null);

      const position = await getCurrentPosition({
        enableHighAccuracy: true,
        timeout: isIOS ? 15000 : 10000
      });

      const newPosition = new L.LatLng(
        position.coords.latitude,
        position.coords.longitude
      );

      setPosition(newPosition);
      map?.setView(newPosition, 15);
      
      onLocationSelect({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      });

      toast.success('Location updated');
    } catch (error: any) {
      console.error('Error getting location:', error);
      setError(error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <h3 className="text-lg font-semibold mb-4">Location Error</h3>
          <p className="text-gray-600 mb-6">{error}</p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={recenterMap}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Share Location</h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={recenterMap}
              disabled={loading}
              className="p-2 hover:bg-gray-100 rounded-full text-blue-500 disabled:opacity-50"
              title="Use current location"
            >
              {loading ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                <Crosshair className="h-5 w-5" />
              )}
            </button>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="h-96 rounded-lg overflow-hidden">
          <MapContainer
            center={position?.toJSON() || [-23.5505, -46.6333]}
            zoom={15}
            style={{ height: '100%', width: '100%' }}
            whenCreated={setMap}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker position={position} onLocationSelect={onLocationSelect} />
          </MapContainer>
        </div>

        <div className="mt-4 text-sm text-gray-500 text-center space-y-2">
          <p>Your current location is automatically detected</p>
          <p>Tap anywhere on the map to change the location</p>
        </div>
      </div>
    </div>
  );
}