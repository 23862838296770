import { ReactNode } from 'react';
import { MainMenu } from './MainMenu';
import { useTheme } from '../contexts/ThemeContext';

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  const { isDarkMode } = useTheme();
  
  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <MainMenu />
      <main className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        {children}
      </main>
    </div>
  );
}