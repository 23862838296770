import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Loader2, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import { VerificationBadge } from './VerificationBadge';

interface AlliesListProps {
  userId: string;
  allies: string[];
  isDarkMode?: boolean;
  language?: string;
}

interface FollowingUser {
  uid: string;
  name: string;
  username: string;
  photoURL: string;
  allies?: string[];
}

const INITIAL_FOLLOWING_COUNT = 6;
const FOLLOWING_PER_PAGE = 12;

const translations = {
  'pt-BR': {
    title: 'Seguidores Aliados',
    noAllies: 'Nenhum aliado ainda',
    loading: 'Carregando...',
    showMore: 'Mostrar Mais'
  },
  'en': {
    title: 'Allies Followers',
    noAllies: 'No allies yet',
    loading: 'Loading...',
    showMore: 'Show More'
  }
};

export function AlliesList({ userId, allies, isDarkMode = false, language = 'en' }: AlliesListProps) {
  const [followingUsers, setFollowingUsers] = useState<FollowingUser[]>([]);
  const [displayedFollowing, setDisplayedFollowing] = useState<FollowingUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    const fetchFollowing = async () => {
      if (!allies.length) {
        setLoading(false);
        return;
      }

      try {
        const usersRef = collection(db, 'users');
        const followingData: FollowingUser[] = [];

        // Fetch following in batches of 10 to avoid query limitations
        for (let i = 0; i < allies.length; i += 10) {
          const batch = allies.slice(i, i + 10);
          const followingQuery = query(usersRef, where('uid', 'in', batch));
          const snapshot = await getDocs(followingQuery);

          snapshot.docs.forEach(doc => {
            const data = doc.data();
            if (data.username) {
              followingData.push({
                uid: doc.id,
                username: data.username,
                name: data.name,
                photoURL: data.photoURL || null,
                allies: data.allies || []
              });
            }
          });
        }

        // Sort following by most recent first
        const sortedFollowing = followingData.sort((a, b) => {
          const indexA = allies.indexOf(a.uid);
          const indexB = allies.indexOf(b.uid);
          return indexB - indexA;
        });

        setFollowingUsers(sortedFollowing);
        setDisplayedFollowing(sortedFollowing.slice(0, INITIAL_FOLLOWING_COUNT));
        setHasMore(sortedFollowing.length > INITIAL_FOLLOWING_COUNT);
      } catch (error) {
        console.error('Error fetching following:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFollowing();
  }, [allies]);

  const loadMoreFollowing = () => {
    setLoadingMore(true);
    const currentLength = displayedFollowing.length;
    const nextFollowing = followingUsers.slice(currentLength, currentLength + FOLLOWING_PER_PAGE);
    setDisplayedFollowing(prev => [...prev, ...nextFollowing]);
    setHasMore(currentLength + FOLLOWING_PER_PAGE < followingUsers.length);
    setLoadingMore(false);
  };

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className={`h-6 w-6 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  if (!allies.length) {
    return (
      <div className="text-center py-8 px-4">
        <Users className={`h-12 w-12 mx-auto ${isDarkMode ? 'text-gray-600' : 'text-gray-400'} mb-3`} />
        <p className={isDarkMode ? 'text-gray-300' : 'text-gray-500'}>{t('noAllies')}</p>
      </div>
    );
  }

  return (
    <div className="py-4 px-4">
      <h3 className={`text-lg font-semibold mb-4 flex items-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        <Users className="h-5 w-5 mr-2" />
        {t('title')} ({allies.length})
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        {displayedFollowing.map((ally) => (
          <Link
            key={`${ally.uid}-${ally.username}`}
            to={`/profile/${ally.username}`}
            className={`flex flex-col items-center p-3 rounded-lg transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600' 
                : 'bg-gray-50 hover:bg-gray-100'
            }`}
          >
            <div className="w-16 h-16 rounded-full overflow-hidden">
              <img
                src={ally.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${ally.uid}`}
                alt={`@${ally.username}`}
                className="w-full h-full object-cover"
                loading="lazy"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = `https://api.dicebear.com/7.x/avataaars/svg?seed=${ally.uid}`;
                }}
              />
            </div>
            <span className={`mt-2 font-medium text-sm text-center truncate w-full ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              @{ally.username}
            </span>
            <VerificationBadge alliesCount={ally.allies?.length || 0} />
          </Link>
        ))}
      </div>

      {hasMore && (
        <div className="flex justify-center mt-6">
          <button
            onClick={loadMoreFollowing}
            disabled={loadingMore}
            className={`px-6 py-2 rounded-full disabled:opacity-50 flex items-center space-x-2 ${
              isDarkMode 
                ? 'bg-blue-600 text-white hover:bg-blue-700' 
                : 'bg-black text-white hover:bg-gray-800'
            }`}
          >
            {loadingMore ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>{t('loading')}</span>
              </>
            ) : (
              <span>{t('showMore')}</span>
            )}
          </button>
        </div>
      )}
    </div>
  );
}