import axios from 'axios';

const API_KEY = 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8';
const VISION_API_URL = 'https://vision.googleapis.com/v1/images:annotate';

interface SafeSearchAnnotation {
  adult: string;
  spoof: string;
  medical: string;
  violence: string;
  racy: string;
}

// List of blocked promotional terms
const BLOCKED_TERMS = [
  'whatsapp', 'zap', 'telefone', 'email', 'site', 'www.', 'instagram', '@',
  'promoção', 'desconto', 'compre agora', 'oferta', 'grátis', 'última chance', '50% off', 'exclusivo', 'imperdível', 'aproveite',
  'não perca', 'desconto especial', 'liquidação', 'mega oferta', 'super desconto', 'leve 2 pague 1', 'frete grátis',
  'preço especial', 'estoque limitado', 'promoção relâmpago', 'desconto progressivo', 'oferta exclusiva', 'somente hoje',
  'compre já', 'cupom de desconto', 'desconto relâmpago', 'aproveite agora', 'melhor preço', 'últimos dias', 'compra garantida',
  'desconto imperdível', 'preço de fábrica', 'oferta do dia', 'desconto surpresa', 'preço reduzido', 'super promoção',
  'flash sale', 'liquidação total', 'desconto exclusivo', 'preços reduzidos', 'compre e ganhe', 'só hoje', 'descontão',
  'frete incluso', 'preço imbatível', 'aproveite já', 'estoque acabando', 'última unidade', 'leve mais por menos',
  'super oferta', 'economize', 'brinde exclusivo', 'promoção especial', 'compra segura', 'sem juros', 'apenas algumas unidades',
  'preço de atacado', 'combo especial', 'desconto duplo', 'parcelamento sem juros', 'melhor desconto', 'liquidação relâmpago',
  'compre já e economize', 'cupom exclusivo', 'preço promocional', 'super desconto especial', 'promoção limitada',
  'preço inacreditável', 'desconto no carrinho', 'oferta por tempo limitado', 'leve mais e pague menos', 'combo promocional',
  'ganhe um brinde', 'últimas unidades disponíveis', 'frete grátis em compras acima de', 'compre agora e economize',
  'desconto por tempo limitado', 'desconto extra', 'economize agora', 'grande liquidação', 'garanta o seu', 'cupom de oferta',
  'desconto para novos clientes', 'promoção relâmpago especial', 'últimas unidades', 'oferta limitada', 'desconto especial para você',
  'desconto exclusivo online', 'parcelamento facilitado', 'preço de ocasião', 'super oferta do dia', 'melhores preços',
  'cupom especial', 'só por hoje', 'estoque limitado, compre já', 'leve 3 pague 2', 'promoção de aniversário',
  'promoção exclusiva para clientes', 'desconto de lançamento', 'oferta especial de inauguração', 'compra antecipada com desconto',
  'frete reduzido', 'promoção bombástica', 'compre com desconto agora', 'promoção exclusiva para membros',
  'fique de olho na nossa oferta', 'desconto relâmpago apenas hoje', 'promoção limitada, não perca',
  'leve o segundo com 50% de desconto', 'preços imbatíveis', 'preço promocional para primeiras compras',
  'melhor preço garantido', 'promoção especial para clientes fiéis', 'desconto em todo o site',
  'última oportunidade de economizar', 'compre agora e pague menos', 'oferta para os primeiros compradores',
  'desconto especial para seguidores', 'promoção de queima de estoque', 'frete grátis para assinantes',
  'oferta especial por tempo determinado', 'promoção semanal', 'desconto válido apenas hoje', 'oferta flash',
  'cupom de desconto para novos clientes', 'leve um, ganhe outro', 'frete grátis na sua primeira compra',
  'desconto exclusivo para quem compartilhar', 'promoção especial de lançamento', 'economize já', 'liquidação exclusiva',
  'desconto progressivo, quanto mais compra, mais economiza', 'última liquidação do mês', 'super desconto de fim de ano',
  'compre agora e pague parcelado', 'promoção especial para assinantes', 'preço especial para pré-venda',
  'desconto válido para os primeiros 100 clientes', 'melhor promoção do ano', 'promoção válida apenas online',
  'desconto especial no app', 'oferta de Black Friday', 'compre na promoção e leve mais', 'oportunidade única',
  'compre antes que acabe', 'leve 4 pague 3', 'desconto maior para compras acima de', 'oferta válida até meia-noite',
  'última oferta do mês', 'desconto especial de natal', 'compre já com frete reduzido', 'promoção exclusiva de páscoa',
  'aproveite nossa liquidação relâmpago', 'preço reduzido por tempo limitado', 'oferta especial para estudantes',
  'oferta especial para profissionais', 'cupom de fidelidade', 'desconto garantido', 'compre na pré-venda com desconto',
  'promoção válida até esgotar o estoque', 'preço promocional para quem se cadastrar', 'leve agora e pague depois',
  'melhores descontos online', 'promoção exclusiva no site', 'promoção ativa apenas neste fim de semana',
  'compre hoje e pague parcelado', 'leve grátis na compra acima de', 'promoção válida apenas na loja online',
  'frete grátis acima de R$', 'cupom de desconto aplicado automaticamente', 'grande queima de estoque',
  'desconto automático no carrinho', 'oferta disponível por poucas horas', 'promoção especial para quem assinar',
  'melhor oferta do mês', 'últimas unidades em estoque', 'promoção exclusiva para quem indicar amigos',
  'leve dois e ganhe um', 'promoção do dia', 'promoção válida apenas para clientes cadastrados', 'super liquidação do ano',
  'última oportunidade de comprar com desconto', 'estoque limitado, aproveite agora', 'desconto especial para quem pagar no Pix',
  'promoção exclusiva para compras no app', 'preço reduzido por tempo determinado', 'leve grátis na compra de',
  'frete fixo em todas as compras', 'promoção progressiva', 'liquidação de verão', 'super oferta de inverno',
  'promoção válida para os primeiros clientes do dia', 'última oferta da temporada', 'uniform', 'garanta já', 'faça seu',
  'reais', 'R$', 'r$'
];

function containsBlockedTerms(text: string): boolean {
  const normalizedText = text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return BLOCKED_TERMS.some(term => {
    const normalizedTerm = term.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return normalizedText.includes(normalizedTerm);
  });
}

export async function moderateImage(imageData: string | Blob): Promise<boolean> {
  try {
    // Convert Blob to base64 if needed
    let base64Image: string;
    if (imageData instanceof Blob) {
      base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          resolve(base64String.split(',')[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(imageData);
      });
    } else if (imageData.startsWith('data:')) {
      base64Image = imageData.split(',')[1];
    } else if (imageData.startsWith('http')) {
      const response = await fetch(imageData);
      const blob = await response.blob();
      base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          resolve(base64String.split(',')[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } else {
      base64Image = imageData;
    }

    const response = await axios.post(
      `${VISION_API_URL}?key=${API_KEY}`,
      {
        requests: [
          {
            image: {
              content: base64Image
            },
            features: [
              {
                type: 'SAFE_SEARCH_DETECTION'
              },
              {
                type: 'TEXT_DETECTION'
              }
            ]
          }
        ]
      }
    );

    const safeSearch = response.data.responses[0].safeSearchAnnotation as SafeSearchAnnotation;
    const textAnnotations = response.data.responses[0].textAnnotations;

    // More permissive moderation rules for profile photos
    // Only block explicit adult content and violence
    if (safeSearch.adult === 'VERY_LIKELY') {
      console.log('Blocked: Explicit adult content detected');
      return false;
    }

    if (safeSearch.violence === 'VERY_LIKELY') {
      console.log('Blocked: Violence detected');
      return false;
    }

    // Allow swimwear/beachwear content
    // Only block if both racy and adult content are highly likely
    if (safeSearch.racy === 'VERY_LIKELY' && safeSearch.adult === 'LIKELY') {
      console.log('Blocked: Overly explicit content detected');
      return false;
    }

    // Check for promotional content in detected text
    if (textAnnotations && textAnnotations.length > 0) {
      const detectedText = textAnnotations[0].description;
      if (containsBlockedTerms(detectedText)) {
        console.log('Blocked: Promotional content detected in image text');
        return false;
      }
    }

    return true; // Image is safe
  } catch (error) {
    console.error('Error moderating image:', error);
    throw error;
  }
}