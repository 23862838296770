import { useState, useEffect } from 'react';
import { Globe, Search } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { useTheme } from '../contexts/ThemeContext';

const translations = {
  'pt-BR': {
    title: 'Poosting Web',
    searchPlaceholder: '',
    loading: 'Carregando...',
    noResults: 'Nenhum resultado encontrado',
    searchWeb: 'Buscar na Web',
    enterSearch: 'Digite sua busca',
    errors: {
      searchFailed: 'Falha ao realizar busca'
    }
  },
  'en': {
    title: 'Poosting Web',
    searchPlaceholder: '',
    loading: 'Loading...',
    noResults: 'No results found',
    searchWeb: 'Search Web',
    enterSearch: 'Enter your search',
    errors: {
      searchFailed: 'Failed to perform search'
    }
  }
};

export function WebSearch() {
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    // Detect user's language
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    // Load Google Custom Search script
    const script = document.createElement('script');
    script.src = 'https://cse.google.com/cse.js?cx=08d0dcdf675efd6f3';
    script.async = true;
    document.head.appendChild(script);

    // Add dark mode styles for Google Custom Search
    const style = document.createElement('style');
    style.textContent = `
      .gsc-control-cse {
        background-color: ${isDarkMode ? '#1f2937 !important' : '#ffffff !important'};
        border-color: ${isDarkMode ? '#374151 !important' : '#e5e7eb !important'};
        font-family: system-ui, -apple-system, sans-serif !important;
      }
      .gsc-input-box {
        background-color: ${isDarkMode ? '#374151 !important' : '#ffffff !important'};
        border-color: ${isDarkMode ? '#4b5563 !important' : '#d1d5db !important'};
      }
      .gsc-input {
        color: #000000 !important;
      }
      input.gsc-input {
        color: #000000 !important;
      }
      .gsc-input::placeholder {
        color: #6B7280 !important;
      }
      .gsc-completion-container {
        background-color: ${isDarkMode ? '#374151 !important' : '#ffffff !important'};
        border-color: ${isDarkMode ? '#4b5563 !important' : '#d1d5db !important'};
      }
      .gsc-completion-title {
        color: ${isDarkMode ? '#ffffff !important' : '#000000 !important'};
      }
      .gsc-completion-snippet {
        color: ${isDarkMode ? '#9ca3af !important' : '#6b7280 !important'};
      }
      .gsc-webResult.gsc-result {
        background-color: ${isDarkMode ? '#1f2937 !important' : '#ffffff !important'};
        border-color: ${isDarkMode ? '#374151 !important' : '#e5e7eb !important'};
      }
      .gs-title {
        color: ${isDarkMode ? '#60a5fa !important' : '#2563eb !important'};
      }
      .gs-snippet {
        color: ${isDarkMode ? '#9ca3af !important' : '#6b7280 !important'};
      }
      .gsc-cursor-page {
        color: ${isDarkMode ? '#ffffff !important' : '#000000 !important'};
        background-color: ${isDarkMode ? '#374151 !important' : '#ffffff !important'};
        border-color: ${isDarkMode ? '#4b5563 !important' : '#d1d5db !important'};
      }
      .gsc-cursor-current-page {
        color: ${isDarkMode ? '#ffffff !important' : '#ffffff !important'};
        background-color: ${isDarkMode ? '#3b82f6 !important' : '#2563eb !important'};
      }
      .gsc-search-button-v2 {
        background-color: ${isDarkMode ? '#3b82f6 !important' : '#2563eb !important'};
      }
      .gsc-search-button-v2:hover {
        background-color: ${isDarkMode ? '#2563eb !important' : '#1d4ed8 !important'};
      }
      .gsc-input-box input::placeholder {
        color: ${isDarkMode ? '#9ca3af !important' : '#6b7280 !important'};
      }
      .gsc-orderby-label {
        color: ${isDarkMode ? '#ffffff !important' : '#000000 !important'};
      }
      .gsc-selected-option-container {
        background-color: ${isDarkMode ? '#374151 !important' : '#ffffff !important'};
        color: ${isDarkMode ? '#ffffff !important' : '#000000 !important'};
        border-color: ${isDarkMode ? '#4b5563 !important' : '#d1d5db !important'};
      }
      .gsc-option-menu-item {
        background-color: ${isDarkMode ? '#374151 !important' : '#ffffff !important'};
        color: ${isDarkMode ? '#ffffff !important' : '#000000 !important'};
      }
      .gsc-option-menu-item:hover {
        background-color: ${isDarkMode ? '#4b5563 !important' : '#f3f4f6 !important'};
      }
    `;
    document.head.appendChild(style);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
      if (document.head.contains(style)) {
        document.head.removeChild(style);
      }
    };
  }, [isDarkMode]);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Globe className={`h-6 w-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        <div className="pt-[130px] px-4 pb-4">
          <div className="flex items-center space-x-2 mb-4">
            <Search className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
            <span className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {t('searchWeb')}
            </span>
          </div>
          <div className="gcse-search"></div>
        </div>
      </main>
    </div>
  );
}