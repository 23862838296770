import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, addDoc, serverTimestamp, updateDoc, doc, limit, startAfter, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { formatDistanceToNow } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { Loader2, Heart, Star, Trash2, Bomb, MessageCircle, Send, X, UserPlus } from 'lucide-react';
import { Post } from './Post';
import toast from 'react-hot-toast';

interface NotificationListProps {
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Notificações',
    loading: 'Carregando...',
    noNotifications: 'Nenhuma notificação ainda',
    whenInteract: 'Quando alguém interagir com seu conteúdo, você verá aqui',
    notifications: {
      comment_reply: 'respondeu seu comentário',
      comment_like: 'curtiu seu comentário',
      post_like: 'curtiu sua publicação',
      post_comment: 'comentou em sua publicação',
      profile_match: 'deu match com você',
      story_like: 'curtiu seu story',
      new_follower: 'começou a seguir você'
    },
    relatedPost: 'Publicação Relacionada',
    showMore: 'Mostrar Mais',
    loadingMore: 'Carregando mais...',
    notificationTypes: {
      comment_reply: 'Resposta ao comentário',
      comment_like: 'Curtida no comentário',
      post_like: 'Curtida na publicação',
      post_comment: 'Comentário na publicação'
    }
  },
  'en': {
    title: 'Notifications',
    loading: 'Loading...',
    noNotifications: 'No notifications yet',
    whenInteract: 'When someone interacts with your content, you\'ll see it here',
    notifications: {
      comment_reply: 'replied to your comment',
      comment_like: 'liked your comment',
      post_like: 'liked your post',
      post_comment: 'commented on your post',
      profile_match: 'matched with you',
      story_like: 'liked your story',
      new_follower: 'started following you'
    },
    relatedPost: 'Related Post',
    showMore: 'Show More',
    loadingMore: 'Loading more...',
    notificationTypes: {
      comment_reply: 'Comment Reply',
      comment_like: 'Comment Like',
      post_like: 'Post Like',
      post_comment: 'Post Comment'
    }
  }
};

export function NotificationList({ language = 'en' }: NotificationListProps) {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [loadingPost, setLoadingPost] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState<string | null>(null);
  const { user } = useAuth();
  const dateLocale = language === 'pt-BR' ? ptBR : enUS;

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    if (!user) return;

    const notificationsRef = collection(db, 'notifications');
    const q = query(
      notificationsRef,
      where('recipientId', '==', user.uid),
      orderBy('timestamp', 'desc'),
      limit(20)
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      try {
        const now = new Date();
        const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

        // Filter and delete old notifications
        const notificationsToDelete: string[] = [];
        const validNotifications: any[] = [];

        snapshot.docs.forEach(doc => {
          const data = doc.data();
          const timestamp = data.timestamp?.toDate() || new Date();
          
          if (timestamp < twentyFourHoursAgo) {
            notificationsToDelete.push(doc.id);
          } else {
            validNotifications.push({
              id: doc.id,
              ...data,
              timestamp: timestamp
            });
          }
        });

        // Delete expired notifications
        await Promise.all(
          notificationsToDelete.map(id => 
            deleteDoc(doc(db, 'notifications', id))
          )
        );

        setNotifications(validNotifications);
        setLoading(false);

        // Mark new notifications as read
        await Promise.all(
          snapshot.docs.filter(doc => !doc.data().read)
            .map(doc => updateDoc(doc.ref, { read: true }))
        );
      } catch (error) {
        console.error('Error processing notifications:', error);
        toast.error('Failed to load notifications');
      }
    });

    return () => unsubscribe();
  }, [user]);

  const handleNotificationClick = async (notification: any) => {
    setSelectedNotificationId(notification.id);
    
    // Only load post for notifications that have a postId
    if (!notification.postId) return;

    try {
      setLoadingPost(true);
      const postRef = doc(db, 'posts', notification.postId);
      const postDoc = await getDoc(postRef);
      
      if (postDoc.exists()) {
        setSelectedPost({
          id: postDoc.id,
          ...postDoc.data(),
          timestamp: postDoc.data().timestamp?.toDate() || new Date(),
          action: t(`notificationTypes.${notification.type}`) // Use translated notification type
        });
      } else {
        toast.error('Post not found or was deleted');
      }
    } catch (error) {
      console.error('Error loading post:', error);
      toast.error('Failed to load post');
    } finally {
      setLoadingPost(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <>
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        {notifications.map((notification) => {
          const isSelected = selectedNotificationId === notification.id;
          
          return (
            <div
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
              className={`block p-4 ${
                isSelected 
                  ? 'bg-blue-50 dark:bg-blue-900/50' 
                  : !notification.read 
                    ? 'bg-blue-50 dark:bg-blue-900/20' 
                    : 'hover:bg-gray-50 dark:hover:bg-gray-700'
              } transition-colors relative cursor-pointer`}
            >
              <div className="flex items-start space-x-3">
                <Link 
                  to={`/profile/${notification.senderUsername}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={notification.senderPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${notification.senderId}`}
                    alt={notification.senderName}
                    className="h-10 w-10 rounded-full"
                  />
                </Link>
                <div className="flex-1 min-w-0">
                  <div className="flex items-baseline justify-between">
                    <div className="flex items-center space-x-2">
                      <Link 
                        to={`/profile/${notification.senderUsername}`}
                        onClick={(e) => e.stopPropagation()}
                        className={`font-medium hover:underline ${
                          isSelected ? 'text-black dark:text-white' : 'text-gray-900 dark:text-white'
                        }`}
                      >
                        {notification.senderName}
                      </Link>
                      <span className={`text-sm ${isSelected ? 'text-black dark:text-white' : 'text-gray-500 dark:text-gray-400'}`}>
                        {formatDistanceToNow(notification.timestamp, { 
                          addSuffix: true,
                          locale: dateLocale
                        })}
                      </span>
                    </div>
                  </div>
                  
                  <div className="flex items-center space-x-2">
                    {/* Notification type icon */}
                    {notification.type.includes('like') && (
                      <Heart className="h-4 w-4 text-red-500 fill-current" />
                    )}
                    {notification.type.includes('star') && (
                      <Star className="h-4 w-4 text-yellow-500 fill-current" />
                    )}
                    {notification.type.includes('trash') && (
                      <Trash2 className="h-4 w-4 text-gray-500" />
                    )}
                    {notification.type.includes('match') && (
                      <Bomb className="h-4 w-4 text-orange-500" />
                    )}
                    {notification.type.includes('comment') && (
                      <MessageCircle className="h-4 w-4 text-blue-500" />
                    )}
                    {notification.type === 'new_follower' && (
                      <UserPlus className="h-4 w-4 text-green-500" />
                    )}
                    <span className={`text-sm ${
                      isSelected ? 'text-black dark:text-white' : 'text-gray-600 dark:text-gray-300'
                    }`}>
                      {t(`notifications.${notification.type}`)}
                    </span>
                  </div>

                  {/* Content preview for comments */}
                  {notification.content && (
                    <div className={`mt-4 p-4 rounded-lg ${
                      isSelected 
                        ? 'bg-gray-100 dark:bg-gray-800' 
                        : 'bg-gray-100 dark:bg-gray-800/50'
                    }`}>
                      <p className={`whitespace-pre-wrap break-words text-sm ${
                        isSelected ? 'text-black dark:text-white' : 'text-gray-700 dark:text-gray-300'
                      }`}>
                        {notification.content}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Post Modal */}
      {selectedPost && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
          onClick={() => {
            setSelectedPost(null);
            setSelectedNotificationId(null);
          }}
        >
          <div 
            className="w-full max-w-2xl bg-white dark:bg-gray-800 rounded-lg shadow-xl max-h-[90vh] overflow-y-auto"
            onClick={e => e.stopPropagation()}
          >
            <div className="sticky top-0 z-10 p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 flex justify-between items-center">
              <div className="flex items-center space-x-3">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {t('relatedPost')}
                </h3>
                {selectedPost.action && (
                  <span className="text-sm px-3 py-1 bg-gray-100 dark:bg-gray-700 rounded-full text-gray-700 dark:text-gray-300">
                    {selectedPost.action}
                  </span>
                )}
              </div>
              <button
                onClick={() => {
                  setSelectedPost(null);
                  setSelectedNotificationId(null);
                }}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
                title="Close"
              >
                <X className="h-5 w-5 text-gray-600 dark:text-gray-400" />
              </button>
            </div>
            
            <div className="p-4">
              {loadingPost ? (
                <div className="flex justify-center py-8">
                  <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
                </div>
              ) : (
                <Post {...selectedPost} isDarkMode={true} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}