import { useState, useEffect, useRef } from 'react';
import { collection, query, where, orderBy, getDocs, limit, startAfter } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { StoryViewer } from './StoryViewer';
import { CreateStory } from './CreateStory';
import { StoryGrid } from './StoryGrid';
import { fetchStories } from '../utils/stories/fetch';
import type { Story } from '../utils/stories/types';
import { MainMenu } from '../components/MainMenu';
import { Loader2, WifiOff, ChevronLeft, ChevronRight, Plus } from 'lucide-react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const translations = {
  'pt-BR': {
    title: 'Poosting Stories',
    timeAgo: {
      lessThanAMinute: 'menos de um minuto atrás',
      oneMinute: '1 minuto atrás',
      minutes: 'minutos atrás',
      oneHour: '1 hora atrás',
      hours: 'horas atrás'
    },
    createStory: {
      camera: 'Câmera',
      gallery: 'Galeria',
      music: 'Música',
      uploadImage: 'Enviar Imagem',
      noStoriesYet: 'Nenhum story ainda',
      beFirst: 'Seja o primeiro a compartilhar um story!',
      processing: 'Processando...',
      checkingImage: 'Verificando imagem...',
      storyCreated: 'Story criado com sucesso!',
      waitMessage: 'Aguarde {time} minutos antes de postar outro story',
      errors: {
        accessDenied: 'Não foi possível acessar a câmera ou microfone',
        uploadFailed: 'Falha ao enviar story',
        processingFailed: 'Falha ao processar story',
        invalidContent: 'Esta imagem contém conteúdo promocional ou inadequado. Por favor, escolha outra imagem.'
      }
    },
    storyGrid: {
      recentStories: 'Stories Recentes',
      loading: 'Carregando...',
      showMore: 'Mostrar Mais',
      noStories: 'Nenhum story encontrado',
      beFirstShare: 'Seja o primeiro a compartilhar!',
      loadingMore: 'Carregando mais...'
    }
  },
  'en': {
    title: 'Stories',
    timeAgo: {
      lessThanAMinute: 'less than a minute ago',
      oneMinute: '1 minute ago', 
      minutes: 'minutes ago',
      oneHour: '1 hour ago',
      hours: 'hours ago'
    },
    createStory: {
      camera: 'Camera',
      gallery: 'Gallery',
      music: 'Music',
      uploadImage: 'Upload Image',
      noStoriesYet: 'No stories yet',
      beFirst: 'Be the first to share a story!',
      processing: 'Processing...',
      checkingImage: 'Checking image...',
      storyCreated: 'Story created successfully!',
      waitMessage: 'Please wait {time} minutes before posting another story',
      errors: {
        accessDenied: 'Could not access camera or microphone',
        uploadFailed: 'Failed to upload story',
        processingFailed: 'Failed to process story',
        invalidContent: 'This image contains promotional content or inappropriate material. Please choose another image.'
      }
    },
    storyGrid: {
      recentStories: 'Recent Stories',
      loading: 'Loading...',
      showMore: 'Show More',
      noStories: 'No stories found',
      beFirstShare: 'Be the first to share!',
      loadingMore: 'Loading more...'
    }
  }
};

const STORIES_LIMIT = 12;

export function StoriesGallery() {
  const [selectedStoryId, setSelectedStoryId] = useState<string | null>(null);
  const [stories, setStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const { user } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
      toast.success('Back online!');
    };
    
    const handleOffline = () => {
      setIsOffline(true);
      toast.error('You are offline. Some features may be limited.');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (!user) return;

    const fetchStories = async () => {
      try {
        setLoading(true);
        setError(null);

        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

        const storiesRef = collection(db, 'stories');
        const q = query(
          storiesRef,
          where('timestamp', '>', twentyFourHoursAgo),
          orderBy('timestamp', 'desc'),
          limit(STORIES_LIMIT)
        );

        const snapshot = await getDocs(q);
        const storiesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        })) as Story[];

        // Group stories by user and filter out video stories
        const storiesByUser = storiesData.reduce((acc: { [key: string]: Story[] }, story) => {
          // Skip video stories
          if (story.mediaType === 'video') return acc;
          
          if (!acc[story.authorId]) {
            acc[story.authorId] = [];
          }
          acc[story.authorId].push(story);
          return acc;
        }, {});

        // Sort each user's stories by timestamp
        Object.keys(storiesByUser).forEach(userId => {
          storiesByUser[userId].sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
        });

        // Flatten the stories array while maintaining user grouping
        const flattenedStories = Object.values(storiesByUser)
          .map(userStories => userStories[0]) // Take the most recent story from each user
          .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
          .slice(0, STORIES_LIMIT); // Take only the last 12 stories

        setStories(flattenedStories);
        setError(null);
      } catch (err) {
        console.error('Error processing stories:', err);
        setError('Error loading stories');
      } finally {
        setLoading(false);
      }
    };

    fetchStories();
  }, [user]);

  const checkScrollButtons = () => {
    if (!containerRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener('resize', checkScrollButtons);
    return () => window.removeEventListener('resize', checkScrollButtons);
  }, [stories]);

  const scroll = (direction: 'left' | 'right') => {
    if (!containerRef.current) return;

    const scrollAmount = 200;
    const container = containerRef.current;
    const newScrollLeft = direction === 'left' 
      ? container.scrollLeft - scrollAmount 
      : container.scrollLeft + scrollAmount;

    container.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    checkScrollButtons();
  };

  const handleStoryClick = (storyId: string, event: React.MouseEvent) => {
    event.preventDefault();
    if (isOffline) {
      toast.error('Cannot view stories while offline');
      return;
    }
    
    const storyIndex = stories.findIndex(story => story.id === storyId);
    if (storyIndex !== -1) {
      setSelectedStoryId(storyId);
    }
  };

  const handleStoryClose = () => {
    setSelectedStoryId(null);
  };

  const handleStoryDelete = (storyId: string) => {
    setStories(prev => prev.filter(story => story.id !== storyId));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center py-8 text-gray-500">
        <WifiOff className="h-12 w-12 mb-4" />
        <p>{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div>
      <div className="max-w-2xl mx-auto px-4 py-4 relative">
        {isOffline && (
          <div className="mb-4 p-2 bg-yellow-50 text-yellow-800 rounded-lg text-sm flex items-center justify-center">
            <WifiOff className="h-4 w-4 mr-2" />
            You are currently offline
          </div>
        )}

        {/* Left Arrow */}
        {showLeftArrow && (
          <button
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-1 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-colors"
          >
            <ChevronLeft className="h-5 w-5 text-gray-600" />
          </button>
        )}
        
        <div 
          ref={containerRef}
          className="flex space-x-4 overflow-x-auto scrollbar-hide relative scroll-smooth"
          onScroll={handleScroll}
        >
          <Link
            to="/stories"
            className="flex flex-col items-center space-y-1 flex-shrink-0"
          >
            <div className="w-16 h-16 rounded-full border-2 border-gray-200 flex items-center justify-center bg-gray-50">
              <Plus className="h-6 w-6 text-gray-400" />
            </div>
            <span className="text-xs text-gray-500">Add Story</span>
          </Link>

          {stories.map((story) => {
            const hasUnviewed = true;

            return (
              <div
                key={story.id}
                className="flex flex-col items-center space-y-1 flex-shrink-0"
              >
                <button
                  onClick={(e) => handleStoryClick(story.id, e)}
                  className={`p-1 rounded-full ${
                    hasUnviewed 
                      ? 'bg-gradient-to-tr from-gray-900 via-gray-700 to-gray-800' 
                      : 'bg-gradient-to-tr from-gray-300 to-gray-200'
                  } transition-all duration-300`}
                  disabled={isOffline}
                >
                  <div className="w-14 h-14 rounded-full border-2 border-white overflow-hidden">
                    {story.mediaType === 'spotify' ? (
                      <img
                        src={story.spotifyTrack?.albumImage}
                        alt={story.authorName}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <img
                        src={story.mediaUrl || story.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${story.authorId}`}
                        alt={story.authorName}
                        className="w-full h-full object-cover"
                        loading="lazy"
                      />
                    )}
                  </div>
                </button>
                <Link
                  to={`/profile/${story.authorUsername}`}
                  className="text-xs text-gray-500 hover:text-gray-700 truncate w-16 text-center"
                >
                  {story.authorName.split(' ')[0]}
                </Link>
              </div>
            );
          })}
        </div>

        {/* Right Arrow */}
        {showRightArrow && (
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-1 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-colors"
          >
            <ChevronRight className="h-5 w-5 text-gray-600" />
          </button>
        )}
      </div>

      {selectedStoryId !== null && (
        <StoryViewer
          stories={stories}
          initialIndex={stories.findIndex(story => story.id === selectedStoryId)}
          onClose={handleStoryClose}
          onDelete={handleStoryDelete}
        />
      )}
    </div>
  );
}