import { useState, useEffect } from 'react';
import { Video, Loader2, Search } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { useLocalization } from '../contexts/LocalizationContext';
import { useTheme } from '../contexts/ThemeContext';
import YouTube from 'react-youtube';
import axios from 'axios';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Poosting Live',
    searchPlaceholder: 'Buscar transmissões ao vivo...',
    loading: 'Carregando...',
    noStreams: 'Nenhuma transmissão ao vivo',
    beFirst: 'Seja o primeiro a fazer uma transmissão!',
    live: 'AO VIVO',
    watching: 'assistindo',
    backToStreams: 'Voltar às transmissões',
    errors: {
      loadFailed: 'Falha ao carregar transmissões',
      searchFailed: 'Falha ao buscar transmissões'
    }
  },
  'en': {
    title: 'Poosting Live',
    searchPlaceholder: 'Search live streams...',
    loading: 'Loading...',
    noStreams: 'No live streams',
    beFirst: 'Be the first to start streaming!',
    live: 'LIVE',
    watching: 'watching',
    backToStreams: 'Back to streams',
    errors: {
      loadFailed: 'Failed to load streams',
      searchFailed: 'Failed to search streams'
    }
  }
};

interface LiveVideo {
  id: {
    videoId: string;
  };
  snippet: {
    title: string;
    channelTitle: string;
    thumbnails: {
      high: {
        url: string;
      };
    };
    liveBroadcastContent: string;
  };
}

export function Live() {
  const [videos, setVideos] = useState<LiveVideo[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const { country } = useLocalization();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    fetchLiveVideos();
  }, [country]);

  const fetchLiveVideos = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        'https://www.googleapis.com/youtube/v3/search',
        {
          params: {
            part: 'snippet',
            type: 'video',
            eventType: 'live',
            maxResults: 50,
            order: 'viewCount',
            regionCode: country,
            relevanceLanguage: navigator.language.split('-')[0],
            key: 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8'
          }
        }
      );

      const liveVideos = response.data.items
        .filter((item: any) => 
          item?.snippet?.liveBroadcastContent === 'live' &&
          item?.id?.videoId &&
          item?.snippet?.title &&
          item?.snippet?.channelTitle &&
          item?.snippet?.thumbnails?.high?.url
        );

      setVideos(liveVideos);
    } catch (error) {
      console.error('Error fetching live videos:', error);
      toast.error(t('errors.loadFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchQuery.trim()) {
      fetchLiveVideos();
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(
        'https://www.googleapis.com/youtube/v3/search',
        {
          params: {
            part: 'snippet',
            type: 'video',
            eventType: 'live',
            maxResults: 50,
            q: searchQuery,
            regionCode: country,
            relevanceLanguage: navigator.language.split('-')[0],
            key: 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8'
          }
        }
      );

      const searchResults = response.data.items
        .filter((item: any) => 
          item?.snippet?.liveBroadcastContent === 'live' &&
          item?.id?.videoId &&
          item?.snippet?.title &&
          item?.snippet?.channelTitle &&
          item?.snippet?.thumbnails?.high?.url
        );

      setVideos(searchResults);
    } catch (error) {
      console.error('Error searching videos:', error);
      toast.error(t('errors.searchFailed'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Video className={`h-6 w-6 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')} {country && `(${country})`}
            </h1>
          </div>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <form onSubmit={handleSearch}>
            <div className="relative">
              <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t('searchPlaceholder')}
                className={`w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                }`}
              />
            </div>
          </form>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {selectedVideo ? (
          <div className="p-4">
            <button
              onClick={() => setSelectedVideo(null)}
              className={`mb-4 flex items-center space-x-2 ${
                isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-500 hover:text-blue-600'
              }`}
            >
              <span>← {t('backToStreams')}</span>
            </button>
            <div className="aspect-video mb-4">
              <YouTube
                videoId={selectedVideo}
                opts={{
                  width: '100%',
                  height: '100%',
                  playerVars: {
                    autoplay: 1,
                  },
                }}
                className="w-full h-full"
              />
            </div>
            {videos.find(v => v.id.videoId === selectedVideo)?.snippet && (
              <div className="space-y-2">
                <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {videos.find(v => v.id.videoId === selectedVideo)?.snippet.title}
                </h2>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {videos.find(v => v.id.videoId === selectedVideo)?.snippet.channelTitle}
                </p>
              </div>
            )}
          </div>
        ) : loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-red-400' : 'text-red-500'}`} />
          </div>
        ) : videos.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
            {videos.map((video) => (
              <div
                key={video.id.videoId}
                onClick={() => setSelectedVideo(video.id.videoId)}
                className={`cursor-pointer ${
                  isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
                } rounded-lg overflow-hidden transition-colors`}
              >
                <div className="relative aspect-video">
                  <img
                    src={video.snippet.thumbnails.high.url}
                    alt={video.snippet.title}
                    className="w-full h-full object-cover"
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center p-4">
                    <div className="absolute top-2 left-2">
                      <span className="px-2 py-1 bg-red-500 text-white text-xs font-medium rounded-full">
                        {t('live')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <h3 className={`font-medium line-clamp-2 text-sm ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {video.snippet.title}
                  </h3>
                  <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    {video.snippet.channelTitle}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8">
            <Video className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
            <p className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {t('noStreams')}
            </p>
            <p className={`text-sm mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {t('beFirst')}
            </p>
          </div>
        )}
      </main>
    </div>
  );
}