import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { X, Moon, Sun, Users, Loader2, RefreshCw } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { auth, db } from '../lib/firebase';
import { doc, updateDoc, getDoc, setDoc, collection, query, getDocs, onSnapshot } from 'firebase/firestore';
import { MainMenu } from '../components/MainMenu';
import { DeleteAccountDialog } from '../components/DeleteAccountDialog';
import { ProfilePhotoUploader } from '../components/ProfilePhotoUploader';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Configurações',
    sections: {
      photo: {
        title: 'Foto do Perfil',
        upload: 'Enviar nova foto de perfil',
        recommended: 'Recomendado: Imagem quadrada, pelo menos 400x400 pixels'
      },
      bio: {
        title: 'Bio',
        placeholder: 'Fale sobre você...',
        characters: 'caracteres',
        save: 'Salvar Bio',
        saving: 'Salvando...',
        errors: {
          links: 'Links não são permitidos na bio',
          email: 'Endereços de e-mail não são permitidos na bio',
          phone: 'Números de telefone não são permitidos na bio',
          social: 'Handles de redes sociais não são permitidos na bio'
        }
      },
      match: {
        title: 'Configurações de Match',
        showPhoto: 'Mostrar Foto no Match',
        description: 'Permitir que sua foto apareça no Poosting Match'
      },
      account: {
        title: 'Informações da Conta',
        name: 'Nome',
        email: 'E-mail'
      },
      admin: {
        title: 'Painel Admin',
        userCount: 'Total de Usuários',
        showCounter: 'Mostrar Contador',
        hideCounter: 'Ocultar Contador',
        registeredUsers: 'Usuários Registrados no Poosting',
        lastUpdate: 'Última atualização',
        refresh: 'Atualizar Contagem'
      },
      links: {
        support: 'Chat de Suporte',
        cookie: 'Política de Cookies',
        terms: 'Termos de uso',
        privacy: 'Política de Privacidade'
      },
      delete: {
        title: 'Excluir Conta',
        warning: 'Atenção: Esta ação é permanente',
        button: 'Excluir Minha Conta'
      }
    },
    errors: {
      loadFailed: 'Falha ao carregar dados do usuário',
      updateFailed: 'Falha ao atualizar bio',
      matchFailed: 'Falha ao atualizar configurações de match'
    },
    success: {
      bioUpdated: 'Bio atualizada com sucesso',
      matchHidden: 'Foto ocultada do Match',
      matchVisible: 'Foto visível no Match',
      countUpdated: 'Contagem atualizada com sucesso'
    }
  },
  'en': {
    title: 'Settings',
    sections: {
      photo: {
        title: 'Profile Photo',
        upload: 'Upload new profile photo',
        recommended: 'Recommended: Square image, at least 400x400 pixels'
      },
      bio: {
        title: 'Bio',
        placeholder: 'Tell us about yourself...',
        characters: 'characters',
        save: 'Save Bio',
        saving: 'Saving...',
        errors: {
          links: 'Links are not allowed in bio',
          email: 'Email addresses are not allowed in bio',
          phone: 'Phone numbers are not allowed in bio',
          social: 'Social media handles are not allowed in bio'
        }
      },
      match: {
        title: 'Match Settings',
        showPhoto: 'Show Photo in Match',
        description: 'Allow your photo to appear in Poosting Match'
      },
      account: {
        title: 'Account Information',
        name: 'Name',
        email: 'Email'
      },
      admin: {
        title: 'Admin Panel',
        userCount: 'Total Users',
        showCounter: 'Show Counter',
        hideCounter: 'Hide Counter',
        registeredUsers: 'Registered Poosting Users',
        lastUpdate: 'Last update',
        refresh: 'Refresh Count'
      },
      links: {
        support: 'Support Chat',
        cookie: 'Cookie Policy',
        terms: 'Terms of use',
        privacy: 'Privacy Policy'
      },
      delete: {
        title: 'Delete Account',
        warning: 'Warning: This action is permanent',
        button: 'Delete My Account'
      }
    },
    errors: {
      loadFailed: 'Failed to load user data',
      updateFailed: 'Failed to update bio',
      matchFailed: 'Failed to update match settings'
    },
    success: {
      bioUpdated: 'Bio updated successfully',
      matchHidden: 'Photo hidden from Match',
      matchVisible: 'Photo visible in Match',
      countUpdated: 'Count updated successfully'
    }
  }
};

export function Config() {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [bio, setBio] = useState('');
  const [savingBio, setSavingBio] = useState(false);
  const [showInMatch, setShowInMatch] = useState(true);
  const [savingMatch, setSavingMatch] = useState(false);
  const [showUserCounter, setShowUserCounter] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState<Date | null>(null);
  const { user } = useAuth();
  const { isDarkMode, toggleTheme } = useTheme();
  const [language, setLanguage] = useState('en');

  const isAdmin = user?.email?.toLowerCase() === 'afonsoalcantara@outlook.com';

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    if (!user) return;

    const loadUserData = async () => {
      try {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          setBio(userDoc.data().bio || '');
          setShowInMatch(userDoc.data().showInMatch !== false);
        } else {
          await setDoc(userRef, {
            uid: user.uid,
            name: user.displayName || '',
            username: user.displayName?.replace('@', '').toLowerCase() || '',
            photoURL: user.photoURL || '',
            bio: '',
            showInMatch: true,
            createdAt: new Date(),
            allies: [],
            likes: [],
            stars: [],
            trash: [],
            coins: 10
          });
        }
      } catch (error) {
        console.error('Error loading user data:', error);
        toast.error(t('errors.loadFailed'));
      }
    };

    loadUserData();
  }, [user, language]);

  useEffect(() => {
    if (!isAdmin || !showUserCounter) return;

    const fetchUserCount = async () => {
      try {
        setLoading(true);
        const usersRef = collection(db, 'users');
        const snapshot = await getDocs(usersRef);
        setUserCount(snapshot.size);
        setLastUpdate(new Date());
      } catch (error) {
        console.error('Error fetching user count:', error);
        toast.error('Failed to fetch user count');
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch
    fetchUserCount();

    // Set up interval for hourly updates
    const interval = setInterval(fetchUserCount, 60 * 60 * 1000); // Update every hour

    return () => clearInterval(interval);
  }, [isAdmin, showUserCounter]);

  const handleRefreshCount = async () => {
    try {
      setLoading(true);
      const usersRef = collection(db, 'users');
      const snapshot = await getDocs(usersRef);
      setUserCount(snapshot.size);
      setLastUpdate(new Date());
      toast.success(t('success.countUpdated'));
    } catch (error) {
      console.error('Error refreshing user count:', error);
      toast.error('Failed to refresh count');
    } finally {
      setLoading(false);
    }
  };

  const handleBioSubmit = async () => {
    if (!user) return;

    try {
      setSavingBio(true);
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { bio: bio.trim() });
      toast.success(t('success.bioUpdated'));
    } catch (error) {
      console.error('Error updating bio:', error);
      toast.error(t('errors.updateFailed'));
    } finally {
      setSavingBio(false);
    }
  };

  const handleMatchVisibilityChange = async () => {
    if (!user) return;

    try {
      setSavingMatch(true);
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { showInMatch: !showInMatch });
      setShowInMatch(!showInMatch);
      toast.success(showInMatch ? t('success.matchHidden') : t('success.matchVisible'));
    } catch (error) {
      console.error('Error updating match visibility:', error);
      toast.error(t('errors.matchFailed'));
    } finally {
      setSavingMatch(false);
    }
  };

  const openSupportChat = () => {
    const width = Math.min(600, window.innerWidth * 0.9);
    const height = Math.min(800, window.innerHeight * 0.9);
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(
      'https://wproo.com/ia/atendimento/index.html',
      'Support Chat',
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,status=no,location=no`
    );
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
            {t('title')}
          </h1>
          <div className="flex items-center space-x-2">
            <button
              onClick={toggleTheme}
              className={`p-2 rounded-full ${
                isDarkMode 
                  ? 'bg-gray-700 text-yellow-400 hover:bg-gray-600' 
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
              title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
            >
              {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
            </button>
            <button
              onClick={() => auth.signOut()}
              className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
            >
              <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
            </button>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen p-4`}>
        <div className="space-y-6">
          <ProfilePhotoUploader 
            isDarkMode={isDarkMode}
            language={language}
          />

          {/* Admin Panel */}
          {isAdmin && (
            <div>
              <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {t('sections.admin.title')}
              </h2>
              <div className="space-y-4">
                <button
                  onClick={() => setShowUserCounter(!showUserCounter)}
                  className={`w-full flex items-center justify-center space-x-2 px-4 py-3 ${
                    isDarkMode 
                      ? 'bg-blue-600 text-white hover:bg-blue-700' 
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  } rounded-lg disabled:opacity-50 transition-colors`}
                >
                  <Users className="h-5 w-5" />
                  <span>
                    {showUserCounter ? t('sections.admin.hideCounter') : t('sections.admin.showCounter')}
                  </span>
                </button>

                {showUserCounter && (
                  <div className={`p-8 ${
                    isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
                  } rounded-lg text-center`}>
                    <div className="flex flex-col items-center justify-center space-y-4">
                      <Users className={`h-12 w-12 ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-500'
                      }`} />
                      <h3 className={`text-xl ${
                        isDarkMode ? 'text-white' : 'text-gray-900'
                      }`}>
                        {t('sections.admin.userCount')}
                      </h3>
                      <div className={`text-6xl font-bold ${
                        isDarkMode ? 'text-blue-400' : 'text-blue-500'
                      }`}>
                        {loading ? (
                          <Loader2 className="h-16 w-16 animate-spin" />
                        ) : (
                          userCount.toLocaleString()
                        )}
                      </div>
                      <div className={`text-sm ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        {t('sections.admin.registeredUsers')}
                      </div>
                      {lastUpdate && (
                        <div className={`text-xs ${
                          isDarkMode ? 'text-gray-500' : 'text-gray-400'
                        }`}>
                          {t('sections.admin.lastUpdate')}: {lastUpdate.toLocaleTimeString()}
                        </div>
                      )}
                      <button
                        onClick={handleRefreshCount}
                        disabled={loading}
                        className={`mt-4 px-4 py-2 rounded-full flex items-center space-x-2 ${
                          isDarkMode 
                            ? 'bg-blue-600 text-white hover:bg-blue-700' 
                            : 'bg-blue-500 text-white hover:bg-blue-600'
                        } disabled:opacity-50`}
                      >
                        {loading ? (
                          <Loader2 className="h-5 w-5 animate-spin" />
                        ) : (
                          <RefreshCw className="h-5 w-5" />
                        )}
                        <span>{t('sections.admin.refresh')}</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div>
            <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {t('sections.match.title')}
            </h2>
            <div className="flex items-center justify-between">
              <div>
                <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {t('sections.match.showPhoto')}
                </p>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {t('sections.match.description')}
                </p>
              </div>
              <button
                onClick={handleMatchVisibilityChange}
                disabled={savingMatch}
                className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                  showInMatch 
                    ? isDarkMode ? 'bg-blue-600' : 'bg-blue-500'
                    : isDarkMode ? 'bg-gray-600' : 'bg-gray-300'
                }`}
              >
                <span
                  className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                    showInMatch ? 'translate-x-6' : 'translate-x-1'
                  }`}
                />
              </button>
            </div>
          </div>

          <div>
            <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {t('sections.bio.title')}
            </h2>
            <div className="space-y-4">
              <textarea
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                placeholder={t('sections.bio.placeholder')}
                maxLength={120}
                className={`w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                }`}
                rows={3}
              />
              <div className="flex justify-between items-center">
                <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {bio.length}/120 {t('sections.bio.characters')}
                </span>
                <button
                  onClick={handleBioSubmit}
                  disabled={savingBio}
                  className={`px-4 py-2 rounded-lg disabled:opacity-50 ${
                    isDarkMode 
                      ? 'bg-blue-600 text-white hover:bg-blue-700' 
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                >
                  {savingBio ? t('sections.bio.saving') : t('sections.bio.save')}
                </button>
              </div>
            </div>
          </div>

          <div>
            <h2 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {t('sections.account.title')}
            </h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="name" className={`block text-sm font-medium mb-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t('sections.account.name')}
                </label>
                <input
                  type="text"
                  id="name"
                  value={user?.displayName || ''}
                  disabled
                  className={`mt-1 block w-full px-3 py-2 border rounded-md ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-gray-300' 
                      : 'bg-gray-100 border-gray-300 text-gray-600'
                  }`}
                />
              </div>
              <div>
                <label htmlFor="email" className={`block text-sm font-medium mb-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t('sections.account.email')}
                </label>
                <input
                  type="email"
                  id="email"
                  value={user?.email || ''}
                  disabled
                  className={`mt-1 block w-full px-3 py-2 border rounded-md ${
                    isDarkMode 
                      ? 'bg-gray-700 border-gray-600 text-gray-300' 
                      : 'bg-gray-100 border-gray-300 text-gray-600'
                  }`}
                />
              </div>
            </div>
          </div>

          <div className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} pt-6`}>
            {/* YouTube Video */}
            <div className="mb-6">
              <div className="relative aspect-video w-full">
                <iframe
                  className="absolute inset-0 w-full h-full rounded-lg"
                  src="https://www.youtube.com/embed/4R-5sAR_Ndc?si=1uSYflUYPOIVf5V1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </div>
            </div>

            <button
              onClick={openSupportChat}
              className={`w-full flex items-center justify-center space-x-2 px-4 py-3 ${
                isDarkMode 
                  ? 'bg-blue-600 text-white hover:bg-blue-700' 
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              } rounded-lg disabled:opacity-50 transition-colors mb-6`}
            >
              {t('sections.links.support')}
            </button>

            <div className={`mt-8 border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} pt-6 flex flex-col items-center`}>
              <button
                onClick={() => setShowDeleteDialog(true)}
                className="px-3 py-1.5 text-sm bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors text-center flex items-center justify-center space-x-2"
              >
                <X className="h-4 w-4" />
                <span>{t('sections.delete.button')}</span>
              </button>
              <p className={`text-xs mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('sections.delete.warning')}
              </p>
            </div>
          </div>

          <div className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} pt-6 px-4`}>
            <div className="flex flex-col items-center space-y-2">
              <a
                href="https://poosting.com.br/doc/en/cookie-policy.html"
                target="_blank"
                rel="noopener noreferrer"
                className={isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'}
              >
                {t('sections.links.cookie')}
              </a>
              <a
                href="https://poosting.com.br/doc/en/poosting-terms-of-use.html"
                target="_blank"
                rel="noopener noreferrer"
                className={isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'}
              >
                {t('sections.links.terms')}
              </a>
              <a
                href="https://poosting.com.br/doc/en/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
                className={isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'}
              >
                {t('sections.links.privacy')}
              </a>
            </div>
          </div>
        </div>
      </main>

      {showDeleteDialog && (
        <DeleteAccountDialog 
          onClose={() => setShowDeleteDialog(false)} 
          isDarkMode={isDarkMode}
          language={language}
        />
      )}
    </div>
  );
}