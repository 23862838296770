import { useState, useEffect } from 'react';
import { Search, Video, Loader2 } from 'lucide-react';
import { auth } from '../lib/firebase';
import YouTube from 'react-youtube';
import { MainMenu } from '../components/MainMenu';
import { VideoGrid } from '../components/youtube/VideoGrid';
import { fetchTrendingVideos, searchVideos } from '../utils/youtube';
import { useLocalization } from '../contexts/LocalizationContext';
import { useTheme } from '../contexts/ThemeContext';
import type { YouTubeVideo } from '../utils/youtube';

const translations = {
  'pt-BR': {
    title: 'Poosting Vídeos',
    searchPlaceholder: 'Buscar vídeos...',
    loading: 'Carregando...',
    noResults: 'Nenhum vídeo encontrado',
    noResultsFor: 'Nenhum vídeo encontrado para',
    searchVideos: 'Buscar Vídeos',
    enterVideo: 'Digite o nome de um vídeo para buscar',
    backToVideos: 'Voltar aos vídeos',
    showMore: 'Mostrar Mais',
    loadingMore: 'Carregando mais...',
    errors: {
      loadFailed: 'Falha ao carregar vídeos',
      searchFailed: 'Falha ao buscar vídeos'
    }
  },
  'en': {
    title: 'Poosting Videos',
    searchPlaceholder: 'Search videos...',
    loading: 'Loading...',
    noResults: 'No videos found',
    noResultsFor: 'No videos found for',
    searchVideos: 'Search Videos',
    enterVideo: 'Enter a video name to search',
    backToVideos: 'Back to videos',
    showMore: 'Show More',
    loadingMore: 'Loading more...',
    errors: {
      loadFailed: 'Failed to load videos',
      searchFailed: 'Failed to search videos'
    }
  }
};

const VIDEOS_PER_PAGE = 32;

export function Videos() {
  const [videos, setVideos] = useState<YouTubeVideo[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [pageToken, setPageToken] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const { country } = useLocalization();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    loadTrendingVideos();
  }, [country]);

  const loadTrendingVideos = async () => {
    try {
      setLoading(true);
      const { items, nextPageToken } = await fetchTrendingVideos(country);
      setVideos(items);
      setPageToken(nextPageToken);
      setHasMore(!!nextPageToken);
    } catch (error) {
      console.error('Error loading videos:', error);
      toast.error(t('errors.loadFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      setLoading(true);
      const { items, nextPageToken } = await searchVideos(searchQuery, country);
      setVideos(items);
      setPageToken(nextPageToken);
      setHasMore(!!nextPageToken);
    } catch (error) {
      console.error('Error searching videos:', error);
      toast.error(t('errors.searchFailed'));
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async () => {
    if (loadingMore || !hasMore || !pageToken) return;

    try {
      setLoadingMore(true);
      const { items, nextPageToken } = searchQuery
        ? await searchVideos(searchQuery, country, pageToken)
        : await fetchTrendingVideos(country, pageToken);

      setVideos(prev => [...prev, ...items]);
      setPageToken(nextPageToken);
      setHasMore(!!nextPageToken);
    } catch (error) {
      console.error('Error loading more videos:', error);
      toast.error(t('errors.loadFailed'));
    } finally {
      setLoadingMore(false);
    }
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Video className={`h-6 w-6 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <form onSubmit={handleSearch}>
            <div className="relative">
              <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t('searchPlaceholder')}
                className={`w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                }`}
              />
            </div>
          </form>
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {selectedVideo ? (
          <div className="p-4">
            <button
              onClick={() => setSelectedVideo(null)}
              className={`mb-4 flex items-center space-x-2 ${
                isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-500 hover:text-blue-600'
              }`}
            >
              <span>← {t('backToVideos')}</span>
            </button>
            <div className="aspect-video mb-4">
              <YouTube
                videoId={selectedVideo}
                opts={{
                  width: '100%',
                  height: '100%',
                  playerVars: {
                    autoplay: 1,
                  },
                }}
                className="w-full h-full"
              />
            </div>
            {videos.find(v => (typeof v.id === 'string' ? v.id : v.id.videoId) === selectedVideo)?.snippet && (
              <div className="space-y-2">
                <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {videos.find(v => (typeof v.id === 'string' ? v.id : v.id.videoId) === selectedVideo)?.snippet.title}
                </h2>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {videos.find(v => (typeof v.id === 'string' ? v.id : v.id.videoId) === selectedVideo)?.snippet.channelTitle}
                </p>
              </div>
            )}
          </div>
        ) : (
          <>
            <VideoGrid
              videos={videos}
              loading={loading}
              onVideoSelect={(videoId) => setSelectedVideo(videoId)}
              isDarkMode={isDarkMode}
              language={language}
            />

            {hasMore && !loading && (
              <div className="p-4 flex justify-center">
                <button
                  onClick={loadMore}
                  disabled={loadingMore}
                  className={`px-6 py-2 rounded-full disabled:opacity-50 flex items-center space-x-2 ${
                    isDarkMode 
                      ? 'bg-gray-700 text-white hover:bg-gray-600' 
                      : 'bg-black text-white hover:bg-gray-800'
                  }`}
                >
                  {loadingMore ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <span>{t('loadingMore')}</span>
                    </>
                  ) : (
                    <span>{t('showMore')}</span>
                  )}
                </button>
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
}