import { useState, useRef, useEffect } from 'react';
import { MoreHorizontal } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { blockContent } from '../utils/blockContent';
import { blockUser } from '../utils/blockUser';
import toast from 'react-hot-toast';

interface StoryMenuProps {
  storyId: string;
  authorId: string;
  onBlock: () => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    report: 'Denunciar',
    blockContent: 'Bloquear Conteúdo Abusivo',
    blockUser: 'Bloquear Usuário',
    success: {
      contentBlocked: 'Conteúdo bloqueado com sucesso',
      userBlocked: 'Usuário bloqueado com sucesso'
    }
  },
  'en': {
    report: 'Report',
    blockContent: 'Block Abusive Content',
    blockUser: 'Block User',
    success: {
      contentBlocked: 'Content blocked successfully',
      userBlocked: 'User blocked successfully'
    }
  }
};

export function StoryMenu({ storyId, authorId, onBlock, isDarkMode = false, language = 'en' }: StoryMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleReport = () => {
    window.open(`https://poosting.com.br/report/index.html?storyId=${storyId}`, '_blank');
    setIsOpen(false);
  };

  const handleBlockContent = () => {
    if (!user) return;
    
    blockContent(user.uid, storyId);
    onBlock();
    toast.success(t('success.contentBlocked'));
    setIsOpen(false);
  };

  const handleBlockUser = () => {
    if (!user || user.uid === authorId) return;
    
    blockUser(user.uid, authorId);
    toast.success(t('success.userBlocked'));
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 text-white hover:bg-white/10 rounded-full transition-colors"
        title="More options"
      >
        <MoreHorizontal className="h-5 w-5" />
      </button>

      {isOpen && (
        <div className="absolute bottom-full right-0 mb-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-50">
          <button
            onClick={handleReport}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
          >
            {t('report')}
          </button>
          <button
            onClick={handleBlockContent}
            className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-50"
          >
            {t('blockContent')}
          </button>
          {user?.uid !== authorId && (
            <button
              onClick={handleBlockUser}
              className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-50 border-t border-gray-100"
            >
              {t('blockUser')}
            </button>
          )}
        </div>
      )}
    </div>
  );
}