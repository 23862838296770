import { Play, Pause, ExternalLink } from 'lucide-react';

interface Track {
  id: string;
  name: string;
  artists: string[];
  album: {
    name: string;
    images: { url: string }[];
  };
  external_urls: {
    spotify: string;
  };
  preview_url?: string;
}

interface TrackListProps {
  tracks: Track[];
  currentlyPlayingId: string | null;
  onPlayPause: (track: Track) => void;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    noPreview: 'Prévia não disponível - Clique para abrir no Spotify',
    reviews: 'avaliações'
  },
  'en': {
    noPreview: 'No preview available - Click to open in Spotify',
    reviews: 'reviews'
  }
};

export function TrackList({ tracks, currentlyPlayingId, onPlayPause, isDarkMode = false, language = 'en' }: TrackListProps) {
  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  return (
    <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
      {tracks.map((track, index) => (
        <div key={track.id} className={`p-4 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} transition-colors`}>
          <div className="flex items-center space-x-4">
            <div className="relative flex-shrink-0">
              <img
                src={track.album.images[0]?.url}
                alt={track.album.name}
                className="w-16 h-16 rounded-lg shadow-md"
              />
              <button
                onClick={() => onPlayPause(track)}
                className={`absolute inset-0 flex items-center justify-center ${
                  isDarkMode ? 'bg-black/40' : 'bg-black/30'
                } rounded-lg hover:bg-black/50 transition-colors`}
                title={track.preview_url ? 'Play preview' : 'Open in Spotify'}
              >
                {currentlyPlayingId === track.id ? (
                  <Pause className="h-8 w-8 text-white" />
                ) : (
                  <Play className="h-8 w-8 text-white" />
                )}
              </button>
            </div>
            <div className="flex-1 min-w-0">
              <div className="flex items-center justify-between">
                <span className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  #{index + 1}
                </span>
                <a
                  href={track.external_urls.spotify}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`p-2 ${
                    isDarkMode ? 'text-gray-400 hover:text-green-400' : 'text-gray-500 hover:text-green-500'
                  } transition-colors`}
                  title="Open in Spotify"
                >
                  <ExternalLink className="h-4 w-4" />
                </a>
              </div>
              <h3 className={`text-lg font-semibold truncate ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                {track.name}
              </h3>
              <p className={`text-sm truncate ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {track.artists.map(artist => artist.name).join(', ')}
              </p>
              <p className={`text-xs truncate mt-1 ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
                {track.album.name}
              </p>
              {!track.preview_url && (
                <p className="text-xs text-yellow-500 mt-1">
                  {t('noPreview')}
                </p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}