import { useState, useRef } from 'react';
import { X, Loader2, Image as ImageIcon, Camera } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { ImageEditor } from './ImageEditor';
import { moderateImage } from '../utils/vision';
import toast from 'react-hot-toast';

interface CreateCommunityProps {
  onClose: () => void;
  onCreate: (title: string, question: string, imageFile: File | null) => Promise<void>;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Criar Comunidade',
    image: {
      label: 'Imagem da Comunidade',
      upload: 'Enviar Imagem',
      required: 'Imagem da comunidade é obrigatória'
    },
    form: {
      title: {
        label: 'Título',
        placeholder: 'Digite um título claro e descritivo'
      },
      question: {
        label: 'Pergunta ou Tópico',
        placeholder: 'Descreva sua pergunta ou tópico. Você pode usar Markdown para formatação.'
      },
      preview: 'Visualizar',
      edit: 'Editar',
      characters: 'caracteres',
      supportsMarkdown: 'Suporta Markdown'
    },
    buttons: {
      cancel: 'Cancelar',
      create: 'Criar Comunidade',
      creating: 'Criando...',
      checkingImage: 'Verificando Imagem...'
    },
    errors: {
      imageProcessing: 'Erro ao processar imagem:',
      inappropriateContent: 'Esta imagem contém conteúdo promocional ou material inadequado. Por favor, escolha outra imagem.'
    }
  },
  'en': {
    title: 'Create Community',
    image: {
      label: 'Community Image',
      upload: 'Upload Image',
      required: 'Community image is required'
    },
    form: {
      title: {
        label: 'Title',
        placeholder: 'Enter a clear, descriptive title'
      },
      question: {
        label: 'Question or Topic',
        placeholder: 'Describe your question or topic. You can use Markdown for formatting.'
      },
      preview: 'Preview',
      edit: 'Edit',
      characters: 'characters',
      supportsMarkdown: 'Supports Markdown'
    },
    buttons: {
      cancel: 'Cancel',
      create: 'Create Community',
      creating: 'Creating...',
      checkingImage: 'Checking Image...'
    },
    errors: {
      imageProcessing: 'Error processing image:',
      inappropriateContent: 'This image contains promotional content or inappropriate material. Please choose another image.'
    }
  }
};

export function CreateCommunity({ onClose, onCreate, isDarkMode = false, language = 'en' }: CreateCommunityProps) {
  const [title, setTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [moderating, setModerating] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setShowImageEditor(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCroppedImage = async (blob: Blob) => {
    try {
      setModerating(true);
      
      // Convert blob to base64 for Cloud Vision
      const base64Image = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });

      // Check image with Cloud Vision
      const isImageSafe = await moderateImage(base64Image);
      if (!isImageSafe) {
        toast.error(t('errors.inappropriateContent'));
        return;
      }

      const file = new File([blob], `community-${Date.now()}.jpg`, { type: 'image/jpeg' });
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      setShowImageEditor(false);
      setSelectedImage(null);
    } catch (error: any) {
      console.error(t('errors.imageProcessing'), error);
      toast.error(error.message || t('errors.imageProcessing'));
    } finally {
      setModerating(false);
    }
  };

  const validateInput = (text: string, type: 'title' | 'question'): boolean => {
    // URL detection regex
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.(com|net|org|br|io|dev|app|me|co|uk|us|ca|eu))/gi;
    
    if (urlRegex.test(text)) {
      toast.error(`Links are not allowed in the ${type}`);
      return false;
    }

    if (type === 'title') {
      if (text.length > 100) {
        toast.error('Title must be 100 characters or less');
        return false;
      }
    } else {
      if (text.length > 120) {
        toast.error('Question must be 120 characters or less');
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim() || !question.trim() || !imageFile || submitting) {
      if (!imageFile) {
        toast.error(t('image.required'));
      }
      return;
    }

    // Validate both fields for links
    if (!validateInput(title, 'title') || !validateInput(question, 'question')) {
      return;
    }

    try {
      setSubmitting(true);
      await onCreate(title, question, imageFile);
      onClose();
    } catch (error) {
      console.error('Error creating community:', error);
      toast.error('Failed to create community');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-2xl rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className={`flex justify-between items-center p-4 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <h2 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('title')}
          </h2>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              {t('image.label')} <span className="text-red-500">*</span>
            </label>
            <div className="flex justify-center">
              <div className="relative">
                <div 
                  className={`w-32 h-32 rounded-lg border-2 ${
                    imagePreview ? 'border-solid border-gray-200' : 'border-dashed border-red-300'
                  } flex items-center justify-center overflow-hidden group cursor-pointer hover:border-blue-500 transition-colors`}
                  onClick={() => fileInputRef.current?.click()}
                >
                  {imagePreview ? (
                    <>
                      <img
                        src={imagePreview}
                        alt="Community preview"
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                        <Camera className="h-8 w-8 text-white" />
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <ImageIcon className="h-8 w-8 text-gray-400 mx-auto mb-2" />
                      <span className="text-sm text-gray-500">{t('image.upload')}</span>
                    </div>
                  )}
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleImageSelect}
                  className="hidden"
                />
              </div>
            </div>
            {!imagePreview && (
              <p className="text-sm text-red-500 text-center mt-2">
                {t('image.required')}
              </p>
            )}
          </div>

          <div>
            <label htmlFor="title" className={`block text-sm font-medium mb-1 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              {t('form.title.label')} <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t('form.title.placeholder')}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
              required
              maxLength={100}
            />
            <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {title.length}/100 {t('form.characters')}
            </p>
          </div>

          <div>
            <div className="flex justify-between items-center mb-1">
              <label htmlFor="question" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('form.question.label')} <span className="text-red-500">*</span>
              </label>
              <button
                type="button"
                onClick={() => setShowPreview(!showPreview)}
                className={`text-sm ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-500 hover:text-blue-600'}`}
              >
                {showPreview ? t('form.edit') : t('form.preview')}
              </button>
            </div>
            
            {showPreview ? (
              <div className={`prose max-w-none p-3 border rounded-lg ${
                isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-gray-50 border-gray-200'
              } min-h-[120px]`}>
                <ReactMarkdown>{question}</ReactMarkdown>
              </div>
            ) : (
              <textarea
                id="question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder={t('form.question.placeholder')}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none ${
                  isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                }`}
                rows={4}
                required
                maxLength={120}
              />
            )}
            <p className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {question.length}/120 {t('form.characters')} • {t('form.supportsMarkdown')}
            </p>
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className={`px-4 py-2 ${
                isDarkMode 
                  ? 'text-gray-300 hover:bg-gray-700' 
                  : 'text-gray-700 hover:bg-gray-100'
              } rounded-lg`}
            >
              {t('buttons.cancel')}
            </button>
            <button
              type="submit"
              disabled={!title.trim() || !question.trim() || !imageFile || submitting || moderating}
              className={`px-4 py-2 text-white rounded-lg disabled:opacity-50 flex items-center space-x-2 ${
                isDarkMode 
                  ? 'bg-blue-600 hover:bg-blue-700' 
                  : 'bg-blue-500 hover:bg-blue-600'
              }`}
            >
              {submitting || moderating ? (
                <>
                  <Loader2 className="h-5 w-5 animate-spin" />
                  <span>{moderating ? t('buttons.checkingImage') : t('buttons.creating')}</span>
                </>
              ) : (
                <span>{t('buttons.create')}</span>
              )}
            </button>
          </div>
        </form>

        {showImageEditor && selectedImage && (
          <ImageEditor
            image={selectedImage}
            onSave={handleCroppedImage}
            onCancel={() => {
              setShowImageEditor(false);
              setSelectedImage(null);
            }}
            aspectRatio={1}
            isProcessing={moderating}
          />
        )}
      </div>
    </div>
  );
}