import { useState, useEffect } from 'react';
import { Heart } from 'lucide-react';

interface MatchBannerProps {
  photoURL: string;
  name: string;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    match: 'É um Match!'
  },
  'en': {
    match: 'It\'s a Match!'
  }
};

export function MatchBanner({ photoURL, name, isDarkMode = false, language = 'en' }: MatchBannerProps) {
  const [isVisible, setIsVisible] = useState(true);

  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;

  return (
    <div className={`absolute top-0 left-0 right-0 ${
      isDarkMode 
        ? 'bg-gradient-to-b from-pink-600/80 to-transparent' 
        : 'bg-gradient-to-b from-pink-500/80 to-transparent'
    } p-4 text-white backdrop-blur-sm animate-fade-in`}>
      <div className="flex items-center justify-center space-x-2">
        <Heart className="h-6 w-6 text-white animate-pulse" />
        <span className="text-lg font-semibold">{t('match')}</span>
        <Heart className="h-6 w-6 text-white animate-pulse" />
      </div>
    </div>
  );
}