export const MAX_IMAGE_SIZE = 50 * 1024 * 1024; // 50MB
export const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

export function validateImageFile(file: File): { valid: boolean; error?: string } {
  // Explicitly reject GIF files
  if (file.type === 'image/gif') {
    return { 
      valid: false, 
      error: 'GIF files are not allowed. Please upload a JPEG, PNG, or WebP image.' 
    };
  }

  // Validate file type
  if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
    return { 
      valid: false, 
      error: 'Please select a valid image file (JPEG, PNG, or WebP)' 
    };
  }

  // Validate file size
  if (file.size > MAX_IMAGE_SIZE) {
    return { 
      valid: false, 
      error: `Image must be less than ${MAX_IMAGE_SIZE / (1024 * 1024)}MB` 
    };
  }

  return { valid: true };
}