import { Eye, User, Search, Film, Music, MessageSquarePlus, Users, Youtube, MapPin, Newspaper, Heart, Tv, Video, Trophy, Radio } from 'lucide-react';
import { ChatBadge } from '../components/ChatBadge';
import { MenuItem } from '../types/navigation';
import type { User as FirebaseUser } from 'firebase/auth';

export function getMenuItems(user: FirebaseUser): MenuItem[] {
  return [
    { icon: Eye, path: '/', title: 'Feed World' },
    { icon: Film, path: '/stories', title: 'Stories' },
    { 
      icon: User, 
      path: `/profile/${user.displayName?.replace('@', '')}`,
      title: 'Profile'
    },
    { icon: Search, path: '/search', title: 'Search Users' },
    {
      component: ChatBadge,
      path: '/chat',
      title: 'Chat'
    },
    { icon: Users, path: '/communities', title: 'Communities' },
    { icon: Heart, path: '/match', title: 'Match' },
    { icon: Trophy, path: '/ranking', title: 'Ranking' },
    { icon: Newspaper, path: '/news', title: 'News' },
    { icon: Music, path: '/music', title: 'Music' },
    { icon: Video, path: '/videos', title: 'Videos' },
    { icon: Tv, path: '/live', title: 'Live' },
    { icon: Radio, path: '/radio', title: 'Radio' }
  ];
}