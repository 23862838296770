import { useEffect } from 'react';
import { Post } from './Post';
import { Loader2, ArrowUp } from 'lucide-react';
import type { PostData } from '../utils/posts/types';
import { ensureUniqueKey } from '../utils/posts/helpers';

const translations = {
  'pt-BR': {
    showMore: 'Mostrar Mais',
    loadingMore: 'Carregando mais...',
    reachedEnd: 'Você chegou ao fim',
    backToTop: 'Voltar ao topo',
    noPosts: 'Nenhuma publicação ainda'
  },
  'en': {
    showMore: 'Show More',
    loadingMore: 'Loading more...',
    reachedEnd: 'You\'ve reached the end',
    backToTop: 'Back to top',
    noPosts: 'No posts yet'
  }
};

interface PostListProps {
  posts: PostData[];
  loading: boolean;
  loadingMore: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  isDarkMode?: boolean;
  language?: string;
}

export function PostList({ posts, loading, loadingMore, hasMore, onLoadMore, isDarkMode = false, language = 'en' }: PostListProps) {
  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
      </div>
    );
  }

  if (posts.length === 0) {
    return (
      <div className="text-center py-8">
        <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
          {t('noPosts')}
        </p>
      </div>
    );
  }

  return (
    <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
      {posts.map((post) => (
        <Post 
          key={post._key || post.id} 
          {...ensureUniqueKey(post)} 
          isDarkMode={isDarkMode} 
        />
      ))}
      
      {hasMore && !loading && (
        <div className="py-12 mb-8 flex justify-center">
          {loadingMore ? (
            <Loader2 className={`h-6 w-6 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          ) : (
            <button
              onClick={onLoadMore}
              className={`inline-flex items-center space-x-2 px-4 py-2 rounded-full transition-colors ${
                isDarkMode 
                  ? 'bg-gray-700 text-white hover:bg-gray-600' 
                  : 'bg-black text-white hover:bg-gray-800'
              }`}
            >
              <span>{t('showMore')}</span>
            </button>
          )}
        </div>
      )}

      {!hasMore && posts.length > 0 && (
        <div className="py-8 text-center space-y-4">
          <p className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
            {t('reachedEnd')}
          </p>
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className={`inline-flex items-center space-x-2 px-4 py-2 rounded-full transition-colors ${
              isDarkMode 
                ? 'bg-gray-700 text-white hover:bg-gray-600' 
                : 'bg-black text-white hover:bg-gray-800'
            }`}
          >
            <ArrowUp className="h-4 w-4" />
            <span>{t('backToTop')}</span>
          </button>
        </div>
      )}
    </div>
  );
}