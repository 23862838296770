import { collection, query, where, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import type { Story, StoriesResponse } from './types';

// Reduzido de 24 para 8 stories por página
const STORIES_PER_PAGE = 8;

export async function fetchStories(lastDoc?: any): Promise<StoriesResponse> {
  try {
    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

    const storiesRef = collection(db, 'stories');
    let q = query(
      storiesRef,
      where('timestamp', '>', twentyFourHoursAgo),
      orderBy('timestamp', 'desc'),
      limit(STORIES_PER_PAGE)
    );

    if (lastDoc) {
      q = query(
        storiesRef,
        where('timestamp', '>', twentyFourHoursAgo),
        orderBy('timestamp', 'desc'),
        startAfter(lastDoc),
        limit(STORIES_PER_PAGE)
      );
    }

    const snapshot = await getDocs(q);
    const stories = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      timestamp: doc.data().timestamp?.toDate() || new Date()
    })) as Story[];

    return {
      stories,
      lastDoc: snapshot.docs[snapshot.docs.length - 1] || null,
      hasMore: stories.length === STORIES_PER_PAGE
    };
  } catch (error) {
    console.error('Error fetching stories:', error);
    throw error;
  }
}