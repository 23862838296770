import { Link, useLocation } from 'react-router-dom';
import { Eye, User, Search, Film, Music, MessageSquarePlus, Users, Youtube, MapPin, Trophy, Newspaper, ChevronLeft, ChevronRight } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { ChatBadge } from './ChatBadge';
import { getMenuItems } from '../utils/navigation';
import { useTheme } from '../contexts/ThemeContext';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

export function MainMenu() {
  const location = useLocation();
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  if (!user) return null;

  const menuItems = getMenuItems(user);

  const checkScrollButtons = () => {
    if (!scrollContainerRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener('resize', checkScrollButtons);
    return () => window.removeEventListener('resize', checkScrollButtons);
  }, []);

  const scroll = (direction: 'left' | 'right') => {
    if (!scrollContainerRef.current) return;

    const scrollAmount = 200;
    const container = scrollContainerRef.current;
    const newScrollLeft = direction === 'left' 
      ? container.scrollLeft - scrollAmount 
      : container.scrollLeft + scrollAmount;

    container.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    checkScrollButtons();
  };

  return (
    <nav className={`sticky top-[57px] z-40 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b shadow-sm`}>
      <div className="max-w-2xl mx-auto relative">
        {/* Left Arrow */}
        {showLeftArrow && (
          <button
            onClick={() => scroll('left')}
            className={`absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-1 ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600' 
                : 'bg-white hover:bg-gray-100'
            } rounded-full shadow-lg transition-colors`}
          >
            <ChevronLeft className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        )}

        {/* Menu Items */}
        <div 
          ref={scrollContainerRef}
          className="flex items-center overflow-x-auto scrollbar-hide py-2 px-4 scroll-smooth"
          onScroll={handleScroll}
        >
          {menuItems.map(({ icon: Icon, component: Component, path, title }) => {
            const isActive = location.pathname === path;
            return (
              <Link
                key={path}
                to={path}
                className={clsx(
                  'flex items-center justify-center px-4 py-2 whitespace-nowrap transition-colors',
                  isActive 
                    ? isDarkMode ? 'text-white font-medium' : 'text-black font-medium'
                    : isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-500 hover:text-black'
                )}
                title={title}
              >
                {Component ? (
                  <Component />
                ) : Icon && (
                  <Icon className="h-5 w-5" />
                )}
              </Link>
            );
          })}
        </div>

        {/* Right Arrow */}
        {showRightArrow && (
          <button
            onClick={() => scroll('right')}
            className={`absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-1 ${
              isDarkMode 
                ? 'bg-gray-700 hover:bg-gray-600' 
                : 'bg-white hover:bg-gray-100'
            } rounded-full shadow-lg transition-colors`}
          >
            <ChevronRight className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        )}
      </div>
    </nav>
  );
}