import { Calendar } from 'lucide-react';

interface RankingHeaderProps {
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    title: 'Top 20 em 24h',
    subtitle: 'Soma de likes do dia',
    participate: 'Participe!'
  },
  'en': {
    title: '24h Top 20',
    subtitle: 'Sum of likes of the day',
    participate: 'Participate!'
  }
};

export function RankingHeader({ isDarkMode = false, language = 'en' }: RankingHeaderProps) {
  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString(language === 'pt-BR' ? 'pt-BR' : 'en-US', { 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className={`p-4 ${
      isDarkMode 
        ? 'bg-gradient-to-r from-yellow-600/80 to-yellow-700/80' 
        : 'bg-gradient-to-r from-yellow-500 to-yellow-600'
    } text-white`}>
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold mb-1">{t('title')}</h2>
          <p className="text-sm opacity-90">{t('subtitle')}</p>
          <div className="flex items-center text-yellow-100/80 mt-2">
            <Calendar className="h-3 w-3 mr-1" />
            <span className="text-xs">
              {formatDate(now)} - {formatDate(tomorrow)}
            </span>
          </div>
        </div>
        <div className="text-right">
          <p className="text-sm text-yellow-100 animate-pulse">{t('participate')}</p>
        </div>
      </div>
    </div>
  );
}