import { ref } from 'firebase/storage';
import { storage } from '../../lib/firebase';

export function getPostStoragePath(userId: string, postId: string): string {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  
  return `posts/${year}/${month}/${day}/${userId}_${postId}`;
}

export function getPostStorageRef(userId: string, postId: string) {
  const path = getPostStoragePath(userId, postId);
  return ref(storage, path);
}