import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { X, Loader2, MessageSquare } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Post } from './Post';
import toast from 'react-hot-toast';

interface UserPostsProps {
  userId: string;
  username: string;
  onClose: () => void;
  isDarkMode?: boolean;
}

export function UserPosts({ userId, username, onClose, isDarkMode = false }: UserPostsProps) {
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserPosts = async () => {
      try {
        setLoading(true);
        
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

        const postsRef = collection(db, 'posts');
        const q = query(
          postsRef,
          where('authorId', '==', userId),
          where('timestamp', '>=', twentyFourHoursAgo),
          orderBy('timestamp', 'desc')
        );

        const snapshot = await getDocs(q);
        const postsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        }));

        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching user posts:', error);
        toast.error('Failed to load posts');
      } finally {
        setLoading(false);
      }
    };

    fetchUserPosts();
  }, [userId]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className={`w-full max-w-2xl rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-xl max-h-[90vh] overflow-y-auto`}>
        <div className={`sticky top-0 z-10 p-4 border-b ${isDarkMode ? 'border-gray-700 bg-gray-800' : 'border-gray-200 bg-white'} flex justify-between items-center`}>
          <Link 
            to={`/profile/${username}`}
            className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'} hover:underline`}
          >
            @{username}'s Posts
          </Link>
          <button
            onClick={onClose}
            className={`p-2 ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'} rounded-full`}
          >
            <X className={`h-5 w-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`} />
          </button>
        </div>
        
        <div className="p-4">
          {loading ? (
            <div className="flex justify-center py-8">
              <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            </div>
          ) : posts.length > 0 ? (
            <div className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
              {posts.map(post => (
                <Post key={post.id} {...post} isDarkMode={isDarkMode} />
              ))}
            </div>
          ) : (
            <div className="text-center py-8">
              <MessageSquare className={`h-12 w-12 mx-auto mb-3 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
              <p className={isDarkMode ? 'text-gray-300' : 'text-gray-500'}>
                No posts in the last 24 hours
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}