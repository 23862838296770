import axios from 'axios';

interface GeoLocation {
  country: string;
  region: string;
  city: string;
  language: string;
}

export async function detectUserLocation(): Promise<GeoLocation> {
  try {
    // First try IP-based detection as it's more reliable
    const response = await axios.get('https://ipapi.co/json/', {
      timeout: 5000
    });

    if (response.data && response.data.country_code) {
      return {
        country: response.data.country_code,
        region: response.data.region || '',
        city: response.data.city || '',
        language: response.data.country_code === 'BR' ? 'pt-BR' : 'en'
      };
    }

    // Fallback to browser geolocation if IP detection fails
    if (navigator.geolocation) {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 24 * 60 * 60 * 1000 // 24 hours
        });
      });

      const geoResponse = await axios.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`,
        { timeout: 5000 }
      );
      
      return {
        country: geoResponse.data.countryCode || 'US',
        region: geoResponse.data.principalSubdivision || '',
        city: geoResponse.data.locality || '',
        language: geoResponse.data.countryCode === 'BR' ? 'pt-BR' : 'en'
      };
    }

    // Default fallback
    return {
      country: 'US',
      region: '',
      city: '',
      language: 'en'
    };
  } catch (error) {
    console.warn('Error detecting location:', error);
    // Graceful fallback to US/English
    return {
      country: 'US',
      region: '',
      city: '',
      language: 'en'
    };
  }
}