import { BadgeCheck, Star, Crown } from 'lucide-react';

interface VerificationBadgeProps {
  alliesCount: number;
  isDiamond?: boolean;
}

export function VerificationBadge({ alliesCount, isDiamond = false }: VerificationBadgeProps) {
  if (isDiamond) {
    return (
      <span 
        title="Crown Status (Verified Celebrity)" 
        className="inline-flex relative group"
      >
        {/* Crown glow effect */}
        <div className="absolute inset-0 bg-yellow-400 blur-md opacity-50 group-hover:opacity-75 transition-opacity" />
        
        {/* Main crown icon with animation */}
        <Crown className="h-5 w-5 relative z-10 text-yellow-400 animate-[shimmer_2s_ease-in-out_infinite]" />
        
        {/* Sparkle effects */}
        <div className="absolute -top-1 -right-1 w-2 h-2 bg-yellow-200 rounded-full animate-[pulse_1.5s_ease-in-out_infinite]" />
        <div className="absolute -bottom-1 -left-1 w-1.5 h-1.5 bg-yellow-300 rounded-full animate-[pulse_2s_ease-in-out_infinite_0.5s]" />
        
        <style>
          {`
            @keyframes shimmer {
              0% { filter: brightness(1) drop-shadow(0 0 2px rgba(251, 191, 36, 0.3)); transform: scale(1) rotate(0deg); }
              50% { filter: brightness(1.3) drop-shadow(0 0 4px rgba(251, 191, 36, 0.5)); transform: scale(1.1) rotate(5deg); }
              100% { filter: brightness(1) drop-shadow(0 0 2px rgba(251, 191, 36, 0.3)); transform: scale(1) rotate(0deg); }
            }
            @keyframes pulse {
              0% { transform: scale(0.8) rotate(0deg); opacity: 0.5; }
              50% { transform: scale(1.2) rotate(180deg); opacity: 1; }
              100% { transform: scale(0.8) rotate(360deg); opacity: 0.5; }
            }
          `}
        </style>
      </span>
    );
  }

  if (alliesCount < 1000) return null;

  let badgeColor = '';
  let title = '';
  let icon = BadgeCheck;

  if (alliesCount >= 1000000) {
    badgeColor = 'text-yellow-500';
    title = 'Star Status (1,000,000+ Allies)';
    icon = Star;
  } else if (alliesCount >= 100000) {
    badgeColor = 'text-black';
    title = 'Elite Status (100,000+ Allies)';
  } else if (alliesCount >= 10000) {
    badgeColor = 'text-red-500';
    title = 'Legend Status (10,000+ Allies)';
  } else if (alliesCount >= 1000) {
    badgeColor = 'text-blue-500';
    title = 'Champion Status (1,000+ Allies)';
  }

  const Icon = icon;
  return (
    <span title={title} className="inline-flex">
      <Icon className={`h-5 w-5 ${badgeColor}`} />
    </span>
  );
}