import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { storage, db } from '../../lib/firebase';
import { optimizeVideo } from '../video';
import toast from 'react-hot-toast';

interface UploadStoryParams {
  file?: File;
  type: 'image' | 'video' | 'spotify';
  userId: string;
  userName: string;
  userPhotoURL: string | null;
  spotifyTrack?: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  };
}

export async function uploadStory({
  file,
  type,
  userId,
  userName,
  userPhotoURL,
  spotifyTrack
}: UploadStoryParams) {
  let mediaUrl = '';
  let tempStorageRef: any = null;

  try {
    if (type !== 'spotify') {
      if (!file) throw new Error('File is required for image/video stories');

      let processedFile = file;
      if (type === 'video') {
        try {
          processedFile = await optimizeVideo(file);
        } catch (error) {
          console.error('Video optimization failed:', error);
          throw new Error('Failed to process video');
        }
      }

      // Generate storage path with date structure
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      
      // Generate unique filename
      const timestamp = Date.now();
      const randomString = Math.random().toString(36).substring(7);
      const extension = type === 'image' ? 'jpg' : 'webm';
      const filename = `${userId}_${timestamp}_${randomString}.${extension}`;
      
      // Create full storage path
      const storagePath = `stories/${year}/${month}/${day}/${filename}`;

      // Upload to storage
      tempStorageRef = ref(storage, storagePath);
      await uploadBytes(tempStorageRef, processedFile);
      mediaUrl = await getDownloadURL(tempStorageRef);
    }

    // Create story document
    const storyData = {
      mediaUrl,
      mediaType: type,
      authorId: userId,
      authorName: userName,
      authorUsername: userName.startsWith('@') ? userName.slice(1) : userName,
      authorPhotoURL: userPhotoURL,
      timestamp: serverTimestamp(),
      likes: [],
      ...(type === 'spotify' && { spotifyTrack })
    };

    const docRef = await addDoc(collection(db, 'stories'), storyData);

    return {
      id: docRef.id,
      ...storyData,
      timestamp: new Date()
    };
  } catch (error: any) {
    console.error('Error uploading story:', error);
    throw error;
  }
}