import { useState, useEffect } from 'react';
import { Bomb, Flame } from 'lucide-react';

interface PostLifetimeProps {
  timestamp: Date;
  isDarkMode?: boolean;
}

export function PostLifetime({ timestamp, isDarkMode = false }: PostLifetimeProps) {
  const [progress, setProgress] = useState(100);
  const [timeLeft, setTimeLeft] = useState('');
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const calculateTime = () => {
      const now = new Date().getTime();
      const postTime = timestamp.getTime();
      const expiryTime = postTime + (24 * 60 * 60 * 1000); // 24 hours in milliseconds
      const timeLeftMs = expiryTime - now;
      const totalDuration = 24 * 60 * 60 * 1000;
      
      if (timeLeftMs <= 0) {
        setProgress(0);
        setTimeLeft('0:00:00');
        setIsExpired(true);
        return;
      }

      // Calculate progress percentage
      const currentProgress = (timeLeftMs / totalDuration) * 100;
      setProgress(Math.max(0, Math.min(100, currentProgress)));

      // Calculate hours, minutes and seconds left
      const hours = Math.floor(timeLeftMs / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeftMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeftMs % (1000 * 60)) / 1000);

      setTimeLeft(`${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
    };

    calculateTime();
    const interval = setInterval(calculateTime, 1000);

    return () => clearInterval(interval);
  }, [timestamp]);

  return (
    <div className="mt-4 mb-1">
      {/* Timeline container */}
      <div className="relative flex items-center">
        {/* Timeline bar and icons container - 90% width */}
        <div className="flex-1 relative">
          {/* Timeline bar */}
          <div className="h-1 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className="h-full bg-gradient-to-r from-orange-300 via-orange-500 to-red-600 transition-all duration-1000"
              style={{ width: `${progress}%` }}
            />
          </div>

          {/* Bomb and flame icons */}
          <div 
            className="absolute top-1/2 -translate-y-1/2 transition-all duration-1000"
            style={{ left: `${progress}%`, transform: `translateX(-50%) translateY(-50%)` }}
          >
            <div className={`relative flex items-center ${
              isExpired 
                ? 'animate-bounce' 
                : 'animate-[wiggle_1s_ease-in-out_infinite]'
            }`}>
              {/* Single flame */}
              <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                <Flame 
                  className={`h-4 w-4 text-orange-500 ${
                    progress <= 50 ? 'animate-[flame_0.5s_ease-in-out_infinite]' : ''
                  }`}
                />
              </div>

              {/* Bomb */}
              <Bomb 
                className={`h-5 w-5 ${isDarkMode ? 'text-white' : 'text-black'} transform transition-transform ${
                  progress <= 25 ? 'animate-[shake_0.5s_ease-in-out_infinite]' : ''
                }`}
              />

              {/* Warning indicator */}
              {progress <= 25 && (
                <div className="absolute -top-1 -right-1 h-2 w-2 bg-red-500 rounded-full animate-ping" />
              )}
            </div>
          </div>
        </div>

        {/* Countdown timer - minimal spacing */}
        <div className={`ml-1 w-14 text-right text-xs font-medium flex items-center justify-end ${
          progress <= 25
            ? 'text-red-500'
            : isDarkMode 
              ? 'text-gray-400' 
              : 'text-gray-500'
        }`}>
          <div className="bg-opacity-75 rounded">
            {timeLeft}
          </div>
        </div>
      </div>
    </div>
  );
}