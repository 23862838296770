import { useState, useEffect, useRef } from 'react';
import { Radio as RadioIcon, Loader2, ChevronLeft, ChevronRight, Search } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { RadioStation } from '../components/radio/RadioStation';
import { fetchRadioStations } from '../utils/radio/api';
import { useTheme } from '../contexts/ThemeContext';
import { useRadio } from '../contexts/RadioContext';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Música ao Vivo',
    searchPlaceholder: 'Buscar estações de rádio...',
    loading: 'Carregando...',
    noStations: 'Nenhuma estação encontrada',
    noResults: 'Nenhuma estação encontrada para "{query}"',
    startStreaming: 'Seja o primeiro a transmitir!',
    showMore: 'Mostrar Mais',
    loadingMore: 'Carregando mais...',
    alphabet: {
      title: 'Selecione uma letra',
      noStationsLetter: 'Nenhuma estação começando com',
      selectLetter: 'Selecione uma letra para ver as estações'
    },
    errors: {
      loadFailed: 'Falha ao carregar estações',
      playbackError: 'Falha ao reproduzir estação'
    }
  },
  'en': {
    title: 'Live Music',
    searchPlaceholder: 'Search radio stations...',
    loading: 'Loading...',
    noStations: 'No stations found',
    noResults: 'No stations found for "{query}"',
    startStreaming: 'Be the first to start streaming!',
    showMore: 'Show More',
    loadingMore: 'Loading more...',
    alphabet: {
      title: 'Select a letter',
      noStationsLetter: 'No stations starting with',
      selectLetter: 'Select a letter to view stations'
    },
    errors: {
      loadFailed: 'Failed to load stations',
      playbackError: 'Failed to play station'
    }
  }
};

interface RadioStation {
  name: string;
  url: string;
  favicon: string;
  country: string;
  language: string;
  tags: string;
  votes: number;
  clickCount: number;
  bitrate: number;
  _key?: string;
}

interface GroupedStations {
  [key: string]: {
    items: RadioStation[];
    hasMore: boolean;
    loading: boolean;
  };
}

const ALPHABET = '#ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const STATIONS_PER_PAGE = 10;

function generateStationKey(station: RadioStation): string {
  return `${station.url}-${station.name}-${station.country}-${Date.now()}`;
}

export function Radio() {
  const [stations, setStations] = useState<RadioStation[]>([]);
  const [groupedStations, setGroupedStations] = useState<GroupedStations>({});
  const [selectedLetter, setSelectedLetter] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredStations, setFilteredStations] = useState<RadioStation[]>([]);
  const { isDarkMode } = useTheme();
  const { currentStation, isPlaying, playStation } = useRadio();
  const [language, setLanguage] = useState('en');
  const alphabetContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  useEffect(() => {
    loadStations();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const normalizedQuery = searchQuery.toLowerCase();
      const filtered = stations.filter(station => 
        station.name.toLowerCase().includes(normalizedQuery) ||
        station.country.toLowerCase().includes(normalizedQuery) ||
        station.language.toLowerCase().includes(normalizedQuery) ||
        station.tags.toLowerCase().includes(normalizedQuery)
      );
      setFilteredStations(filtered);
      setSelectedLetter(null);
    } else {
      setFilteredStations([]);
    }
  }, [searchQuery, stations]);

  const loadStations = async () => {
    try {
      setLoading(true);
      const data = await fetchRadioStations();
      
      const stationsWithKeys = data.map(station => ({
        ...station,
        _key: generateStationKey(station)
      }));
      
      const sortedStations = stationsWithKeys.sort((a, b) => 
        (b.votes + b.clickCount) - (a.votes + a.clickCount)
      );

      setStations(sortedStations);

      const grouped: GroupedStations = {};
      sortedStations.forEach(station => {
        const firstLetter = station.name.charAt(0).toUpperCase();
        const letter = /[A-Z]/.test(firstLetter) ? firstLetter : '#';
        
        if (!grouped[letter]) {
          grouped[letter] = {
            items: [],
            hasMore: false,
            loading: false
          };
        }
        
        if (grouped[letter].items.length < STATIONS_PER_PAGE) {
          grouped[letter].items.push(station);
        }
      });

      ALPHABET.forEach(letter => {
        if (grouped[letter]) {
          const totalForLetter = sortedStations.filter(station => {
            const stationLetter = /[A-Z]/.test(station.name.charAt(0).toUpperCase()) 
              ? station.name.charAt(0).toUpperCase() 
              : '#';
            return stationLetter === letter;
          }).length;
          
          grouped[letter].hasMore = totalForLetter > STATIONS_PER_PAGE;
        }
      });

      setGroupedStations(grouped);
      
      const firstAvailableLetter = ALPHABET.find(letter => grouped[letter]?.items.length > 0) || null;
      setSelectedLetter(firstAvailableLetter);
    } catch (error) {
      console.error('Error loading radio stations:', error);
      toast.error(t('errors.loadFailed'));
    } finally {
      setLoading(false);
    }
  };

  const loadMoreForLetter = async (letter: string) => {
    if (!groupedStations[letter] || !groupedStations[letter].hasMore || groupedStations[letter].loading) {
      return;
    }

    try {
      setGroupedStations(prev => ({
        ...prev,
        [letter]: { ...prev[letter], loading: true }
      }));

      const currentItems = groupedStations[letter].items;
      const letterStations = stations.filter(station => {
        const stationLetter = /[A-Z]/.test(station.name.charAt(0).toUpperCase()) 
          ? station.name.charAt(0).toUpperCase() 
          : '#';
        return stationLetter === letter;
      });

      const nextStations = letterStations.slice(
        currentItems.length,
        currentItems.length + STATIONS_PER_PAGE
      );

      setGroupedStations(prev => ({
        ...prev,
        [letter]: {
          items: [...prev[letter].items, ...nextStations],
          hasMore: letterStations.length > (currentItems.length + nextStations.length),
          loading: false
        }
      }));
    } catch (error) {
      console.error('Error loading more stations:', error);
      toast.error(t('errors.loadFailed'));
      setGroupedStations(prev => ({
        ...prev,
        [letter]: { ...prev[letter], loading: false }
      }));
    }
  };

  const checkScrollButtons = () => {
    if (!alphabetContainerRef.current) return;
    
    const { scrollLeft, scrollWidth, clientWidth } = alphabetContainerRef.current;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener('resize', checkScrollButtons);
    return () => window.removeEventListener('resize', checkScrollButtons);
  }, [stations]);

  const scroll = (direction: 'left' | 'right') => {
    if (!alphabetContainerRef.current) return;

    const scrollAmount = 200;
    const container = alphabetContainerRef.current;
    const newScrollLeft = direction === 'left' 
      ? container.scrollLeft - scrollAmount 
      : container.scrollLeft + scrollAmount;

    container.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    checkScrollButtons();
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <RadioIcon className="h-6 w-6 text-purple-500" />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <MainMenu />
      </header>

      <div className={`sticky top-[113px] z-40 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <div className="relative mb-4">
            <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('searchPlaceholder')}
              className={`w-full pl-10 pr-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                isDarkMode 
                  ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
              }`}
            />
          </div>

          <div className="relative">
            {showLeftArrow && (
              <button
                onClick={() => scroll('left')}
                className={`absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-1 ${
                  isDarkMode 
                    ? 'bg-gray-700 text-white hover:bg-gray-600' 
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                } rounded-full shadow-lg transition-colors`}
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
            )}

            <div 
              ref={alphabetContainerRef}
              className="flex space-x-1 overflow-x-auto scrollbar-hide scroll-smooth px-6"
              onScroll={handleScroll}
            >
              {ALPHABET.map(letter => {
                const hasItems = groupedStations[letter]?.items.length > 0;
                return (
                  <button
                    key={letter}
                    onClick={() => hasItems && setSelectedLetter(letter)}
                    disabled={!hasItems || !!searchQuery}
                    className={`min-w-[32px] h-8 flex items-center justify-center rounded-md text-sm font-medium transition-colors ${
                      selectedLetter === letter
                        ? isDarkMode
                          ? 'bg-purple-600 text-white'
                          : 'bg-black text-white'
                        : hasItems && !searchQuery
                        ? isDarkMode
                          ? 'text-gray-300 hover:bg-gray-700'
                          : 'text-gray-900 hover:bg-gray-100'
                        : isDarkMode
                        ? 'text-gray-600 cursor-not-allowed'
                        : 'text-gray-300 cursor-not-allowed'
                    }`}
                  >
                    {letter}
                  </button>
                );
              })}
            </div>

            {showRightArrow && (
              <button
                onClick={() => scroll('right')}
                className={`absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-1 ${
                  isDarkMode 
                    ? 'bg-gray-700 text-white hover:bg-gray-600' 
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                } rounded-full shadow-lg transition-colors`}
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
      </div>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen pb-24`}>
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-purple-400' : 'text-purple-500'}`} />
          </div>
        ) : searchQuery ? (
          <div className="p-4">
            {filteredStations.length > 0 ? (
              <div className="space-y-4">
                {filteredStations.map((station) => (
                  <RadioStation
                    key={station._key}
                    station={station}
                    isPlaying={isPlaying}
                    isCurrentStation={currentStation?.url === station.url}
                    onClick={() => playStation(station)}
                    isDarkMode={isDarkMode}
                    language={language}
                  />
                ))}
              </div>
            ) : (
              <div className="text-center py-8">
                <p className={`text-lg ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {t('noResults', { query: searchQuery })}
                </p>
              </div>
            )}
          </div>
        ) : selectedLetter && groupedStations[selectedLetter]?.items.length > 0 ? (
          <div className="p-4">
            <div className="space-y-4">
              {groupedStations[selectedLetter].items.map((station) => (
                <RadioStation
                  key={station._key}
                  station={station}
                  isPlaying={isPlaying}
                  isCurrentStation={currentStation?.url === station.url}
                  onClick={() => playStation(station)}
                  isDarkMode={isDarkMode}
                  language={language}
                />
              ))}
            </div>

            {groupedStations[selectedLetter].hasMore && (
              <div className="mt-6 flex justify-center">
                <button
                  onClick={() => loadMoreForLetter(selectedLetter)}
                  disabled={groupedStations[selectedLetter].loading}
                  className={`px-6 py-2 rounded-full disabled:opacity-50 flex items-center space-x-2 ${
                    isDarkMode 
                      ? 'bg-gray-700 text-white hover:bg-gray-600' 
                      : 'bg-black text-white hover:bg-gray-800'
                  }`}
                >
                  {groupedStations[selectedLetter].loading ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <span>{t('loadingMore')}</span>
                    </>
                  ) : (
                    <span>{t('showMore')}</span>
                  )}
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="p-8 text-center">
            <p className={`text-lg mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {selectedLetter 
                ? `${t('alphabet.noStationsLetter')} '${selectedLetter}'` 
                : t('alphabet.selectLetter')}
            </p>
          </div>
        )}
      </main>
    </div>
  );
}