import { useState, useEffect } from 'react';
import { Search as SearchIcon, Loader2 } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { auth } from '../lib/firebase';
import { debounce } from 'lodash';
import { searchUsers } from '../utils/search';
import { isUserBlocked } from '../utils/blockUser';
import type { User } from '../types/user';
import { SearchInput } from '../components/SearchInput';
import { UserList } from '../components/UserList';
import { EmptySearchState } from '../components/EmptySearchState';
import { MainMenu } from '../components/MainMenu';
import toast from 'react-hot-toast';

const translations = {
  'pt-BR': {
    title: 'Buscar Usuários',
    errors: {
      searchFailed: 'Falha ao buscar usuários'
    }
  },
  'en': {
    title: 'Search Users',
    errors: {
      searchFailed: 'Failed to search users'
    }
  }
};

export function Search() {
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const { user: currentUser } = useAuth();
  const { isDarkMode } = useTheme();
  const [language, setLanguage] = useState('en');

  // Detect user's language on mount
  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }
    
    return value as string;
  };

  const performSearch = async (query: string) => {
    if (!query.trim() || !currentUser) return;

    try {
      setLoading(true);
      const results = await searchUsers(query, currentUser.uid);
      
      // Filter out blocked users
      const filteredResults = results.filter(user => 
        !isUserBlocked(currentUser.uid, user.uid)
      );
      
      setUsers(filteredResults);
    } catch (error) {
      console.error('Error searching users:', error);
      toast.error(t('errors.searchFailed'));
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = debounce(performSearch, 300);

  useEffect(() => {
    if (searchQuery) {
      debouncedSearch(searchQuery);
    } else {
      setUsers([]);
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery, currentUser?.uid]);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <header className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <SearchIcon className={`h-6 w-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <SearchInput 
            value={searchQuery} 
            onChange={setSearchQuery} 
            isDarkMode={isDarkMode}
            language={language}
          />
        </div>
        <MainMenu />
      </header>

      <main className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-x min-h-screen`}>
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className={`h-8 w-8 animate-spin ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          </div>
        ) : users.length > 0 ? (
          <UserList 
            users={users} 
            isDarkMode={isDarkMode} 
            language={language}
          />
        ) : (
          <EmptySearchState 
            searchQuery={searchQuery} 
            isDarkMode={isDarkMode}
            language={language}
          />
        )}
      </main>
    </div>
  );
}