const BASE_URL = 'https://de1.api.radio-browser.info/json';

// Cache das estações para evitar requisições repetidas
let cachedStations: any[] | null = null;
let lastCacheTime: number = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutos

export async function fetchRadioStations() {
  try {
    // Verifica se tem cache válido
    const now = Date.now();
    if (cachedStations && (now - lastCacheTime) < CACHE_DURATION) {
      return cachedStations;
    }

    // Busca 300 estações mais populares
    const response = await fetch(`${BASE_URL}/stations/topclick/300`);
    const stations = await response.json();

    // Filtra e processa apenas estações válidas
    const processedStations = stations
      .filter((station: any) => 
        station.url_resolved && 
        station.name &&
        station.codec !== 'AAC+' && // Remove formatos que consomem mais banda
        station.bitrate && 
        station.bitrate <= 128 // Limita bitrate para economizar banda
      )
      .map((station: any) => ({
        name: station.name,
        url: station.url_resolved,
        favicon: station.favicon || `https://api.dicebear.com/7.x/initials/svg?seed=${station.name}`,
        country: station.country || 'Unknown',
        language: station.language || 'Unknown',
        tags: station.tags || '',
        votes: station.votes || 0,
        clickCount: station.clickcount || 0,
        bitrate: station.bitrate || 0
      }));

    // Atualiza o cache
    cachedStations = processedStations;
    lastCacheTime = now;

    return processedStations;
  } catch (error) {
    console.error('Error fetching radio stations:', error);
    // Retorna cache mesmo expirado em caso de erro
    if (cachedStations) {
      return cachedStations;
    }
    throw new Error('Failed to load radio stations');
  }
}