import { useState, useEffect, useRef } from 'react';
import { Film } from 'lucide-react';
import { auth } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { useTheme } from '../contexts/ThemeContext';
import { StoryViewer } from '../components/StoryViewer';
import { CreateStory } from '../components/CreateStory';
import { StoryGrid } from '../components/StoryGrid';
import { fetchStories } from '../utils/stories/fetch';
import type { Story } from '../utils/stories/types';
import { MainMenu } from '../components/MainMenu';
import toast from 'react-hot-toast';

// Translations object
const translations = {
  'pt-BR': {
    title: 'Poosting Stories',
    createStory: {
      camera: 'Câmera',
      gallery: 'Galeria',
      music: 'Música',
      uploadImage: 'Enviar Imagem',
      noStoriesYet: 'Nenhum story ainda',
      beFirst: 'Seja o primeiro a compartilhar um story!',
      processing: 'Processando...',
      checkingImage: 'Verificando imagem...',
      storyCreated: 'Story criado com sucesso!',
      waitMessage: 'Aguarde {time} minutos antes de postar outro story',
      errors: {
        accessDenied: 'Não foi possível acessar a câmera ou microfone',
        uploadFailed: 'Falha ao enviar story',
        processingFailed: 'Falha ao processar story',
        invalidContent: 'Esta imagem contém conteúdo promocional ou inadequado. Por favor, escolha outra imagem.'
      }
    },
    storyGrid: {
      recentStories: 'Stories Recentes',
      loading: 'Carregando...',
      showMore: 'Mostrar Mais',
      noStories: 'Nenhum story encontrado',
      beFirstShare: 'Seja o primeiro a compartilhar!',
      loadingMore: 'Carregando mais...'
    },
    errors: {
      loadFailed: 'Falha ao carregar stories',
      loadMoreFailed: 'Falha ao carregar mais stories'
    }
  },
  'en': {
    title: 'Stories',
    createStory: {
      camera: 'Camera',
      gallery: 'Gallery',
      music: 'Music',
      uploadImage: 'Upload Image',
      noStoriesYet: 'No stories yet',
      beFirst: 'Be the first to share a story!',
      processing: 'Processing...',
      checkingImage: 'Checking image...',
      storyCreated: 'Story created successfully!',
      waitMessage: 'Please wait {time} minutes before posting another story',
      errors: {
        accessDenied: 'Could not access camera or microphone',
        uploadFailed: 'Failed to upload story',
        processingFailed: 'Failed to process story',
        invalidContent: 'This image contains promotional content or inappropriate material. Please choose another image.'
      }
    },
    storyGrid: {
      recentStories: 'Recent Stories',
      loading: 'Loading...',
      showMore: 'Show More',
      noStories: 'No stories found',
      beFirstShare: 'Be the first to share!',
      loadingMore: 'Loading more...'
    },
    errors: {
      loadFailed: 'Failed to load stories',
      loadMoreFailed: 'Failed to load more stories'
    }
  }
};

export function Stories() {
  const [selectedStoryIndex, setSelectedStoryIndex] = useState<number | null>(null);
  const [stories, setStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastDoc, setLastDoc] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const videoPreviewRefs = useRef<{ [key: string]: HTMLVideoElement }>({});
  const previewTimersRef = useRef<{ [key: string]: number }>({});
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    // Detect user's language
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  useEffect(() => {
    loadInitialStories();
  }, []);

  const loadInitialStories = async () => {
    try {
      setLoading(true);
      const result = await fetchStories();
      setStories(result.stories);
      setLastDoc(result.lastDoc);
      setHasMore(result.hasMore);
    } catch (error) {
      console.error('Error loading stories:', error);
      toast.error(t('errors.loadFailed'));
    } finally {
      setLoading(false);
    }
  };

  const loadMoreStories = async () => {
    if (!lastDoc || loadingMore) return;

    try {
      setLoadingMore(true);
      const result = await fetchStories(lastDoc);
      setStories(prev => [...prev, ...result.stories]);
      setLastDoc(result.lastDoc);
      setHasMore(result.hasMore);
    } catch (error) {
      console.error('Error loading more stories:', error);
      toast.error(t('errors.loadMoreFailed'));
    } finally {
      setLoadingMore(false);
    }
  };

  const handleStoryCreated = (newStory: Story) => {
    setStories(prev => [newStory, ...prev]);
  };

  const handleStoryDeleted = (storyId: string) => {
    setStories(prev => prev.filter(story => story.id !== storyId));
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <div className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b`}>
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Film className={`h-6 w-6 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-black'}`}>
              {t('title')}
            </h1>
          </div>
        </div>
        <MainMenu />
      </div>

      <div className={`max-w-2xl mx-auto ${isDarkMode ? 'bg-gray-800' : 'bg-white'} min-h-screen`}>
        {selectedStoryIndex !== null ? (
          <StoryViewer
            stories={stories}
            initialIndex={selectedStoryIndex}
            onClose={() => setSelectedStoryIndex(null)}
            onDelete={handleStoryDeleted}
            isDarkMode={isDarkMode}
          />
        ) : (
          <div className="p-4">
            <CreateStory 
              onStoryCreated={handleStoryCreated} 
              isDarkMode={isDarkMode}
              language={language}
              translations={translations}
            />
            
            <StoryGrid
              stories={stories}
              loading={loading}
              loadingMore={loadingMore}
              hasMore={hasMore}
              onLoadMore={loadMoreStories}
              videoPreviewRefs={videoPreviewRefs}
              previewTimersRef={previewTimersRef}
              onStorySelect={setSelectedStoryIndex}
              isDarkMode={isDarkMode}
              language={language}
              translations={translations}
            />
          </div>
        )}
      </div>
    </div>
  );
}