import { BadgeCheck, Star, Crown } from 'lucide-react';

interface ProfileProgressProps {
  alliesCount: number;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    badges: {
      champion: 'Campeão',
      legend: 'Lenda',
      elite: 'Elite',
      star: 'Estrela'
    },
    progress: {
      notVerified: 'Em processo',
      current: 'Atual',
      next: 'Próximo',
      allies: 'aliados',
      needed: 'necessários',
      progressTo: 'progresso para Status {status}',
      maxAchieved: 'Status Estrela Máximo Alcançado!'
    }
  },
  'en': {
    badges: {
      champion: 'Champion Status',
      legend: 'Legend Status',
      elite: 'Elite Status',
      star: 'Star Status'
    },
    progress: {
      notVerified: 'In process',
      current: 'Current',
      next: 'Next',
      allies: 'allies',
      needed: 'needed',
      progressTo: 'progress to {status} Status',
      maxAchieved: 'Maximum Star Status Achieved!'
    }
  }
};

const BADGE_THRESHOLDS = [
  { count: 1000, color: 'blue', label: 'champion', icon: BadgeCheck },
  { count: 10000, color: 'red', label: 'legend', icon: BadgeCheck },
  { count: 100000, color: 'black', label: 'elite', icon: BadgeCheck },
  { count: 1000000, color: 'yellow', label: 'star', icon: Star }
];

export function ProfileProgress({ alliesCount, isDarkMode = false, language = 'en' }: ProfileProgressProps) {
  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  const getNextBadgeThreshold = () => {
    for (const threshold of BADGE_THRESHOLDS) {
      if (alliesCount < threshold.count) {
        return threshold;
      }
    }
    return BADGE_THRESHOLDS[BADGE_THRESHOLDS.length - 1];
  };

  const getCurrentBadgeThreshold = () => {
    for (let i = BADGE_THRESHOLDS.length - 1; i >= 0; i--) {
      if (alliesCount >= BADGE_THRESHOLDS[i].count) {
        return BADGE_THRESHOLDS[i];
      }
    }
    return null;
  };

  const calculateProgress = () => {
    const nextThreshold = getNextBadgeThreshold();
    const prevThreshold = BADGE_THRESHOLDS[BADGE_THRESHOLDS.indexOf(nextThreshold) - 1];
    const start = prevThreshold ? prevThreshold.count : 0;
    const range = nextThreshold.count - start;
    const progress = ((alliesCount - start) / range) * 100;
    return Math.min(Math.max(progress, 0), 100);
  };

  const currentBadge = getCurrentBadgeThreshold();
  const nextBadge = getNextBadgeThreshold();
  const progress = calculateProgress();

  if (alliesCount >= 1000000) {
    return (
      <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg p-4 mt-4`}>
        <div className="flex items-center justify-center space-x-2">
          <Star className="h-6 w-6 text-yellow-500 animate-[spin_4s_linear_infinite]" />
          <span className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            {t('progress.maxAchieved')}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg p-4 mt-4`}>
      <div className="space-y-2">
        <div className="flex justify-between text-sm">
          <span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            {currentBadge ? (
              <>
                {t('progress.current')}: {t(`badges.${currentBadge.label}`)}{' '}
                <currentBadge.icon className={`inline-block h-4 w-4 text-${currentBadge.color}-500`} />
              </>
            ) : (
              t('progress.notVerified')
            )}
          </span>
          <span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            {t('progress.next')}: {t(`badges.${nextBadge.label}`)}{' '}
            <nextBadge.icon className={`inline-block h-4 w-4 text-${nextBadge.color}-500`} />
          </span>
        </div>
        
        <div className={`relative w-full h-2 ${isDarkMode ? 'bg-gray-600' : 'bg-gray-200'} rounded-full overflow-hidden`}>
          {/* Animated progress bar */}
          <div 
            className="absolute left-0 top-0 h-full bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 rounded-full transition-all duration-1000 ease-out"
            style={{ 
              width: '0%',
              animation: 'progress-grow 1.5s ease-out forwards'
            }}
          >
            {/* Spark effect */}
            <div 
              className="absolute right-0 top-0 h-full w-4 animate-spark"
              style={{
                background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.8), transparent)'
              }}
            />
          </div>
        </div>

        <div className="flex justify-between text-xs">
          <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
            {alliesCount.toLocaleString()} {t('progress.allies')}
          </span>
          <span className={isDarkMode ? 'text-gray-400' : 'text-gray-500'}>
            {nextBadge.count.toLocaleString()} {t('progress.needed')}
          </span>
        </div>

        <p className={`text-center text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mt-2`}>
          {progress.toFixed(1)}% {t('progress.progressTo', { status: t(`badges.${nextBadge.label}`) })}
        </p>
      </div>

      <style>
        {`
          @keyframes progress-grow {
            0% {
              width: 0%;
            }
            100% {
              width: ${progress}%;
            }
          }

          @keyframes spark {
            0% {
              transform: translateX(-100%) skewX(-45deg);
            }
            100% {
              transform: translateX(1000%) skewX(-45deg);
            }
          }

          .animate-spark {
            animation: spark 2s ease-out infinite;
          }
        `}
      </style>
    </div>
  );
}