import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { Profile } from '../types/match';
import toast from 'react-hot-toast';

const PROFILES_LIMIT = 20;
const COOLDOWN_HOURS = 24;

const translations = {
  'pt-BR': {
    loading: 'Estamos selecionando os perfis para você, aguarde...',
    errors: {
      searchFailed: 'Falha ao buscar perfis',
      dailyLimit: 'Limite diário atingido. Volte em {hours} horas'
    }
  },
  'en': {
    loading: 'We are selecting profiles for you, please wait...',
    errors: {
      searchFailed: 'Failed to fetch profiles',
      dailyLimit: 'Daily limit reached. Come back in {hours} hours'
    }
  }
};

export function useMatchProfiles() {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isOnCooldown, setIsOnCooldown] = useState(false);
  const [cooldownTimeLeft, setCooldownTimeLeft] = useState<string>('');
  const { user } = useAuth();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang.startsWith('pt')) {
      setLanguage('pt-BR');
    }
  }, []);

  const t = (key: string, params?: Record<string, string>) => {
    const keys = key.split('.');
    let value = translations[language as keyof typeof translations];
    
    for (const k of keys) {
      if (value && typeof value === 'object') {
        value = value[k as keyof typeof value];
      } else {
        return key;
      }
    }

    let text = value as string;
    if (params) {
      Object.entries(params).forEach(([param, replacement]) => {
        text = text.replace(`{${param}}`, replacement);
      });
    }
    
    return text;
  };

  useEffect(() => {
    if (!user) return;

    const checkCooldown = () => {
      const lastMatchTime = localStorage.getItem('lastMatchTime');
      if (lastMatchTime) {
        const timeDiff = Date.now() - parseInt(lastMatchTime);
        const hoursDiff = timeDiff / (1000 * 60 * 60);
        return hoursDiff < COOLDOWN_HOURS;
      }
      return false;
    };

    const updateCooldownTimer = () => {
      const lastMatchTime = localStorage.getItem('lastMatchTime');
      if (lastMatchTime) {
        const now = Date.now();
        const endTime = parseInt(lastMatchTime) + (COOLDOWN_HOURS * 60 * 60 * 1000);
        const timeLeft = endTime - now;

        if (timeLeft <= 0) {
          setIsOnCooldown(false);
          setCooldownTimeLeft('');
          return;
        }

        const hours = Math.floor(timeLeft / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        setCooldownTimeLeft(`${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
        setIsOnCooldown(true);
      }
    };

    const fetchProfiles = async () => {
      try {
        if (checkCooldown()) {
          setIsOnCooldown(true);
          updateCooldownTimer();
          setLoading(false);
          return;
        }

        const usersRef = collection(db, 'users');
        const q = query(
          usersRef,
          where('uid', '!=', user.uid)
        );
        
        const snapshot = await getDocs(q);
        const fetchedProfiles = snapshot.docs
          .map(doc => ({
            uid: doc.id,
            ...doc.data()
          } as Profile))
          .filter(profile => {
            if (!profile.name || !profile.username) return false;
            if (!profile.photoURL) return false;
            if (profile.photoURL.includes('dicebear.com')) return false;
            if (!profile.photoURL.includes('firebasestorage.googleapis.com')) return false;
            try {
              const url = new URL(profile.photoURL);
              return url.protocol === 'https:' && url.hostname.includes('firebasestorage.googleapis.com');
            } catch {
              return false;
            }
          });

        const shuffled = fetchedProfiles
          .sort(() => Math.random() - 0.5)
          .slice(0, PROFILES_LIMIT);

        setProfiles(shuffled);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profiles:', error);
        toast.error(t('errors.searchFailed'), {
          style: {
            background: '#000000',
            color: '#ffffff'
          }
        });
        setLoading(false);
      }
    };

    fetchProfiles();

    const timer = setInterval(updateCooldownTimer, 1000);

    return () => clearInterval(timer);
  }, [user, language]);

  const handleSwipe = async (liked: boolean) => {
    if (!user || !profiles[currentIndex]) return;

    try {
      const currentProfile = profiles[currentIndex];

      if (liked) {
        await addDoc(collection(db, 'notifications'), {
          type: 'profile_match',
          recipientId: currentProfile.uid,
          senderId: user.uid,
          senderName: user.displayName || '',
          senderUsername: user.displayName?.replace('@', '') || '',
          senderPhotoURL: user.photoURL,
          message: 'Matched! ❤️',
          timestamp: serverTimestamp(),
          read: false,
          matchData: {
            matchType: 'heart',
            matchStatus: 'matched',
            matchTimestamp: serverTimestamp()
          }
        });

        toast.success('Matched! ❤️', {
          icon: '❤️',
          style: {
            background: '#000000',
            color: '#ffffff',
            fontWeight: 'bold'
          },
          duration: 2000
        });
      }

      setCurrentIndex(prev => {
        const nextIndex = prev + 1;
        if (nextIndex >= PROFILES_LIMIT) {
          localStorage.setItem('lastMatchTime', Date.now().toString());
          setIsOnCooldown(true);
          toast.success('You\'ve reached your daily limit! Come back tomorrow for more matches.', {
            duration: 4000,
            style: {
              background: '#000000',
              color: '#ffffff'
            }
          });
        }
        return nextIndex;
      });
    } catch (error) {
      console.error('Error handling swipe:', error);
      toast.error('Failed to send match', {
        style: {
          background: '#000000',
          color: '#ffffff'
        }
      });
    }
  };

  return {
    profiles,
    loading,
    currentIndex,
    handleSwipe,
    isOnCooldown,
    cooldownTimeLeft,
    loadingMessage: t('loading')
  };
}