import { Users } from 'lucide-react';

interface EmptySearchStateProps {
  searchQuery?: string;
  isDarkMode?: boolean;
  language?: string;
}

const translations = {
  'pt-BR': {
    noResults: 'Nenhum usuário encontrado para',
    searchUsers: 'Buscar Usuários',
    enterName: 'Digite um nome ou nome de usuário para buscar usuários'
  },
  'en': {
    noResults: 'No users found for',
    searchUsers: 'Search Users',
    enterName: 'Enter a name or username to search for users'
  }
};

export function EmptySearchState({ searchQuery, isDarkMode = false, language = 'en' }: EmptySearchStateProps) {
  const t = (key: string) => {
    return translations[language as keyof typeof translations][key as keyof (typeof translations)['en']];
  };

  if (searchQuery) {
    return (
      <div className={`p-8 text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        {t('noResults')} "{searchQuery}"
      </div>
    );
  }

  return (
    <div className="p-8 text-center">
      <Users className={`h-12 w-12 mx-auto mb-4 ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`} />
      <p className={`text-lg font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
        {t('searchUsers')}
      </p>
      <p className={`mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        {t('enterName')}
      </p>
    </div>
  );
}