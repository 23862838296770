import { doc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { ref, deleteObject, listAll } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import toast from 'react-hot-toast';

const ADMIN_EMAIL = 'afonsoalcantara@outlook.com';

export async function isAdmin(email: string | null): Promise<boolean> {
  return email?.toLowerCase() === ADMIN_EMAIL.toLowerCase();
}

export async function deleteAnyPost(postId: string, currentUserEmail: string | null) {
  if (!await isAdmin(currentUserEmail)) {
    throw new Error('Unauthorized: Admin access required');
  }

  try {
    // Delete post media from storage
    const storageRef = ref(storage, `posts/${postId}`);
    try {
      const mediaFiles = await listAll(storageRef);
      await Promise.all(mediaFiles.items.map(file => deleteObject(file)));
    } catch (error) {
      console.error('Error deleting post media:', error);
    }

    // Delete post comments
    const commentsRef = collection(db, 'posts', postId, 'comments');
    const commentsSnapshot = await getDocs(commentsRef);
    await Promise.all(commentsSnapshot.docs.map(doc => deleteDoc(doc.ref)));

    // Delete post notifications
    const notificationsRef = collection(db, 'notifications');
    const notificationsQuery = query(notificationsRef, where('postId', '==', postId));
    const notificationsSnapshot = await getDocs(notificationsQuery);
    await Promise.all(notificationsSnapshot.docs.map(doc => deleteDoc(doc.ref)));

    // Delete the post document
    await deleteDoc(doc(db, 'posts', postId));

    toast.success('Post deleted by admin');
  } catch (error) {
    console.error('Error deleting post:', error);
    throw error;
  }
}